import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { closeDialog } from 'app/store/fuse/dialogSlice';

function CallerTunezApiCallForm(props) {
  const defaultValues = {
    ...props.data.data.variables,
  };
  const { placeholder } = props.data.data;
  const dispatch = useDispatch();
  const isTuneIdRequired = 'tuneId' in props.data.data.variables;
  const validationSchema = yup.object().shape({
    tuneId: yup.string().required('Tune ID is required'),
  });

  const { control, handleSubmit, formState, getValues } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { isValid, dirtyFields, errors } = formState;

  async function handleSave() {
    dispatch(closeDialog());

    const variables = {
      tuneId: getValues('tuneId'),
    };

    const res = {
      ...props.data,
      data: {
        ...props.data.data,
        variables,
      },
    };
    props.onSubmit(res);
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit(handleSave)}
      className="flex flex-col md:overflow-hidden"
    >
      <DialogContent className="sm:min-w-512">
        {isTuneIdRequired ? (
          <Grid key="tuneId" item>
            <Controller
              control={control}
              name="tuneId"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={placeholder?.tuneId || 'Add your Tune ID here.'}
                  id="tuneId"
                  type="text"
                  error={!!errors.tuneId}
                  helperText={errors?.tuneId?.message}
                  variant="outlined"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
        ) : (
          "Tune ID will be automatically assigned based on the user's song selection in the earlier step."
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={() => dispatch(closeDialog())} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          type="submit"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </form>
  );
}

export default CallerTunezApiCallForm;
