import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import * as queries from '../../../../graphql/queries';
import { showMessage } from '../../../store/fuse/messageSlice';
import { throwErrorIfHandledByMiddleware } from '../../../store';
import ComponentLogger from 'app/services/logger/ComponentLogger';

const logger = new ComponentLogger('SubscriberActivitySlice');

export const listSubscriberActivities = createAsyncThunk(
  'reports/listSubscriberActivities',
  async (variables, { dispatch }) => {
    const { mno, queryType, queryParam } = variables;
    logger.info('listSubscriberActivities', { variables });

    try {
      const response = await API.graphql({
        query: queries.listSubscriberActivities,
        variables,
      });
      const data = response.data.listSubscriberActivities;
      if (data.length === 0) {
        dispatch(
          showMessage({
            message: 'No subscriber activity found matching your criteria!',
            variant: 'info',
          })
        );
      }
      data.forEach((item) => {
        item.id = uuidv4();
      });
      return data;
    } catch (error) {
      logger.error('listSubscriberActivities', { error });
      throwErrorIfHandledByMiddleware(error);
      dispatch(
        showMessage({
          message: error.errors[0].message,
          variant: 'error',
        })
      );
      return [];
    }
  }
);

export const subscriberActivitySlice = createSlice({
  name: 'subscriberActivity',
  initialState: {
    loading: false,
    data: [],
  },
  reducers: {
    resetData: (state) => {
      state.data = [];
    },
  },
  extraReducers: {
    [listSubscriberActivities.pending]: (state) => {
      state.loading = true;
    },
    [listSubscriberActivities.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [listSubscriberActivities.rejected]: (state, action) => {
      state.loading = false;
      state.data = [];
    },
  },
});

export const { resetData } = subscriberActivitySlice.actions;

export default subscriberActivitySlice.reducer;
