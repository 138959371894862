import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import PreviewDisplay from '../../../../../shared-components/preview/PreviewDisplay';
import { getOptionalDisplayTextSchema, noCurlyBracesPattern } from './formHelper';

function SendTextMessageFromSubscriberForm(props) {
  const defaultValues = props.data.data;
  const optionalDisplayTextSchema = getOptionalDisplayTextSchema(defaultValues);

  const schema = yup.object().shape({
    message: yup
      .string()
      .required('You must enter a message')
      .max(500, 'Must be less than 500 character')
      .matches(noCurlyBracesPattern, 'Text should not include curly braces'),
    destinationAddress: yup.object().shape({
      addressString: yup
        .string()
        .required('You must enter a destination address')
        .matches(/^[0-9]+$/, 'You must enter only digits')
        .max(20, 'Must have a maximum of 20 digits')
        .matches(noCurlyBracesPattern, 'Text should not include curly braces'),
    }),
    optionalDisplayText: yup.lazy(optionalDisplayTextSchema),
  });

  const dispatch = useDispatch();
  const { control, reset, handleSubmit, formState, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const message = watch('message');
  const destinationAddress = watch('destinationAddress');
  const optionalDisplayText = watch('optionalDisplayText');

  const { isValid, dirtyFields, errors } = formState;

  function handleSave(data) {
    if (data.message) data.message = data.message.trim();
    dispatch(closeDialog());
    props.onSubmit({ ...props.data, data });
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit(handleSave)}
      className="flex flex-col md:overflow-hidden"
    >
      <DialogContent className="sm:min-w-512">
        <div className="pt-8">
          <Grid container spacing={2}>
            <Grid item xs={12} md={optionalDisplayText ? 4 : 8}>
              <Grid container spacing={2}>
                {defaultValues.optionalDisplayText !== null && (
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="optionalDisplayText"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={
                            (defaultValues.placeholder &&
                              defaultValues.placeholder.optionalDisplayText) ||
                            'Optional Display Text'
                          }
                          id="optionalDisplayText"
                          required
                          error={!!errors.optionalDisplayText}
                          helperText={errors?.optionalDisplayText?.message}
                          variant="outlined"
                          multiline
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="message"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={
                          (defaultValues.placeholder && defaultValues.placeholder.message) ||
                          'Message'
                        }
                        id="message"
                        error={!!errors.message}
                        helperText={errors?.message?.message}
                        variant="outlined"
                        multiline
                        required
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="destinationAddress.addressString"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={
                          (defaultValues.placeholder &&
                            defaultValues.placeholder.destinationAddress) ||
                          'Destination Address'
                        }
                        id="destinationAddress.addressString"
                        error={!!errors.destinationAddress}
                        helperText={errors?.destinationAddress?.addressString?.message}
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={optionalDisplayText ? 8 : 4}>
              <Grid item>
                <PreviewDisplay
                  textMessageFromSubscriberText={message}
                  destinationAddress={destinationAddress && destinationAddress.addressString}
                  optionalDisplayText={optionalDisplayText}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => dispatch(closeDialog())} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          type="submit"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </form>
  );
}

export default SendTextMessageFromSubscriberForm;
