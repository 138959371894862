import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import { showMessage } from '../../../store/fuse/messageSlice';
import { getCurrentDateStr } from '../../../services/dateUtil';
import { getContractName } from './correctionsSlice';

const logger = new ComponentLogger('PricesSlice');

const invalidateCacheHeader = { headers: { 'Cache-Control': 'max-age=0' } };

let contracts = [];

export const getAllPrices = createAsyncThunk(
  'billing/prices/getAllPrices',
  async (validDate = null) => {
    const url = validDate ? `/prices?validDate=${validDate}` : '/prices';
    const response = await API.get('billing-api', url, invalidateCacheHeader);
    return response;
  }
);
export const getPricesOfCode = createAsyncThunk('billing/prices/getPrices', async (codeId) => {
  const response = await API.get('billing-api', `/codes/${codeId}/prices`, invalidateCacheHeader);
  contracts = await API.get('billing-api', 'contracts');

  return response
    .map((contract) =>
      Object.fromEntries(Object.entries(contract).filter(([k, v]) => !!v || !Number.isNaN(v)))
    )
    .map((contract) => {
      return {
        ...contract,
        contractName: getContractName(contracts, contract.contractId),
      };
    });
});

export const getMnoValidPrices = createAsyncThunk(
  'billing/prices/getMnoValidPrices',
  async (mno) => {
    const response = await API.get(
      'billing-api',
      `/mno/${mno}/prices?validDate=${getCurrentDateStr()}`,
      invalidateCacheHeader
    );
    return response.map((price) =>
      Object.fromEntries(Object.entries(price).filter(([k, v]) => !!v || !Number.isNaN(v)))
    );
  }
);

export const savePrice = createAsyncThunk(
  'billing/prices/savePrice',
  async (price, { dispatch, getState }) => {
    try {
      const currentPrices = selectPrices(getState());

      const startDate = price.validFrom ? Date.parse(price.validFrom) : Date.parse('0000-01-01');
      const endDate = price.validTo ? Date.parse(price.validTo) : Date.parse('9999-01-01');

      for (const element of currentPrices) {
        if (
          price.id !== element.id &&
          startDate <= Date.parse(element.validTo) &&
          endDate > Date.parse(element.validFrom)
        ) {
          dispatch(
            showMessage({
              message: `Given range overlaps with range ${element.validFrom} to ${element.validTo}!`,
              variant: 'error',
            })
          );
          return;
        }
      }

      await API.put('billing-api', '/prices', { body: price });
      dispatch(showMessage({ message: 'Price saved successfully!', variant: 'success' }));
      dispatch(getPricesOfCode(price.codeId));
    } catch (err) {
      logger.error('savePrice', { data: price, error: err });
      dispatch(showMessage({ message: 'Price already exists!', variant: 'error' }));
    }
  }
);

const pricesAdapter = createEntityAdapter({});

export const { selectAll: selectPrices, selectById: selectPriceById } = pricesAdapter.getSelectors(
  (state) => state.billing.prices
);

const pricesSlice = createSlice({
  name: 'billing/prices',
  initialState: pricesAdapter.getInitialState({
    loading: false,
    allPrices: null,
    priceDialog: {
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    resetPrices: (state, action) => {
      pricesAdapter.removeAll(state, action.payload);
    },
    openPriceDialog: (state, action) => {
      state.priceDialog = {
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closePriceDialog: (state, action) => {
      state.priceDialog = {
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [savePrice.pending]: (state, action) => {
      state.loading = true;
    },
    [savePrice.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [getPricesOfCode.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllPrices.fulfilled]: (state, action) => {
      state.allPrices = action.payload;
    },
    [getPricesOfCode.fulfilled]: (state, action) => {
      state.loading = false;
      pricesAdapter.setAll(state, action.payload);
    },
    [getMnoValidPrices.fulfilled]: (state, action) => {
      state.loading = false;
      pricesAdapter.setAll(state, action.payload);
    },
    [savePrice.rejected]: (state, action) => {
      pricesAdapter.upsertOne(state, action.payload);
    },
  },
});

export const { openPriceDialog, closePriceDialog, resetPrices } = pricesSlice.actions;

export default pricesSlice.reducer;
