import Dialog from '@mui/material/Dialog';
import { CircularProgress, DialogContent, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { invokePayment } from '../orders/store/paymentSlice';
import { closeDialog } from '../../store/fuse/dialogSlice';

function PaymentRedirectDialog({ requestData }) {
  const { payPageLink, errorMessage } = useSelector(({ journey }) => journey.payment);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(invokePayment(requestData));
  }, [dispatch]);

  useEffect(() => {
    if (payPageLink != null) {
      window.location.href = payPageLink;
    }
  }, [payPageLink]);

  useEffect(() => {
    if (errorMessage != null) {
      dispatch(closeDialog());
    }
  }, [errorMessage]);

  return (
    <Dialog
      classes={{
        paper: 'm-24',
      }}
      open
      maxWidth="sm"
    >
      <DialogContent classes={{ root: 'p-36' }}>
        <div style={{ width: 300, height: '100%' }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            rowSpacing={3}
          >
            <Grid item>
              <span className="font-bold">
                You will be redirected to MTN’s site in a moment to complete your payment...
              </span>
            </Grid>
            <Grid item>
              <CircularProgress color="secondary" />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default PaymentRedirectDialog;
