import Accounts from './account/Accounts';
import Codes from './code/Codes';
import Corrections from './correction/Corrections';
import Code from './code/Code';
import { roleNames } from '../../auth/authRoles';
import Contracts from './contracts/Contracts';
import RefreshDataset from './datasetIngestions/DatasetIngestions';

const BillingAdminConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [roleNames.BILLING_ADMIN, roleNames.SELF_ADMIN],
  routes: [
    {
      path: '/billing-admin/accounts',
      element: <Accounts />,
    },
    {
      path: '/billing-admin/codes/:codeId',
      element: <Code />,
    },
    {
      path: '/billing-admin/codes',
      element: <Codes />,
    },
    {
      path: '/billing-admin/contracts',
      element: <Contracts />,
    },
    {
      path: '/billing-admin/corrections',
      element: <Corrections />,
    },
    {
      path: '/billing-admin/refresh-dataset',
      element: <RefreshDataset />,
    },
  ],
};

export default BillingAdminConfig;
