import { API } from 'aws-amplify';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import { showMessage } from '../../../store/fuse/messageSlice';
import { throwErrorIfHandledByMiddleware } from '../../../store';

const logger = new ComponentLogger('TemplatesSlice');

function getFilter(permissions) {
  const mnoList = permissions.map((permission) => permission.mno).flat();

  if (mnoList.includes('*')) {
    return {};
  }

  if (mnoList.length > 1) {
    const or = mnoList.map((mno) => ({ mno: { eq: mno } }));
    return { or };
  }

  return { mno: { eq: mnoList[0] } };
}

export const getTemplates = createAsyncThunk(
  'admin/template/getTemplates',
  async (noPermissionFiltering = false, { getState }) => {
    const filter = noPermissionFiltering ? null : getFilter(getState().auth.user.permissions);
    const response = await API.graphql({
      query: queries.listJourneyTemplates,
      variables: { filter },
    }).catch((error) => {
      logger.error('listTemplates', { error });
    });

    const data = response.data.listJourneyTemplates.items;
    if (data.length === 0) {
      return [];
    }

    return data;
  }
);

export const saveTemplate = createAsyncThunk(
  'admin/template/saveTemplate',
  async (template, { dispatch }) => {
    let data;
    if (!template.createdAt) {
      const response = await API.graphql({
        query: mutations.createJourneyTemplate,
        variables: { input: template },
      }).catch((error) => {
        logger.error('createTemplate', { data: template, error });
        throwErrorIfHandledByMiddleware(error);
      });

      data = await response.data.createJourneyTemplate;
      dispatch(
        showMessage({
          message: 'Template has been created successfully!',
          variant: 'success',
        })
      );
    } else {
      const {
        createdAt,
        updatedAt,
        version,
        _version,
        _deleted,
        _lastChangedAt,
        journey,
        ...rest
      } = template;
      const response = await API.graphql({
        query: mutations.updateJourneyTemplate,
        variables: { input: rest },
      }).catch((error) => {
        logger.error('updateTemplate', { data: template, error });
        throwErrorIfHandledByMiddleware(error);
      });

      data = await response.data.updateJourneyTemplate;
      dispatch(
        showMessage({
          message: 'Template has been updated successfully!',
          variant: 'success',
        })
      );
    }
    return data;
  }
);

export const getJourneyTemplate = async (templateId) => {
  const response = await API.graphql({
    query: queries.getJourneyTemplate,
    variables: { id: templateId },
  });
  return response?.data?.getJourneyTemplate;
};

const templateAdapter = createEntityAdapter({});

export const { selectAll: selectTemplates, selectById: selectTemplateByID } =
  templateAdapter.getSelectors((state) => state.admin.templates);

export const templatesSlice = createSlice({
  name: 'templates',
  initialState: templateAdapter.getInitialState({
    loading: true,
  }),
  reducers: {},
  extraReducers: {
    [getTemplates.pending]: (state) => {
      state.loading = true;
    },
    [getTemplates.fulfilled]: (state, action) => {
      state.loading = false;
      templateAdapter.setAll(state, action.payload);
    },
    [getTemplates.rejected]: (state, action) => {
      state.loading = false;
    },
    [saveTemplate.fulfilled]: (state, action) => {
      templateAdapter.upsertOne(state, action.payload);
    },
  },
});

export default templatesSlice.reducer;
