import { Button, DialogActions, DialogContent, Grid, Tooltip } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useDebouncedValue from '@fuse/hooks/useDebouncedValue';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { useValidateProduct, validateRemoteAPI } from './utils';

function MadApiCallForm(props) {
  const defaultValues = {
    ...props.data.data.variables,
  };
  const { placeholder } = props.data.data;
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    serviceId: yup.string().required('Service ID is required'),
  });

  const { control, handleSubmit, formState, watch, setValue, getValues, trigger } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const serviceId = useDebouncedValue(watch('serviceId'), 500);
  const { isValid, dirtyFields, errors } = formState;

  const apiStatus = useValidateProduct(serviceId, props.data.data);

  function handleSave() {
    if(!serviceId) return;
    dispatch(closeDialog());
    const variables = {
      serviceId,
      status: 'Verified',
    };

    const res = {
      ...props.data,
      data: {
        ...props.data.data,
        variables,
      },
    };
    props.onSubmit(res);
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit(handleSave)}
      className="flex flex-col md:overflow-hidden"
    >
      <DialogContent className="sm:min-w-512">
        <Tooltip
          title={
            <Typography component="div" sx={{ fontSize: '11px' }}>
              {apiStatus.description}
            </Typography>
          }
          placement={
            apiStatus.description === 'Validating, Please wait...' ? 'top-start' : 'top-end'
          }
          open={apiStatus.description !== ''}
          style={{ maxWidth: '500px', marginTop: '1.5rem' }}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: !apiStatus.verified ? 'red' : 'common.black',
                '& .MuiTooltip-arrow': {
                  color: !apiStatus.verified ? 'red' : 'common.black',
                },
              },
            },
          }}
        >
          <div className="flex flex-col gap-8">
            <Grid key="serviceId" item>
              <Controller
                control={control}
                name="serviceId"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={placeholder?.serviceId || 'Add your DEP service ID here.'}
                    id="serviceId"
                    type="text"
                    error={!!errors.serviceId}
                    helperText={errors?.serviceId?.message}
                    variant="outlined"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
          </div>
        </Tooltip>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={() => dispatch(closeDialog())} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={_.isEmpty(dirtyFields) || !isValid || !apiStatus.verified}
          type="submit"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </form>
  );
}

export default MadApiCallForm;
