import { Grid } from '@mui/material';
import { Device } from 'react-mobile-device-frames';

function PreviewLaunchBrowser(props) {
  return (
    <>
      {props.url && (
        <Grid item xs={12} md={7} style={props.style}>
          {props.handsetType && props.handsetType === 'IOS' && (
            <Device device="iphone-13-pro" backgroundImg="assets/images/safari_base.png">
              <div>
                <div
                  id="header"
                  style={{
                    height: '575px', // set the height of the header
                    padding: '0px 20px 0px 20px',
                    display: 'flex',
                    width: '306px',
                    justifyContent: 'left',
                    alignItems: 'middle',
                  }}
                >
                  <p
                    style={{
                      fontSize: '1.4rem',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {props.url}
                  </p>
                </div>
              </div>
            </Device>
          )}

          {props.handsetType && props.handsetType === 'Android' && (
            <Device device="galaxy-s22" backgroundImg="assets/images/chrome_base.png">
              <div>
                <div
                  id="header"
                  style={{
                    height: '600px', // set the height of the header
                    padding: '0px 30px 0px 20px',
                    display: 'flex',
                    width: '306px',
                    justifyContent: 'left',
                    alignItems: 'middle',
                  }}
                >
                  <p
                    style={{
                      fontSize: '1.4rem',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {props.url}
                  </p>
                </div>
              </div>
            </Device>
          )}
        </Grid>
      )}
    </>
  );
}

export default PreviewLaunchBrowser;
