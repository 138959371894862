import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordConfirm } from 'app/auth/store/loginSlice';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import _ from '@lodash';
import { Typography } from '@mui/material';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email('You must enter a valid email').required('You must enter a email'),
  code: yup.number().required('Please enter the code.'),
  password: yup
    .string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
    ),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const defaultValues = {
  email: '',
  code: '',
  password: '',
  passwordConfirmation: '',
};

function ChangePasswordForm(props) {
  const dispatch = useDispatch();
  const login = useSelector(({ auth }) => auth.login);

  const { control, formState, handleSubmit, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (login.errors && login.errors.type) {
      setError(login.errors.type, {
        type: 'manual',
        message: login.errors.message,
      });
    }
  }, [login.errors, setError]);

  function onPasswordChangeSubmit(model) {
    dispatch(forgotPasswordConfirm(model));
  }

  return (
    <div className="w-full">
      <Typography variant="h6" className="mt-16 mb-24 font-semibold text-18 sm:text-24">
        Reset your password
      </Typography>
      <form
        className="flex flex-col justify-center w-full"
        onSubmit={handleSubmit(onPasswordChangeSubmit)}
      >
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-16"
              type="email"
              label="Email"
              error={!!errors.email}
              helperText={errors?.email?.message}
              variant="outlined"
              required
            />
          )}
        />

        <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-16"
              type="number"
              label="Code"
              error={!!errors.code}
              helperText={errors?.code?.message}
              variant="outlined"
              required
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-16"
              type="password"
              label="New Password"
              error={!!errors.password}
              helperText={errors?.password?.message}
              InputProps={{
                className: 'pr-2',
                type: showPassword ? 'text' : 'password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} size="large">
                      <Icon className="text-20" color="action">
                        {showPassword ? 'visibility' : 'visibility_off'}
                      </Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              required
            />
          )}
        />

        <Controller
          name="passwordConfirmation"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-16"
              type="password"
              label="Confirm Password"
              error={!!errors.password}
              helperText={errors?.password?.message}
              InputProps={{
                className: 'pr-2',
                type: showPassword ? 'text' : 'password',
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)} size="large">
                      <Icon className="text-20" color="action">
                        {showPassword ? 'visibility' : 'visibility_off'}
                      </Icon>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              required
            />
          )}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-full mx-auto mt-16"
          aria-label="Submit"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          value="cognito"
        >
          Submit
        </Button>
      </form>
    </div>
  );
}

export default ChangePasswordForm;
