import { combineReducers } from '@reduxjs/toolkit';
import permissions from './permissionsSlice';
import userSettings from './userSettingsSlice';
import edr from './MTNSAProviderIDsSlice';
import cognitoUsers from './cognitoUsersSlice';
import templates from './templatesSlice';

const reducer = combineReducers({
  permissions,
  userSettings,
  edr,
  cognitoUsers,
  templates,
});

export default reducer;
