import { Grid } from '@mui/material';
import { Device } from 'react-mobile-device-frames';
import { makeStyles } from '@mui/styles';
import {
  convertLineStringToParagraph,
  convertUnicodeToEmoji,
  InvalidPreviewContent,
} from './previewDisplayUtil';

const useStyles = makeStyles({
  deviceRoot: {
    backgroundColor: 'transparent', // Update the background color here
    '.root-0-1-1': {
      backgroundColor: 'transparent',
    },
    '.root': {
      backgroundColor: 'transparent',
    },
    '& .root': {
      backgroundColor: 'transparent',
    },
    '& .root-0-1-1': {
      backgroundColor: 'transparent',
    },
  },
});
function PreviewSelectFromMenu(props) {
  const classes = useStyles();
  return (
    <>
      {props.menuTitle?.trim() && props.menuItems?.length && (
        <Grid item xs={12} md={7} style={props.style}>
          {props.handsetType && props.handsetType === 'IOS' && (
            <Device device="iphone-13-pro" backgroundImg="assets/images/ios_base.png">
              <div>
                <div
                  id="header"
                  style={{
                    height: '30px',
                    display: 'flex',
                    width: '306px',
                    justifyContent: 'left',
                    alignItems: 'middle',
                  }}
                >
                  <p style={{ fontSize: '1.5rem', color: '#4284e3' }}>
                    {'< Back'} <span style={{ paddingLeft: '200px' }}>Cancel</span>
                  </p>
                </div>
                <div
                  style={{
                    height: `590px`,
                    marginTop: '0px',
                    zIndex: 0,
                    textAlign: 'center',
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    wordBreak: 'break-word',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: 'black',
                      marginTop: '0px',
                      padding: '10px 0',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: convertLineStringToParagraph(
                        convertUnicodeToEmoji(props.menuTitle),
                        '1.6rem',
                        'white'
                      ),
                    }}
                  />
                  <div
                    style={{ lineHeight: '250%' }}
                    dangerouslySetInnerHTML={{
                      __html: convertLineStringToParagraph(
                        convertUnicodeToEmoji(props.menuItems.map((m) => m.item).join('\n')),
                        '1.6rem',
                        'white'
                      ),
                    }}
                  />
                </div>
              </div>
            </Device>
          )}

          {props.handsetType && props.handsetType === 'Android' && (
            <Device device="galaxy-s22" backgroundImg="assets/images/android_base.jpeg">
              <div
                style={{
                  height: `590px`, // subtract the height of the footer
                  marginTop: '0px',
                  zIndex: 0,
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'column',
                  wordBreak: 'break-word',
                  backgroundColor: 'white',
                  padding: '20px',
                }}
              >
                <div
                  style={{
                    marginTop: '0px',
                    fontWeight: 'bold',
                    padding: '10px 0 20px 0',
                    borderBottom: '1px solid lightgray',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: convertLineStringToParagraph(
                      convertUnicodeToEmoji(props.menuTitle),
                      '1.6rem',
                      'black'
                    ),
                  }}
                />
                <div
                  style={{ lineHeight: '300%' }}
                  dangerouslySetInnerHTML={{
                    __html: convertLineStringToParagraph(
                      convertUnicodeToEmoji(props.menuItems.map((m) => m.item).join('\n')),
                      '1.6rem',
                      'black'
                    ),
                  }}
                />
              </div>
            </Device>
          )}
        </Grid>
      )}
      <InvalidPreviewContent content={props.text} />
    </>
  );
}
export default PreviewSelectFromMenu;
