import { Grid } from '@mui/material';
import { Device } from 'react-mobile-device-frames';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  convertLineStringToParagraph,
  convertUnicodeToEmoji,
  isContentValidForPreview,
  InvalidPreviewContent,
} from './previewDisplayUtil';

const useStyles = makeStyles({
  deviceRoot: {
    backgroundColor: 'transparent', // Update the background color here
    '.root-0-1-1': {
      backgroundColor: 'transparent',
    },
    '.root': {
      backgroundColor: 'transparent',
    },
    '& .root': {
      backgroundColor: 'transparent',
    },
    '& .root-0-1-1': {
      backgroundColor: 'transparent',
    },
  },
});
function PreviewDisplayText(props) {
  const classes = useStyles();
  return (
    <>
      {props.text && isContentValidForPreview(props.text) && (
        <Grid item xs={12} md={7} style={props.style}>
          {props.handsetType && props.handsetType === 'IOS' && (
            <Device device="iphone-13-pro" backgroundImg="assets/images/ios_base.png">
              <div>
                <div
                  id="header"
                  style={{
                    height: '90px', // set the height of the header
                    display: 'flex',
                    width: '306px',
                    justifyContent: 'left',
                    alignItems: 'middle',
                  }}
                >
                  <p style={{ fontSize: '1.5rem', color: '#4284e3' }}>{'< Back'}</p>
                </div>
                <div
                  style={{
                    height: `530px`, // subtract the height of the footer
                    marginTop: '-100px',
                    zIndex: 0,
                    textAlign: 'center',
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    wordBreak: 'break-normal',
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: convertLineStringToParagraph(
                        convertUnicodeToEmoji(props.text),
                        '1.6rem',
                        'white'
                      ),
                    }}
                  />
                </div>
                <div
                  style={{
                    height: '95px', // set the height of the footer
                    display: 'flex',
                    alignItems: 'top',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ width: '50%', textAlign: 'center' }}>
                    <p style={{ fontSize: '2rem', color: '#4284e3' }}>Cancel</p>
                  </div>
                  <div style={{ width: '50%', textAlign: 'center' }}>
                    <p style={{ fontSize: '2rem', color: '#4284e3' }}>Accept</p>
                  </div>
                </div>
              </div>
            </Device>
          )}

          {props.handsetType && props.handsetType === 'Android' && (
            <Device device="galaxy-s22" backgroundImg="assets/images/android_base.jpeg">
              <div
                style={{
                  zIndex: 0,
                  maxHeight: '500px',
                  width: '100%',
                  background: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  borderRadius: '10px',
                }}
              >
                <div
                  style={{
                    margin: '10px',
                  }}
                >
                  <div
                    id="header"
                    style={{
                      height: '40px', // set the height of the header
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'left',
                    }}
                  >
                    <p style={{ fontSize: '1.4rem', fontWeight: 'bold' }}>Services</p>
                  </div>
                  <div
                    style={{
                      zIndex: 0,
                      textAlign: 'left',
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'left',
                      flexDirection: 'column',
                      wordBreak: 'break-normal',
                    }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: convertLineStringToParagraph(
                          convertUnicodeToEmoji(props.text),
                          '1.2rem',
                          'black'
                        ),
                      }}
                    />
                  </div>
                  <div
                    style={{
                      height: '50px', // set the height of the footer
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ width: '50%', textAlign: 'center' }}>
                      <p style={{ fontSize: '1.2rem', color: '#0084c8', fontWeight: 'bold' }}>
                        Cancel
                      </p>
                    </div>
                    <div style={{ width: '50%', textAlign: 'center' }}>
                      <p style={{ fontSize: '1.2rem', color: '#0084c8', fontWeight: 'bold' }}>OK</p>
                    </div>
                  </div>
                </div>
              </div>
            </Device>
          )}
        </Grid>
      )}
      <InvalidPreviewContent content={props.text} />
    </>
  );
}
export default PreviewDisplayText;
