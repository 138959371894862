import { authRoles } from 'app/auth';
import Payment from './Payment';
import { roleNames } from '../../auth/authRoles';
import PaymentResult from './PaymentResult';

const PaymentConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [roleNames.ADMIN, roleNames.USER_WITH_PAYMENT_FUNCTIONALITY],
  routes: [
    {
      path: '/payment-result',
      element: <PaymentResult />,
      auth: authRoles.userWithPaymentFunctionality,
    },
    {
      path: '/payment',
      element: <Payment />,
      auth: authRoles.userWithPaymentFunctionality,
    },
  ],
};

export default PaymentConfig;
