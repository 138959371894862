import { authRoles } from 'app/auth';
import { lazy } from 'react';

const SubscriberActivityPage = lazy(() => import('./reports/SubscriberActivity'));
const PerformanceReport = lazy(() => import('./reports/PerformanceReport'));
const ProvisionedSubscribersReport = lazy(() => import('./reports/ProvisionedSubscribersReport'));

const ReportsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/reports/subscriber-activity',
      element: <SubscriberActivityPage />,
      auth: authRoles.userWithSearchMSISDNActivity,
    },
    {
      path: '/reports/performance',
      element: <PerformanceReport />,
      auth: authRoles.userWithOrderPermission,
    },
    {
      path: '/reports/provisioned-subscribers',
      element: <ProvisionedSubscribersReport />,
      auth: authRoles.userWithViewProvisionedSubscribers,
    },
  ],
};

export default ReportsConfig;
