import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { closeDialog } from 'app/store/fuse/dialogSlice';
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  ussd: yup
    .string()
    .required('You must enter USSD string')
    .max(240, 'Must be less than 240 characters')
    .matches(/^(\*|#).*$/, 'USSD string should start either with * or # character')
    .matches(/#$/gi, 'USSD string should end with # character'),
});

function SendUssdStringForm(props) {
  const defaultValues = props.data.data;
  const dispatch = useDispatch();
  const { control, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  function handleSave(data) {
    dispatch(closeDialog());
    props.onSubmit({ ...props.data, data });
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit(handleSave)}
      className="flex flex-col md:overflow-hidden"
    >
      <DialogContent className="sm:min-w-512">
        <div className="pt-8">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="ussd"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={
                          (defaultValues.placeholder && defaultValues.placeholder.ussd) || 'USSD'
                        }
                        id="ussd"
                        error={!!errors.ussd}
                        helperText={errors?.ussd?.message}
                        variant="outlined"
                        multiline
                        required
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => dispatch(closeDialog())} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          type="submit"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </form>
  );
}

export default SendUssdStringForm;
