import { authRoles } from 'app/auth';
import SummaryReport from '../orders/reports/SummaryReport';
import AccountBalanceReport from '../orders/reports/AccountBalanceReport';
import PaymentHistory from '../orders/reports/PaymentHistory';
import { roleNames } from '../../auth/authRoles';

const BillingConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [roleNames.USER_WITH_BILLING_PERMISSION, roleNames.USER_WITH_PAYMENT_FUNCTIONALITY],
  routes: [
    {
      path: '/billing/account-balance',
      element: <AccountBalanceReport />,
      auth: authRoles.userWithPaymentFunctionality,
    },
    {
      path: '/billing/summary',
      element: <SummaryReport />,
      auth: authRoles.userWithBillingPermission,
    },
    {
      path: '/billing/payment-history',
      element: <PaymentHistory />,
      auth: authRoles.userWithPaymentFunctionality,
    },
  ],
};

export default BillingConfig;
