import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import * as queries from '../../../../graphql/queries';
import { showMessage } from '../../../store/fuse/messageSlice';

const logger = new ComponentLogger('DatasetIngestionsSlice');

const LIMIT = 10;
export const getMostRecentIngestions = createAsyncThunk(
  'billing/refresh-dataset/getDatasetIngestions',
  async (datasets, { dispatch }) => {
    try {
      const response = await API.graphql({
        query: queries.listIngestions,
        variables: {
          datasets,
          limit: LIMIT,
        },
      });
      return response.data.listIngestions;
    } catch (error) {
      logger.error('getMostRecentIngestions', { error });
      dispatch(
        showMessage({ message: 'An error occurred while fetching ingestions.', variant: 'error' })
      );
      return [];
    }
  }
);

export const refreshUbsDatasets = createAsyncThunk(
  'billing/refresh-dataset/triggerManualRefresh',
  async (datasets, { dispatch }) => {
    try {
      const response = await API.graphql({
        query: queries.refreshUbsDataset,
        variables: {
          datasets,
        },
      });
      dispatch(showMessage({ message: response.data.refreshUbsDataset, variant: 'success' }));
      dispatch(getMostRecentIngestions(datasets));
    } catch (error) {
      logger.error('refreshUbsDatasets', { error });
      dispatch(getMostRecentIngestions(datasets));
      dispatch(
        showMessage({ message: 'Error occurred while refreshing the datasets!', variant: 'error' })
      );
    }
  }
);

const datasetIngestionsSlice = createSlice({
  name: 'billing/datasetIngestions',
  initialState: {
    loading: true,
    data: null,
    isRefreshing: false,
  },
  reducers: {},
  extraReducers: {
    [getMostRecentIngestions.pending]: (state, action) => {
      state.loading = true;
    },
    [getMostRecentIngestions.rejected]: (state, action) => {
      state.loading = false;
      state.data = [];
    },
    [getMostRecentIngestions.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.loading = false;
    },
    [refreshUbsDatasets.pending]: (state, action) => {
      state.isRefreshing = true;
    },
    [refreshUbsDatasets.fulfilled]: (state, action) => {
      state.isRefreshing = false;
    },
    [refreshUbsDatasets.rejected]: (state, action) => {
      state.isRefreshing = false;
    },
  },
});

export default datasetIngestionsSlice.reducer;
