import { Button, DialogActions, DialogContent, Grid, Tooltip } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import useDebouncedValue from '@fuse/hooks/useDebouncedValue';
import Typography from '@mui/material/Typography';
import { CIS_SERVICE_LABELS } from 'app/services/constants';
import { useValidateProduct, validateRemoteAPI } from './utils';

function CisServiceCallForm(props) {
  const dispatch = useDispatch();

  const defaultValues = {
    ...props.data.data.variables,
  };

  const variables = props.data.data.variablesInfo
    ? props.data.data.variablesInfo
    : props.data.data.variables;

  const { placeholder } = props.data.data;

  const validationSchema = yup.object().shape({
    serviceId: yup.string().required('Subscription ID is required'),
  });

  const { control, handleSubmit, formState, watch, setValue, getValues, trigger } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const serviceId = useDebouncedValue(watch('serviceId'), 1000);
  const apiStatus = useValidateProduct(serviceId, props.data.data);

  function handleSave() {
    if (!apiStatus.verified) return;
    dispatch(closeDialog());
    const values = JSON.parse(apiStatus.data ?? '{}');
    const res = {
      ...props.data,
      data: {
        ...props.data.data,
        variables: values,
      },
    };
    props.onSubmit(res);
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit(handleSave)}
      className="flex flex-col md:overflow-hidden"
    >
      <DialogContent className="sm:min-w-512">
        <div className="pt-8">
          <Tooltip
            title={
              <Typography component="div" sx={{ fontSize: '11px', width: '100%' }}>
                {apiStatus.data
                  ? Object.entries(JSON.parse(apiStatus.data)).map(([key, value]) => (
                      <div sx={{ fontSize: 'inherit' }} key={key}>
                        {CIS_SERVICE_LABELS[key]}: {value}
                      </div>
                    ))
                  : apiStatus.description}
              </Typography>
            }
            placement="top-end"
            open={apiStatus.description !== ''}
            style={{ maxWidth: '500px', marginTop: '1.5rem' }}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: !apiStatus.verified ? 'red' : 'common.black',
                  '& .MuiTooltip-arrow': {
                    color: !apiStatus.verified ? 'red' : 'common.black',
                  },
                },
              },
            }}
          >
            <Grid key="serviceId" item>
              <Controller
                control={control}
                name="serviceId"
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{
                      height: 'auto',
                      overflowY: 'visible',
                    }}
                    label={placeholder?.subscriptionId || 'Add your Subscription ID here'}
                    id="serviceId"
                    type="text"
                    error={!!errors.serviceId}
                    helperText={errors?.serviceId?.message}
                    variant="outlined"
                    fullWidth
                    required
                    onChange={(event) => {
                      field.onChange(event);
                    }}
                  />
                )}
              />
            </Grid>
          </Tooltip>
        </div>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={() => dispatch(closeDialog())} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={_.isEmpty(dirtyFields) || !isValid || !apiStatus.verified}
          type="submit"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </form>
  );
}

export default CisServiceCallForm;
