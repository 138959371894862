import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API, Storage } from 'aws-amplify';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import * as queries from '../../../../graphql/queries';

const logger = new ComponentLogger('DownloadMsisdnListSlice');

export const getCampaignMsisdnList = createAsyncThunk(
  'executionStatus/getCampaignMsisdnList',
  async (variables) => {
    const { mno, orderId, orderExecutionId, tag, identityId, fileName } = variables;
    logger.info('getCampaignMsisdnList', { variables })

    try {
      const response = await API.graphql({
        query: queries.getCampaignMsisdnList,
        variables,
      });
      return response.data.getCampaignMsisdnList;
    } catch (error) {
      logger.error('getCampaignMsisdnList', { error });
      throw Error(error.errors[0].errorType);
    }
  }
);

export const downloadS3File = createAsyncThunk(
  'executionStatus/downloadFile',
  async ({ s3Key, fileName }, { dispatch }) => {
    const result = await Storage.get(s3Key, {
      level: 'protected',
      contentType: 'text/plain',
      useAccelerateEndpoint: true,
      download: true,
      progressCallback(progress) {
        dispatch(
          updateFileDownloadPercentage({
            downloadPercentage: Math.round((100 * progress.loaded) / progress.total),
          })
        );
      },
    });
    downloadBlob(result.Body, fileName);
  }
);

export function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}

export const StateEnum = {
  INIT: 0,
  GENERATING_FILE: 1,
  GENERATING_FILE_CLIENT_TIMEOUT: 2,
  DOWNLOAD_READY: 3,
  DOWNLOADING: 4,
  ERROR: -1,
};

export const downloadMsisdnListSlice = createSlice({
  name: 'downloadMsisdnList',
  initialState: {
    state: StateEnum.INIT,
    s3Path: null,
    downloadPercentage: 0,
    errorMessage: null,
  },
  reducers: {
    resetState: (state) => {
      state.state = StateEnum.INIT;
      state.s3Path = null;
      state.downloadPercentage = 0;
      state.errorMessage = null;
    },
    proceedAfterTimeout: (state, action) => {
      state.state = StateEnum.DOWNLOAD_READY;
      state.s3Path = action.payload;
    },
    errorAfterTimeout: (state, action) => {
      state.state = StateEnum.ERROR;
      state.errorMessage = action.payload;
    },
    updateFileDownloadPercentage: (state, action) => {
      state.downloadPercentage = action.payload.downloadPercentage;
    },
  },
  extraReducers: {
    [getCampaignMsisdnList.pending]: (state) => {
      state.state = StateEnum.GENERATING_FILE;
    },
    [getCampaignMsisdnList.fulfilled]: (state, action) => {
      state.state = StateEnum.DOWNLOAD_READY;
      state.s3Path = action.payload;
    },
    [getCampaignMsisdnList.rejected]: (state, action) => {
      if (isErrorMatches(action.error, 'Timeout')) {
        logger.warn('getCampaignMsisdnList', {
          message:
            'Timeout occurred while waiting for download file to be generated! Polling will be performed to periodically check s3 file and then download it once ready.',
          error: action.error,
        });
        state.state = StateEnum.GENERATING_FILE_CLIENT_TIMEOUT;
      } else {
        state.state = StateEnum.ERROR;
        state.errorMessage = isErrorMatches(action.error, 'IllegalArgument')
          ? 'Throttling Error'
          : action.error.message;
      }
    },
    [downloadS3File.pending]: (state) => {
      state.state = StateEnum.DOWNLOADING;
    },
    [downloadS3File.fulfilled]: (state) => {
      resetState(state);
    },
    [downloadS3File.rejected]: (state, action) => {
      logger.error('downloadS3File', { error: action.error });
      state.state = StateEnum.ERROR;
      state.errorMessage = action.error.message;
    },
  },
});

function isErrorMatches(error, errorString) {
  return error?.message?.toLowerCase().includes(errorString.toLowerCase());
}

export const { resetState, updateFileDownloadPercentage, proceedAfterTimeout, errorAfterTimeout } =
  downloadMsisdnListSlice.actions;
export default downloadMsisdnListSlice.reducer;
