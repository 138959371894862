import { getMergedPermissionAttribute } from 'app/services/reportsUtil';
import { mergeArrays } from './filterUtil';
import { OrderStatus } from './constants';
import { SELECT_ALL_ITEM_VALUE, ORDER_PERMISSION_TYPES } from '../main/admin/permission/constants';
import { roleNames } from '../auth/authRoles';

const mnoList = process.env.REACT_APP_MNO_LIST.split(',');

export function getMnoList(permissions, allMnoList, allMno = false, noPermissionFiltering = false) {
  const list = allMno ? allMnoList : mnoList;

  const allowedMnoList = noPermissionFiltering ? list : getAllowedMnoList(permissions);
  if (allowedMnoList.includes('*')) {
    return list;
  }
  return allowedMnoList;
}

export function isSingleMno(permissions) {
  const allowedMnoList = getAllowedMnoList(permissions);
  if (allowedMnoList.includes('*')) {
    return false;
  }
  return allowedMnoList.length === 1;
}

export function isSingleBillingAccount(permissions) {
  const accountList = mergeArrays(
    permissions.map((permission) => {
      return permission.billingAccount;
    })
  );
  return accountList && accountList.length === 1 && accountList[0] !== '*';
}

export function isUserHasFullOrderAccess(permissions) {
  const mnoPermissionList = mergeArrays(
    permissions.map((permission) => {
      return permission.mno;
    })
  );
  return mnoPermissionList && mnoPermissionList.includes('*');
}

export function isSingleTemplate(permissions) {
  const permissionList = mergeArrays(
    permissions.map((permission) => {
      return permission.campaignTemplate;
    })
  );
  return permissionList && permissionList.length === 1 && permissionList[0] !== '*';
}

export function getAccountList(permissions, accounts, mno) {
  // mno param can be either a single mno string or mno array
  const allowedAccountList = mergeArrays(
    permissions
      .filter((permission) => permission.permission !== 'View')
      .map((permission) => {
        return accounts.filter((acc) => permission.billingAccount.includes(acc.id));
      })
  );
  if (allowedAccountList.includes('*') || !allowedAccountList.length) {
    return accounts;
  }

  return mno
    ? allowedAccountList.filter((account) => account.mno === mno || mno.includes(account.mno))
    : accounts;
}

export function getStatusBasedOnPermission(permissions) {
  if (permissions.filter((permission) => permission.permission === 'Schedule').length > 0) {
    return OrderStatus.SCHEDULED.name;
  }
  return OrderStatus.WAITING_FOR_APPROVAL.name;
}

export function hasUserSupportRole(role) {
  return role.includes(roleNames.SUPPORT);
}

export function getAllowedList(permissions, list, key) {
  const allowedList = mergeArrays(
    permissions
      .filter((permission) => permission.permission !== 'View')
      .map((permission) => {
        return permission[key];
      })
  );
  if (allowedList.includes('*') || !allowedList.length) {
    return list;
  }
  return list.filter((item) => allowedList.includes(item.id));
}

function getAllowedMnoList(permissions) {
  return mergeArrays(
    permissions
      .filter(
        (permission) =>
          permission.permission === 'Approve' ||
          permission.permission === 'Schedule' ||
          permission.permission === 'Submit' ||
          permission.permission === 'View'
      )
      .map((permission) => {
        return permission.mno;
      })
  );
}

export function getMnoListFilteredByPermissions(permissions) {
  const mnosByPermission = new Set();
  permissions.forEach((permission) => permission.mno.forEach((mno) => mnosByPermission.add(mno)));
  if (mnosByPermission.has(SELECT_ALL_ITEM_VALUE)) return mnoList;
  return mnoList.filter((mno) => mnosByPermission.has(mno));
}

export function userHasOrderPermission(permissions) {
  return permissions.length
    ? !!permissions.find((p) => ORDER_PERMISSION_TYPES.includes(p.permission))
    : false;
}

export function userHasBillingPermission(permissions) {
  return !!permissions.length; // any permission is sufficient for billing menu
}

export function isUserAdmin(user) {
  return user.role.includes(roleNames.ADMIN);
}

export function isUserSupport(user) {
  return user.role.includes(roleNames.SUPPORT);
}

export function isUserSelfAdmin(user) {
  return user.role.includes(roleNames.SELF_ADMIN);
}

export function isUserBillingAdmin(user) {
  return user.role.includes(roleNames.BILLING_ADMIN);
}

export function isSupportOrAdmin(roles) {
  return roles.includes(roleNames.ADMIN) || roles.includes(roleNames.SUPPORT);
}

export function hasPermissionType(permissions, permissionType) {
  return permissions?.some((perm) => perm.permission === permissionType);
}

export function getAccountsOfUserWithPaymentFunctionality(accounts, permissions) {
  const mnosOfUser = getMergedPermissionAttribute(permissions, 'mno');
  return accounts?.filter(
    (acc) =>
      ((mnosOfUser.includes(acc.mno) &&
        permissions?.some(
          ({ billingAccount }) =>
            billingAccount.includes(SELECT_ALL_ITEM_VALUE) || billingAccount.includes(acc.id)
        )) ||
        mnosOfUser.includes(SELECT_ALL_ITEM_VALUE)) &&
      acc.amountDueLimitCurrency &&
      acc.amountDueLimit !== null &&
      acc.amountDueLimit !== undefined
  );
}
