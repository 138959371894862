import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { closeDialog } from '../store/fuse/dialogSlice';
import { useDispatch } from 'react-redux';

export default function AlertDialog({
  title,
  content,
  okButtonName,
  cancelButtonName,
  handleOk,
  handleCancel,
  okButtonColor,
}) {
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            dispatch(closeDialog());
            handleCancel && handleCancel();
          }}
          color="primary"
        >
          {cancelButtonName}
        </Button>
        <Button
          onClick={() => {
            dispatch(closeDialog());
            handleOk();
          }}
          color={okButtonColor ? okButtonColor : 'primary'}
          autoFocus
        >
          {okButtonName}
        </Button>
      </DialogActions>
    </React.Fragment>
  );
}
