import LOGGER from 'app/services/logger/FlightdeckLogger';

class ComponentLogger {
  constructor(componentName) {
    this.componentName = componentName;
  }

  error(functionName, ...messages) {
    LOGGER.error(this.componentName, functionName, ...messages);
  }

  info(functionName, ...messages) {
    LOGGER.info(this.componentName, functionName, ...messages);
  }

  log(functionName, ...messages) {
    LOGGER.log(this.componentName, functionName, ...messages);
  }

  warn(functionName, ...messages) {
    LOGGER.warn(this.componentName, functionName, ...messages);
  }
}

export default ComponentLogger;
