import { Button, DialogActions, DialogContent, Grid, Tooltip } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import useDebouncedValue from '@fuse/hooks/useDebouncedValue';
import Typography from '@mui/material/Typography';
import { DEP_SERVICE_LABELS } from 'app/services/constants';
import { useValidateProduct } from './utils';

function DepServiceCallForm(props) {
  const dispatch = useDispatch();

  const defaultValues = {
    ...props.data.data.variables,
  };

  const variables = props.data.data.variablesInfo
    ? props.data.data.variablesInfo
    : props.data.data.variables;

  const { placeholder } = props.data.data;

  const validationSchema = yup.object().shape({
    billingRate: yup
      .number()
      .typeError('Billing rate must be a number')
      .when('$hasBillingRate', (hasBillingRate, schema) =>
        hasBillingRate
          ? schema
              .required('Billing rate is required')
              .positive('Billing rate must be a positive number')
          : schema
      ),
    serviceId: yup
      .number()
      .typeError('Service ID must be a number')
      .required('Service ID is required')
      .positive('Service ID must be a positive number'),
  });

  const { control, handleSubmit, formState, watch, setValue, getValues, trigger } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema),
    context: { hasBillingRate: isDepAdhoc() },
  });

  const { isValid, dirtyFields, errors } = formState;
  const serviceId = useDebouncedValue(watch('serviceId'), 1000);
  const apiStatus = useValidateProduct(serviceId, props.data.data);

  const handleNumericInput = (event, callback) => {
    const { value } = event.target;
    const onlyNumeric = /^\d*$/;
    if (value.length <= 10 && onlyNumeric.test(value)) {
      callback(event);
    } else {
      event.preventDefault();
    }
  };

  function isDepAdhoc() {
    return props.data.data.name === 'DepAdhocCall';
  }

  function handleSave() {
    if (!apiStatus.verified) return;
    dispatch(closeDialog());
    const values = JSON.parse(apiStatus.data ?? '{}');
    const res = {
      ...props.data,
      data: {
        ...props.data.data,
        variables: values,
      },
    };
    props.onSubmit(res);
  }

  if (props.data.data.name === 'DepConfirmationCall') {
    return <div />;
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit(handleSave)}
      className="flex flex-col md:overflow-hidden"
    >
      <DialogContent className="sm:min-w-512">
        <div className="pt-24 flex flex-col gap-16">
          <Tooltip
            title={
              <Typography component="div" sx={{ fontSize: '11px', width: '100%' }}>
                {apiStatus.data
                  ? Object.entries(JSON.parse(apiStatus.data)).map(([key, value]) => (
                      <div sx={{ fontSize: 'inherit' }} key={key}>
                        {DEP_SERVICE_LABELS[key]}: {value}
                      </div>
                    ))
                  : apiStatus.description}
              </Typography>
            }
            placement="top-end"
            open={apiStatus.description !== ''}
            style={{ maxWidth: '500px', marginTop: '1.5rem' }}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: !apiStatus.verified ? 'red' : 'common.black',
                  '& .MuiTooltip-arrow': {
                    color: !apiStatus.verified ? 'red' : 'common.black',
                  },
                },
              },
            }}
          >
            <Grid key="serviceId" item>
              <Controller
                control={control}
                name="serviceId"
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{
                      height: 'auto',
                      overflowY: 'visible',
                    }}
                    label={placeholder?.serviceId || 'Add your Service ID here'}
                    id="serviceId"
                    type="number"
                    error={!!errors.serviceId}
                    helperText={errors?.serviceId?.message}
                    variant="outlined"
                    fullWidth
                    required
                    onChange={(event) => {
                      handleNumericInput(event, field.onChange);
                    }}
                  />
                )}
              />
            </Grid>
          </Tooltip>
          {variables?.serviceId !== undefined && isDepAdhoc() && (
            <Grid container direction="column" spacing={1}>
              <Grid key="billingRate" item>
                <Controller
                  control={control}
                  name="billingRate"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={placeholder?.billingRate || 'Add your billing rate here'}
                      id="billingRate"
                      type="number"
                      error={!!errors.billingRate}
                      helperText={errors?.billingRate?.message}
                      variant="outlined"
                      fullWidth
                      required
                      onChange={(event) => handleNumericInput(event, field.onChange)}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={() => dispatch(closeDialog())} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={_.isEmpty(dirtyFields) || !isValid || !apiStatus.verified}
          type="submit"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </form>
  );
}

export default DepServiceCallForm;
