import { userVirtualRoles } from 'app/auth/authRoles';

export function preventNonNumericInput(e) {
  if (e.keyCode !== 8) {
    // allow backspace for deletion (keyCode=8)
    const isNumber = /^[0-9]$/i.test(e.key);
    if (!isNumber) e.preventDefault();
  }
}

export function getActualUserRoles(user) {
  if (user && user.role && user.role.length) {
    return user.role.filter((role) => !userVirtualRoles.includes(role));
  }
  return [];
}

export function isBrowserSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function existsOrEqualsZero(value) {
  return !!value || value === 0;
}
