import { useState } from 'react';

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
  Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PreviewOptionalDisplayText from './PreviewOptionalDisplayText';

import PreviewTextMessageToSubscriberText from './PreviewTextMessageToSubscriberText';
import PreviewDisplayText from './PreviewDisplayText';
import PreviewLaunchBrowser from './PreviewLaunchBrowser';
import PreviewSelectFromMenu from './PreviewSelectFromMenu';

const useStyles = makeStyles({
  deviceRoot: {
    '& [class^="root-"]': {
      backgroundColor: 'transparent',
      width: '420px',
      margin: '-20px -20px -20px -30px',
    },
  },
});

const handsetStyle = { maxHeight: '500px', transform: 'scale(0.6)', transformOrigin: '0 0' };

function PreviewDisplay(props) {
  const [handsetType, setHandsetType] = useState('IOS');
  const classes = useStyles();
  return (
    <div>
      <Grid sx={{ flexGrow: 1 }} container spacing={1}>
        <Grid item alignItems="center">
          <Typography variant="subtitle1">Preview </Typography>
        </Grid>
        <Grid item>
          <FormControl className="w-128 pb-8">
            <InputLabel id="handsetTypeLabel">Handset Type</InputLabel>
            <Select
              labelId="handsetTypeLabel"
              id="handsetType"
              value={handsetType}
              label="Handset Type"
              onChange={(e) => {
                setHandsetType(e.target.value);
              }}
            >
              <MenuItem value="IOS">IOS</MenuItem>
              <MenuItem value="Android">Android</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Typography variant="caption" align="left" className="italic">
            Preview is a representation only and may not provide an exact rendering of how the
            content will appear on all devices.
          </Typography>
        </Grid>
      </Grid>
      <Paper variant="outlined" className="p-12" sx={{ flexGrow: 1 }}>
        <div className={classes.deviceRoot}>
          <Grid container spacing={1}>
            <Grid container columns={16}>
              <PreviewOptionalDisplayText
                style={handsetStyle}
                optionalDisplayText={props.optionalDisplayText}
                handsetType={handsetType}
              />
              {props.optionalDisplayText && (
                <Grid item xs={12} md={2}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="400px"
                    sx={{ fontSize: '1rem' }}
                  >
                    {handsetType === 'IOS'
                      ? 'After user selects Accept -->'
                      : 'After user selects OK -->'}
                  </Box>
                </Grid>
              )}
              <PreviewTextMessageToSubscriberText
                style={handsetStyle}
                textMessageToSubscriberText={props.textMessageToSubscriberText}
                handsetType={handsetType}
                originatingAddress={props.originatingAddress}
                optionalDisplayText={props.optionalDisplayText}
              />
              {props.textMessageFromSubscriberText && (
                <Grid item xs={16} md={props.optionalDisplayText ? 6 : 16}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    height={props.optionalDisplayText ? '400px' : '200px'}
                    sx={{ fontSize: '1.2rem', fontStyle: 'italic' }}
                  >
                    The SMS action that will be executed on subscriber’s behalf. The subscriber will
                    not see the New Message screen and the SMS will be sent transparently in the
                    background without any trace in ‘Sent Messages’
                  </Box>
                </Grid>
              )}

              <PreviewDisplayText
                style={handsetStyle}
                text={props.text}
                handsetType={handsetType}
              />
              <PreviewLaunchBrowser
                style={handsetStyle}
                url={props.url}
                handsetType={handsetType}
              />
              <PreviewSelectFromMenu
                style={handsetStyle}
                menuTitle={props.menuTitle}
                menuItems={props.menuItems}
                handsetType={handsetType}
              />
            </Grid>
          </Grid>
        </div>
      </Paper>
    </div>
  );
}

export default PreviewDisplay;
