import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import * as queries from '../../../../graphql/queries';

export const listApiRequests = createAsyncThunk(
  '/support/apiRequests/listApiRequests',
  async (params) => {
    try {
      const { msisdn, journeyOrderId } = params;
      const getVariables = () => {
        if (msisdn && journeyOrderId) {
          return {
            msisdn,
            filter: {
              journeyOrderId: { eq: journeyOrderId },
            },
          };
        }
        if (msisdn) {
          return { msisdn };
        }
        return { journeyOrderId };
      };

      const query = msisdn
        ? queries.listApiRequests
        : queries.listApiRequestsByJourneyOrderIdAndCreationDate;
      const variables = getVariables();
      const response = await API.graphql({ query, variables });
      const path = msisdn ? 'listApiRequests' : 'listApiRequestsByJourneyOrderIdAndCreationDate';
      return response.data[path].items;
    } catch (e) {
      console.error('List Api Requests error: ', e);
      throw new Error('List Api Requests error: ', e);
    }
  }
);

const ApiRequestsAdapter = createEntityAdapter({
  selectId: (e) => e.eventId,
});

export const { selectAll: selectApiRequests, selectById: selectApiRequestsById } =
  ApiRequestsAdapter.getSelectors((state) => state.support.apiRequests);

export const ApiRequestsSlice = createSlice({
  name: 'apiRequests',
  initialState: ApiRequestsAdapter.getInitialState({
    loading: false,
  }),
  reducers: {},
  extraReducers: {
    [listApiRequests.pending]: (state) => {
      state.loading = true;
    },
    [listApiRequests.fulfilled]: (state, action) => {
      state.loading = false;
      ApiRequestsAdapter.setAll(state, action.payload);
    },
    [listApiRequests.rejected]: (state, action) => {
      console.error('error: ', action.error);
      state.loading = false;
    },
  },
});

export default ApiRequestsSlice.reducer;
