export const SELECT_ALL_ITEM_LABEL = '*';
export const SELECT_ALL_ITEM_VALUE = '*';
export const VIEW = 'View';
export const VIEW_BILLING = 'ViewBilling';
export const SUBMIT = 'Submit';
export const SCHEDULE = 'Schedule';
export const APPROVE = 'Approve';
export const PERMISSION_TYPES = [VIEW, SUBMIT, SCHEDULE, APPROVE, VIEW_BILLING];
export const ORDER_PERMISSION_TYPES = [VIEW, SCHEDULE, SUBMIT, APPROVE];
export const ALLOWED_PERMISSION_COMBINATIONS = [
  [VIEW],
  [VIEW_BILLING],
  [SUBMIT],
  [SCHEDULE],
  [APPROVE],
  [VIEW, SUBMIT],
  [VIEW, SCHEDULE],
  [SCHEDULE, APPROVE],
];

export const MAX_PERMISSION_LIMIT = 1000;
export function allowedPermissionsToString() {
  let result = '';
  for (let i = 0; i < ALLOWED_PERMISSION_COMBINATIONS.length; i++) {
    result += `<br/>- ${ALLOWED_PERMISSION_COMBINATIONS[i].join(' and ')}`;
  }
  return result;
}
