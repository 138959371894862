import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import { Badge, Link, Snackbar } from '@mui/material';
import Icon from '@mui/material/Icon';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import MuiAlert from '@mui/material/Alert';
import { openDialog } from '../../../store/fuse/dialogSlice';
import { listReceivedUserNotifications } from '../../../main/orders/store/receivedNotificationsSlice';
import ReceivedNotificationsDialog from '../../../main/orders/notifications/ReceivedUserNotificationsDialog';
import { useNavigate } from 'react-router-dom';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ToolbarNotification(props) {
  const user = useSelector(({ auth }) => auth.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loading, data } = useSelector(({ journey }) => journey.receivedUserNotifications);
  const [newNotification, setNewNotification] = useState(false);
  const [lastState, setLastState] = useState([]);
  const [notificationItem, setNotificationItem] = useState("");

  useEffect(() => {
      dispatch(listReceivedUserNotifications({ username: user.data.email }));
      const intervalId = setInterval(() => {
        dispatch(listReceivedUserNotifications({ username: user.data.email }));
      }, 60 * 1000);
      return () => {
        clearInterval(intervalId);
      };
    },
    [dispatch]);

  useEffect(() => {
    if(lastState == null && data) {
    } else if(lastState.length > 0 && data && lastState.length < data.length) {
        setNotificationItem(data[0]);
        setNewNotification(true);
    }
    setLastState(data);
  }, [dispatch, data]);

  const snackBarSeverity = (status) => {
    if(['REJECTED', 'TERMINATED', 'CANCELED'].includes(status))
      return "error";
    if(['WAITING_FOR_APPROVAL'].includes(status))
      return "warning";

    return "info";
    };

  return (
    <>
      <IconButton
        className={clsx('w-40 h-40', props.className)}
        aria-controls="font-size-menu"
        aria-haspopup="true"
        onClick={() => {
          dispatch(
            openDialog({
              children: <ReceivedNotificationsDialog />,
            })
          );
        }}
        size="large"
      >
        <Badge badgeContent={loading ? 0 : data.filter((item) => item.readDate == null).length} color="secondary" max={9}>
          <Icon>notifications</Icon>
        </Badge>
      </IconButton>
      <Snackbar open={newNotification} autoHideDuration={10000} onClose={() => setNewNotification(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <span onClick={() => navigate(`/orders/${notificationItem.orderId}`)} style={{ cursor: 'pointer' }}>
          <Alert severity={snackBarSeverity(notificationItem.status)} sx={{ width: '100%' }}>
            {`Order  ${notificationItem.orderName}  is  ${notificationItem.status}`}
          </Alert>
        </span>
      </Snackbar>
    </>
  );
}

export default ToolbarNotification;
