import FusePageCarded from '@fuse/core/FusePageCarded';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import CustomDataGrid from 'app/shared-components/CustomDataGrid';
import { Button } from '@mui/material';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormInputText from 'app/shared-components/FormInputText';
import Icon from '@mui/material/Icon';
import _ from '@lodash';
import FuseLoading from '@fuse/core/FuseLoading';
import { useTheme } from '@mui/styles';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import withReducer from 'app/store/withReducer';
import GridBigDataColumnRenderer from 'app/shared-components/GridBigDataColumnRenderer';
import { Book } from '@mui/icons-material';
import { listApiRequests, selectApiRequests } from '../store/apiRequestsSlice';
import reducer from '../store';

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 136,
      height: 136,
    },
  },
  '& .FusePageCarded-content': {
    display: 'flex',
  },
  '& .FusePageCarded-contentCard': {
    overflow: 'hidden',
  },
}));

const columns = [
  {
    field: 'eventId',
    headerName: 'Event Id',
    flex: 1.5,
  },
  {
    field: 'creationDate',
    headerName: 'Date of Creation',
    flex: 1,
  },
  { field: 'journeyExecutionId', headerName: 'Journey Execution Id', flex: 1, hide: true },
  { field: 'journeyOrderId', headerName: 'Journey Order Id', flex: 1, hide: true },
  {
    field: 'mno',
    headerName: 'MNO',
    flex: 0.75,
  },
  {
    field: 'stepName',
    headerName: 'Step Name',
    flex: 1,
  },
  {
    field: 'result',
    headerName: 'Result',
    flex: 0.75,
  },
  {
    field: 'subscriberId',
    headerName: 'Subscriber Id',
    flex: 1,
    hide: true,
  },
  {
    field: 'requestPayload',
    headerName: 'Request Payload',
    flex: 1,
    renderCell: (cellValues) => {
      return <GridBigDataColumnRenderer value={cellValues.value} title="Request Payload" />;
    },
  },
  {
    field: 'responsePayload',
    headerName: 'Response Payload',
    flex: 1,
    renderCell: (cellValues) => {
      return <GridBigDataColumnRenderer value={cellValues.value} title="Response Payload" />;
    },
  },
  {
    field: 'callbackPayload',
    headerName: 'Callback Payload',
    flex: 1,
    renderCell: (cellValues) => {
      return <GridBigDataColumnRenderer value={cellValues.value} title="Callback Payload" />;
    },
  },
];

const schema = yup.object().shape({
  msisdn: yup.mixed().test('is-numeric-or-null', 'Msisdn must be numeric', (value) => {
    return value === '' || !Number.isNaN(parseFloat(value));
  }),
  journeyOrderId: yup.string().notRequired(),
});

const defaultValues = {
  msisdn: '',
  journeyOrderId: '',
};

function ApiRequests() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { loading } = useSelector(({ support }) => support.apiRequests);
  const rows = useSelector(selectApiRequests);
  const user = useSelector(({ auth }) => auth.user);

  const { control, reset, handleSubmit, formState, getValues, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, errors } = formState;

  function onSubmit(data) {
    const { msisdn, journeyOrderId } = data;
    dispatch(listApiRequests({ msisdn, journeyOrderId }));
  }

  return (
    <>
      <Root
        header={
          <header className="flex flex-row justify-between items-center w-full">
            <h1 className="text-lg font-bold">Api Requests</h1>
            <form
              className="flex flex-row items-center gap-24 justify-center"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormInputText
                variant="outlined"
                autoFocus
                style={{
                  border: !errors?.msisdn ? '1px solid' : '',
                  borderRadius: '4px',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                control={control}
                name="msisdn"
                placeholder="MSISDN"
                helperText={errors.msisdn?.message}
              />
              <FormInputText
                variant="outlined"
                autoFocus
                style={{
                  border: !errors?.journeyOrderId ? '1px solid' : '',
                  borderRadius: '4px',
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Book />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                control={control}
                name="journeyOrderId"
                placeholder="Journey Order Id"
                helperText={errors.journeyOrderId?.message}
              />
              <Button
                type="submit"
                className="whitespace-nowrap w-160 py-8"
                size="medium"
                variant="contained"
                color="secondary"
                disabled={!formState.isValid || !_.isEmpty(formState.errors)}
              >
                <Icon>search</Icon>
                <span className="py-1 hidden sm:flex">Search</span>
              </Button>
            </form>
          </header>
        }
        content={
          loading ? (
            <FuseLoading />
          ) : (
            <CustomDataGrid
              getRowId={(row) => row.eventId}
              name="ApiRequests"
              rows={rows}
              columns={columns}
            />
          )
        }
        innerScroll
      />
    </>
  );
}

export default withReducer('support', reducer)(ApiRequests);
