export default function getTemplateName(templates, templateId) {
  const template = templates?.find((t) => t.id === templateId);
  return template?.name ?? templateId;
}

export function getAccountName(accounts, billingAccountId) {
  const account = accounts?.find((t) => t.id === billingAccountId);
  return account?.name ? `${account?.name} (${account?.mno})` : billingAccountId;
}

export function getBidPriceCurrency(prices) {
  const auctionPrice = prices?.find((price) => price.code.method === 'AUCTION');
  return auctionPrice?.subscriberPriceCurrency || 'USD';
}

export function getStyledCurrencyNumber(number, currency) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
    minimumFractionDigits: 0,
    maximumFractionDigits: 3,
  })
    .format(number)
    .replace(/^(-?)([A-Z]{3})\s*([0-9,]+(?:\.\d+)?)$/, '$1$3 $2');
}
