import { motion } from 'framer-motion';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useSelector } from 'react-redux';
import LoginForm from './LoginForm';
import ChangePasswordForm from './ChangePasswordForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import ForgotPasswordConfirmationForm from './ForgotPasswordConfirmationForm';
import SMSForm from './SMSForm';
import TOTPForm from './TOTPForm';
import Maintenance from '../maintenance/Maintenance';
import Policy from '../main/policy/Policy';
import SetupMFAForm from './SetupMFAForm';




function Login() {
  const login = useSelector(({ auth }) => auth.login);

  return (
    <div className="flex flex-col flex-auto items-center justify-center p-16 sm:p-32">
      <div className="flex flex-col items-center justify-center w-full">
        <motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>
          <Card className={`w-full ${  !(login.step === 'NOMFA' || login.step === 'SMS_MFA' || login.step === 'SOFTWARE_TOKEN_MFA' ) && 'sm:w-320'}`}>
            {login.step === 'maintenance' ? (
              <CardContent className="max-w-384 flex flex-col items-center justify-center p-16 sm:p-24 md:p-32">
                <Maintenance />
              </CardContent>
            ) : (
              <CardContent className=" flex flex-col items-center justify-center p-16 sm:p-24 md:p-32">
                <img className="w-128 m-32" src="assets/images/logos/onepin_logo.png" alt="logo" />

                {login.step === 'login' && <LoginForm />}
                {login.step === 'temporary' && <ChangePasswordForm />}
                {login.step === 'forgotPassword' && <ForgotPasswordForm />}
                {login.step === 'forgotPasswordConfirmation' && <ForgotPasswordConfirmationForm />}
                {login.step === 'NOMFA' && <SetupMFAForm />}
                {login.step === 'SMS_MFA' && <SMSForm />}
                {login.step === 'SOFTWARE_TOKEN_MFA' && <TOTPForm />}
                {login.step === 'policy' && <Policy readOnly = {false} />}
                {login.step === 'temp' && <br/> }
              </CardContent>
            )}
          </Card>
        </motion.div>
      </div>
    </div>
  );
}

export default Login;
