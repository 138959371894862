import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import withReducer from '../../../store/withReducer';
import reducer from '../store';
import ButtonClickOnce from '../../../shared-components/ButtonClickOnce';
import _ from '../../../../@lodash';

function TemplateHeader(props) {
  const methods = useFormContext();

  const { formState, watch, getValues } = methods;
  const { isValid, dirtyFields } = formState;
  return (
    <div className="flex flex-1 w-full items-center justify-between">
      <Grid container item xs={6} direction="row" justifyContent="flex-start">
        <Grid container item direction="row" xs={2} alignItems="center">
          <Typography component={Link} role="button" to="/support/templates" color="inherit" className="centered">
            <Icon style={{color: "#22d3ee"}} className="text-base centered">arrow_back</Icon>
            <span style={{color: "#22d3ee"}} className="hidden sm:flex mx-4 font-normal hover:underline">Templates</span>
          </Typography>
        </Grid>

        <Grid className="items-center" container item direction="row" xs={11} p={1}>
          <Icon className="text-28 md:text-32 mx-3">code</Icon>
          <Typography className="text-md sm:text-lg truncate font-semibold">
            {props.template && props.template.name}
            {!props.template && 'New Template'}
          </Typography>
        </Grid>
      </Grid>

      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
      >
        <ButtonClickOnce
          className="whitespace-nowrap"
          variant="contained"
          color="secondary"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          onClick={props.onButtonClick}
          text="Save the Template"
        />
      </motion.div>
    </div>
  );
}

export default withReducer('admin', reducer)(TemplateHeader);
