import { lazy } from 'react';
import { roleNames } from '../../auth/authRoles';

const Permissions = lazy(() => import('./permission/Permissions'));
const UserSettings = lazy(() => import('./user-settings/UserSettings'));
const MTNSAProviderIDs = lazy(() => import('./edr/MTNSAProviderIDs'));

const AdminConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: [roleNames.ADMIN, roleNames.SELF_ADMIN],
  routes: [
    {
      path: '/admin/permissions',
      element: <Permissions />,
    },
    {
      path: '/admin/user-settings',
      element: <UserSettings />,
    },
    {
      path: '/admin/mtn-sa-provider-ids',
      element: <MTNSAProviderIDs />,
    },
  ],
};

export default AdminConfig;
