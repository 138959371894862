import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Auth } from 'aws-amplify';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import { showMessage } from '../store/fuse/messageSlice';
import { logoutUser } from '../auth/store/userSlice';
import { reLogin } from '../auth/store/loginSlice';

const logger = new ComponentLogger('SetupMFASMSForm');

function SetupMFASMSForm(props) {
  const dispatch = useDispatch();
  const [smsCode, setSmsCode] = useState(null);
  const [timer, setTimer] = useState(0);
  const timeOutCallback = useCallback(() => setTimer((currTimer) => currTimer - 1), []);
  const { authUser } = props;

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  function handleSmsCodeChange(e) {
    setSmsCode(e.target.value);
  }

  async function sendSmsToPhoneNumber(e) {
    try {
      await Auth.verifyCurrentUserAttribute('phone_number');
      if (!timer) {
        setTimer(180);
      }
      dispatch(showMessage({ message: 'SMS sent successfully!', variant: 'success' }));
    } catch (error) {
      FlightdeckLogger.error(error);
      dispatch(
        showMessage({
          message: 'Unable to send SMS! Please try again later or contact system administrator.',
          variant: 'error',
        })
      );

      // Token is not verified
    }
  }

  async function verifySmsCode() {
    try {
      const user = await Auth.user;
      await Auth.verifyCurrentUserAttributeSubmit('phone_number', smsCode);
      await Auth.setPreferredMFA(user, 'SMS_MFA');
      dispatch(logoutUser());
      dispatch(reLogin());
      dispatch(showMessage({ message: 'Phone number successfully verified!', variant: 'success' }));
    } catch (error) {
      logger.error('verifySmsCode', { error });
      dispatch(
        showMessage({ message: error instanceof Error ? error.message : 'Error occurred. Please try again later.', variant: 'error' })
      );
    }
  }

  function strPadLeft(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }
  function prettifyTimeout(time) {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return `${strPadLeft(minutes, '0', 2)}:${strPadLeft(seconds, '0', 2)}`;
  }

  return (
    <Card className="w-full mb-32">
      {/* {authUser?.user?.attributes?.phone_number_verified && ( */}
      {/*  <Alert severity="info">There is already verified phone number.</Alert> */}
      {/* )} */}
      <div className="px-32 pt-24 ">
        <Typography className="text-lg font-semibold leading-tight">Setup SMS</Typography>
      </div>

      <CardContent className="px-32 py-24">
        <div className="mb-8">
          <Typography>
            Send verification code to: {props.authUser?.user?.attributes?.phone_number || props.authUser?.user?.challengeParam?.userAttributes?.phone_number  }
          </Typography>
        </div>

        <div className="mb-16">
          <Button
            variant="contained"
            color="primary"
            onClick={sendSmsToPhoneNumber}
            disabled={props.timer > 0}
          >
            {timer ? `Send (${prettifyTimeout(timer)})` : 'Send'}
          </Button>
        </div>

        <div className="mb-8">
          <TextField
            value={smsCode}
            onChange={handleSmsCodeChange}
            label="Code"
            type="number"
            variant="outlined"
          />
        </div>

        <div className="mb-8">
          <Button variant="contained" color="primary" onClick={verifySmsCode} disabled={!smsCode}>
            Verify
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}

export default SetupMFASMSForm;
