import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import FuseLoading from '../../@fuse/core/FuseLoading';
import { getAttributes, selectAttributes } from '../main/support/store/attributesSlice';
import { ATTRIBUTE_TYPE_SYSTEM } from '../services/constants';
import withReducer from '../store/withReducer';
import reducer, { getQuickSightEmbedUrl } from '../store/quickSightSlice';

const logger = new ComponentLogger('QuickSightFrame');

function QuickSightFrame({ dashboardIdAttributeName, tags, urlParams }) {
  const dispatch = useDispatch();
  const { embedUrl, loading } = useSelector(({ quickSight }) => quickSight);
  const attributes = useSelector(selectAttributes);
  const { loading: attributesLoading } = useSelector(({ support }) => support.attributes);
  const dashboardId = getDashboardId();

  function getDashboardId() {
    return attributes && attributes.length
      ? attributes.find(
          (attr) => attr.type === ATTRIBUTE_TYPE_SYSTEM && attr.name === dashboardIdAttributeName
        )?.value
      : null;
  }

  useEffect(() => {
    if (!attributes.length) dispatch(getAttributes());
  }, []);

  useEffect(() => {
    if (dashboardId) {
      logger.info('quickSightEmbedUrlRequest', { dashboardId, tags, urlParams });
      const input = {
        dashboardIdAttributeName,
        dashboardId,
        tags: JSON.stringify(tags),
        urlParams,
      };
      dispatch(getQuickSightEmbedUrl(input));
    }
  }, [dashboardId, urlParams, dashboardIdAttributeName, tags, dispatch]);

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {loading || attributesLoading ? (
        <FuseLoading />
      ) : embedUrl ? (
        <iframe
          title={`QuickSightFrame-${dashboardIdAttributeName}`}
          src={embedUrl}
          width="100%"
          height="600"
          className="mb-24"
        />
      ) : (
        <Grid container>
          <Grid container item xs={12} alignItems="center" justifyContent="center">
            <span className="font-bold">
              <Alert severity="error">
                <span className="text-sm">
                  {dashboardId
                    ? 'Unable to load the report! Please try again later or contact the system administrator.'
                    : 'Report Dashboard ID is not configured! Please contact the system administrator.'}
                </span>
              </Alert>
            </span>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default withReducer('quickSight', reducer)(QuickSightFrame);
