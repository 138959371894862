import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormInputDropdown from './FormInputDropdown';
import FuseLoading from '../../@fuse/core/FuseLoading';
import { getPlatforms, selectPlatforms } from '../main/billing-admin/store/platformsSlice';

function FormPlatformDropdown(props) {
  const dispatch = useDispatch();
  const platforms = useSelector(selectPlatforms);
  const { mno } = props;
  const [loading, setLoading] = useState(true);
  const [availablePlatforms, setAvailablePlatforms] = useState([]);

  useEffect(() => {
    dispatch(getPlatforms()).then(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    setAvailablePlatforms(
      platforms
        .filter((platform) => platform.mno === mno)
        .map((platform) => {
          return {
            value: platform.id,
            label:
              platform.name === platform.mno ? platform.name : `${platform.name} (${platform.mno})`,
          };
        })
    );
  }, [mno, platforms]);

  if (loading) {
    return <FuseLoading />;
  }

  return <FormInputDropdown {...props} options={availablePlatforms} />;
}

export default FormPlatformDropdown;
