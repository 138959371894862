import { Card, Grid, Typography } from '@mui/material';

function JourneyInfo({ data }) {
  return (
    <Card className="w-full rounded-10 shadow p-10">
      <Grid item xs={12}>
        <Typography variant="body1" style={{ whiteSpace: 'pre-line', alignItems: 'center' }}>
          {data.info || 'No step info defined in the journey template.'}
        </Typography>
      </Grid>
    </Card>
  );
}
export default JourneyInfo;
