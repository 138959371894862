import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridLinkOperator,
} from '@mui/x-data-grid-pro';
import filterUtil from './filterUtil';
import { isValidDate } from './dateUtil';

export function getIdFilter(orderId) {
  return {
    filter: {
      items: [
        {
          value: orderId,
          columnField: 'id',
          operatorValue: 'is',
          id: 40000,
        },
      ],
    },
  };
}

export function convertColumnFieldToQueryField(columnField) {
  const columnMap = {
    state: 'status',
    billingAccountId: 'billingAccountId',
    campaignTemplateId: 'templateId',
    campaignName: 'name',
  };

  return columnMap[columnField] || columnField;
}

export function convertFilterToGraphQL(model) {
  function createCondition(operatorValue, queryField, value) {
    const condition = {};
    if (value) {
      switch (operatorValue) {
        case 'isAnyOf':
          condition.or = value.map((element) => ({ [queryField]: { eq: element } }));
          break;
        case 'equals':
        case '=':
        case 'is':
          if (queryField === 'product') {
            if (Array.isArray(value)) {
              condition.or = value.map((element) => ({ [queryField]: { eq: element } }));
            } else {
              condition[queryField] = { wildcard: `*${value}*` };
            }
          } else if (isValidDate(value)) {
            condition[queryField] = { match: value };
          } else {
            condition[queryField] = { eq: value };
          }
          break;
        case 'contains':
          condition[queryField] = { wildcard: `*${value}*` };
          break;
        case 'not':
        case '!=':
          condition[queryField] = { ne: value };
          break;
        case 'onOrAfter':
        case '>=':
          condition[queryField] = { gte: value };
          break;
        case 'after':
        case '>':
          condition[queryField] = { gt: value };
          break;
        case 'before':
        case '<':
          condition[queryField] = { lt: value };
          break;
        case 'onOrBefore':
        case '<=':
          condition[queryField] = { lte: value };
          break;
        case 'startsWith':
          condition[queryField] = { matchPhrasePrefix: value };
          break;
        case 'isEmpty':
          condition[queryField] = { eq: '' };
          break;
        case 'isNotEmpty':
          condition[queryField] = { ne: '' };
          break;
        default:
          break;
      }
    }
    return condition;
  }

  let graphqlFilter = {};

  if (model.items.length === 1) {
    const { columnField, operatorValue, value } = model.items[0];
    const queryField = convertColumnFieldToQueryField(columnField);
    graphqlFilter = createCondition(operatorValue, queryField, value);
  } else {
    graphqlFilter[model.linkOperator || 'and'] = [];
    model.items.forEach((item) => {
      const { columnField, operatorValue, value } = item;
      const queryField = convertColumnFieldToQueryField(columnField);
      const condition = createCondition(operatorValue, queryField, value);
      if (Object.keys(condition).length > 0) {
        graphqlFilter[model.linkOperator || 'and'].push(condition);
      }
    });
  }

  return graphqlFilter;
}

export function getDateFilterOperators() {
  return getGridDateOperators().filter(
    (operator) =>
      operator.value === 'is' ||
      operator.value === 'after' ||
      operator.value === 'before' ||
      operator.value === 'onOrBefore' ||
      operator.value === 'onOrAfter'
  );
}

export function getNumberFilterOperators() {
  return getGridNumericOperators().filter(
    (operator) =>
      operator.value === '=' ||
      operator.value === '!=' ||
      operator.value === '>' ||
      operator.value === '<' ||
      operator.value === '>=' ||
      operator.value === '<='
  );
}

export function getStringFilterOperators() {
  return getGridStringOperators().filter(
    (operator) =>
      operator.value === 'contains' ||
      operator.value === 'equals' ||
      operator.value === 'startsWith'
  );
}

export function getLinkOperator(permissions) {
  if (filterUtil.isEligible(permissions, 'mno')) {
    return [GridLinkOperator.And, GridLinkOperator.Or];
  }
  return [GridLinkOperator.And];
}

export function getEntitiesByPermissions(permissions, entities, entityType) {
  const entityList = [];
  if (permissions.length > 0 && entities.length > 0) {
    for (const permission of permissions) {
      for (const entity of permission[`${entityType}`]) {
        if (entity === '*') {
          entityList.push(
            ...entities.filter(
              (e) => permission.mno.includes(e.mno) || permission.mno.includes('*')
            )
          );
        } else {
          entityList.push(entities.find((e) => e.id === entity));
        }
      }
    }
  }
  return entityList;
}

export function getPermissionFilterOperators(permissions, keyword) {
  const eligibleOperators = filterUtil.isEligible(permissions, keyword)
    ? ['is', 'isAnyOf', 'not']
    : ['is', 'isAnyOf'];

  return getGridSingleSelectOperators().filter((operator) =>
    eligibleOperators.includes(operator.value)
  );
}
