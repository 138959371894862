import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { logoutUser } from 'app/auth/store/userSlice';
import { useDispatch } from 'react-redux';

function Error401Page() {
  const dispatch = useDispatch();

  return (
    <div className="flex flex-col flex-1 items-center justify-center p-16">
      <div className="max-w-512 text-center">
        <motion.div
          initial={{ opacity: 0, scale: 0.6 }}
          animate={{ opacity: 1, scale: 1, transition: { delay: 0.1 } }}
        >
          <Typography variant="h1" color="inherit" className="font-medium mb-16">
            401
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
        >
          <Typography variant="h5" color="textSecondary" className="mb-16 font-normal">
            Sorry, you don't have sufficient privileges to access the system. Please contact the
            system administrator.
          </Typography>
        </motion.div>

        <Link className="font-normal" to="/login" onClick={() => dispatch(logoutUser())}>
          Go back to Login page
        </Link>
      </div>
    </div>
  );
}

export default Error401Page;
