import { combineReducers } from '@reduxjs/toolkit';
import attributes from './attributesSlice';
import journeys from './journeysSlice';
import mnos from './mnosSlice';
import apiRequests from './apiRequestsSlice';

const reducer = combineReducers({
  attributes,
  journeys,
  mnos,
  apiRequests,
});

export default reducer;
