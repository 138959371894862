import FusePageCarded from '@fuse/core/FusePageCarded';
import { styled } from '@mui/material/styles';
import { DataGridPro as DataGrid, GridToolbar } from '@mui/x-data-grid-pro';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import withReducer from '../../../store/withReducer';
import reducer from '../store';
import PageHeader from '../../../shared-components/PageHeader';
import AttributeDialog from './AttributeDialog';
import AttributeAction from './AttributeAction';
import { selectAttributes, getAttributes, openNewAttributeDialog } from '../store/attributesSlice';
import { ATTRIBUTE_TYPE_SYSTEM } from '../../../services/constants';

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 136,
      height: 136,
    },
  },
  '& .FusePageCarded-content': {
    display: 'flex',
  },
  '& .FusePageCarded-contentCard': {
    overflow: 'hidden',
  },
}));

const columns = [
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'value', headerName: 'Value', flex: 2 },
  { field: 'type', headerName: 'Type', flex: 0.5 },
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    flex: 0.75,
    renderCell: (cellValues) => {
      return <AttributeAction cellValues={cellValues} />;
    },
  },
];

function Attributes({ isSystem, ...props }) {
  const dispatch = useDispatch();
  const attributesData = useSelector(({ support }) => support.attributes);
  const attributes = useSelector(selectAttributes)?.filter(
    (attr) =>
      (isSystem && attr.type === ATTRIBUTE_TYPE_SYSTEM) ||
      (!isSystem && attr.type !== ATTRIBUTE_TYPE_SYSTEM)
  );
  const { loading } = attributesData;

  useEffect(() => {
    dispatch(getAttributes());
  }, []);

  const getColumns = () => {
    if (isSystem) return columns.filter((c) => c.field !== 'type');
    return columns;
  };

  return (
    <>
      <Root
        header={
          <PageHeader
            iconName="group"
            title={isSystem ? 'System Attributes' : 'Attributes'}
            buttonName="New Attribute"
            onButtonClick={() => dispatch(openNewAttributeDialog())}
          />
        }
        content={
          loading ? (
            <FuseLoading />
          ) : (
            <DataGrid
              rows={attributes}
              columns={getColumns()}
              autoPageSize
              pagination
              disableColumnResize={false}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          )
        }
        innerScroll
      />
      <AttributeDialog attributes={attributes} isSystem={isSystem} />
    </>
  );
}

export default withReducer('support', reducer)(Attributes);
