import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import format from 'date-fns/format';
import { parseISO } from 'date-fns';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { Constants } from '../services/constants';

function FormInputText({ name, control, customHandler, label, ...props }) {
  function isValidDate(date) {
    try {
      format(date, Constants.DEFAULT_DATE_FORMAT);
      return true;
    } catch (e) {
      return false;
    }
  }

  if (props.type === 'date') {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            {...field}
            {...props}
            views={['day']}
            label={label}
            inputFormat={Constants.DEFAULT_DATE_FORMAT}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={error?.message}
                error={!!error}
                fullWidth
                {...props}
              />
            )}
            value={parseISO(field.value)}
            onChange={(d) => {
              isValidDate(d)
                ? field.onChange(format(d, Constants.DEFAULT_DATE_FORMAT))
                : field.onChange(d);
              if (customHandler) customHandler(d);
            }}
          />
        )}
      />
    );
  }
  if (props.type === 'datetime') {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const handleChange = (time) => {
            if (isValidDate(time) ){
              const formattedTime = time ? format(time, Constants.DEFAULT_TIME_FORMAT) : ''; // Convert to string format 'HH:mm'
              onChange(formattedTime);
              if (customHandler) customHandler(time);
            }
            else{
              onChange(time);
            }
          };
          const parsedTime = value ? moment(value, Constants.DEFAULT_TIME_FORMAT) : null; // Parse string value to moment object (requires moment.js library)
          return (
            <TimePicker
              {...props}
              value={parsedTime}
              onChange={handleChange}
              label={label}
              ampm={false}
              renderInput={(params) => (
                <TextField {...params} helperText={error?.message} error={!!error} fullWidth />
              )}
            />
          );
        }}
      />
    );
  }
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          error={!!error}
          helperText={error?.message}
          label={label}
          fullWidth
          {...props}
        />
      )}
    />
  );
}

export default FormInputText;
