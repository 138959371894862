import React, { useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { setVersion } from '../store/fuse/settingsSlice';

function AutoReload(props) {
  const dispatch = useDispatch();
  let previousHash = null;
  const [codeHasChanged, setCodeHasChanged] = useState(false);
  const { url, tryDelay } = props;

  async function fetchSource() {
    try {
      const [response, responseLocal] = await Promise.all([
        fetch(url, {
          headers: {
            'Cache-Control': 'no-cache',
            pragma: 'no-cache',
          },
        }),
        fetch(url),
      ]);

      if (response.status !== 200 || responseLocal.status !== 200) {
        throw new Error('offline');
      }

      const [html, htmlLocal] = await Promise.all([response.text(), responseLocal.text()]);

      const hashedPage = hash(html);
      const hashedPageLocal = hash(htmlLocal);
      dispatch(setVersion(hashedPage));

      if (hashedPageLocal !== hashedPage) {
        setCodeHasChanged(true);
      } else if (!previousHash) {
        previousHash = hashedPage;
      } else if (previousHash !== hashedPage) {
        previousHash = hashedPage;
        setCodeHasChanged(true);
      }
    } catch (error) {
      // Handle any errors here if needed
    }
  }
  /**
   * Java-like hashCode function for strings
   *
   * taken from http://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript-jquery/7616484#7616484
   */
  function hash(str) {
    const len = str.length;
    let hash = 0;
    if (len === 0) return hash;
    let i;
    for (i = 0; i < len; i++) {
      hash = (hash << 5) - hash + str.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  const reloadApp = () => {
    // Clear the cache by appending a unique query parameter to the URL
    const updatedUrl = `${window.location.href}?v=${new Date().getTime()}`;

    // Reload the page with the updated URL
    window.location.href = updatedUrl;
  };

  useEffect(() => {
    fetchSource();
    const timer = setInterval(() => {
      fetchSource();
    }, 60000); // 1 minutes
    return () => clearInterval(timer);
  }, []);

  const action = (
    <>
      <Button color="secondary" size="small" onClick={reloadApp}>
        RELOAD
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={reloadApp}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Snackbar
      open={codeHasChanged}
      onClose={reloadApp}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      message="New version is found!"
      action={action}
    />
  );
}

export default React.memo(AutoReload);
