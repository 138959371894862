import { yupResolver } from '@hookform/resolvers/yup';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from '@lodash';
import * as yup from 'yup';

import { Grid } from '@mui/material';
import Alert from '@mui/material/Alert';
import {
  closeNewAttributeDialog,
  closeEditAttributeDialog,
  saveAttribute,
} from '../store/attributesSlice';
import FormInputDropdown from '../../../shared-components/FormInputDropdown';
import { ATTRIBUTE_TYPE_SYSTEM } from '../../../services/constants';
import { openDialog } from '../../../store/fuse/dialogSlice';
import SystemAttributesInfo from './SystemAttributesInfo';

export const ATTRIBUTE_TYPES = ['Service', 'EDR', 'Custom Tag'];

const defaultValues = (isSystem) => ({
  name: '',
  value: '',
  type: isSystem ? ATTRIBUTE_TYPE_SYSTEM : ATTRIBUTE_TYPES[0],
});

/**
 * Form Validation Schema
 */
const schema = (isSystem) =>
  yup.object().shape({
    name: yup.string().required('You must enter attribute name'),
    value: yup.string().required('You must enter attribute value'),
    type: isSystem
      ? yup.string().notRequired()
      : yup.string().required('You must select attribute type'),
  });

function AttributeDialog({ isSystem, ...props }) {
  const dispatch = useDispatch();
  const attributeDialog = useSelector(({ support }) => support.attributes.attributeDialog);
  const user = useSelector(({ auth }) => auth.user);


  const { control, reset, handleSubmit, formState, getValues, setValue } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues(isSystem),
    resolver: yupResolver(schema(isSystem)),
  });

  const { isValid, dirtyFields, errors } = formState;

  const initDialog = useCallback(() => {
    /**
     * Dialog type: 'edit'
     */
    if (attributeDialog.type === 'edit' && attributeDialog.data) {
      reset({ ...defaultValues, ...attributeDialog.data });
    }

    /**
     * Dialog type: 'new'
     */
    if (attributeDialog.type === 'new') {
      reset({
        ...defaultValues,
        ...attributeDialog.data,
      });
    }
  }, [getValues, attributeDialog.data, attributeDialog.type, reset]);

  /**
   * On Dialog Open
   */
  useEffect(() => {
    if (attributeDialog.props.open) {
      initDialog();
    }
  }, [attributeDialog.props.open, initDialog]);

  /**
   * Close Dialog
   */
  function closeAttributeDialog() {
    return attributeDialog.type === 'edit'
      ? dispatch(closeEditAttributeDialog())
      : dispatch(closeNewAttributeDialog());
  }

  /**
   * Form Submit
   */
  function onSubmit(data) {
    if (isSystem) data.type = ATTRIBUTE_TYPE_SYSTEM;
    data.updaterId = user.data.email;
    if (attributeDialog.type === 'new') {
      dispatch(saveAttribute(data));
    } else {
      dispatch(saveAttribute({ ...attributeDialog.data, ...data }));
    }
    closeAttributeDialog();
  }

  return (
    <Dialog
      classes={{
        paper: 'm-24',
      }}
      {...attributeDialog.props}
      onClose={closeAttributeDialog}
      fullWidth
      maxWidth="sm"
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            {attributeDialog.type === 'new' ? 'New ' : 'Edit '}
            {isSystem ? 'System ' : ''}Attribute
          </Typography>
        </Toolbar>
      </AppBar>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col md:overflow-hidden"
      >
        <DialogContent classes={{ root: 'p-24' }}>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item>
              {isSystem && (
                <Alert severity="info">
                  <span style={{ fontSize: '1.2rem' }}>
                    Please make sure that you enter one of the
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() =>
                        dispatch(
                          openDialog({
                            children: <SystemAttributesInfo />,
                          })
                        )
                      }
                      title="Show Supported System Attributes"
                    >
                      Supported System Attributes
                    </Button>
                    into the "Name" field.
                  </span>
                </Alert>
              )}
            </Grid>
            <Grid item>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Name"
                    id="name"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            </Grid>

            <Grid item>
              <Controller
                control={control}
                name="value"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Value"
                    id="value"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    variant="outlined"
                    required
                    fullWidth
                    multiline
                  />
                )}
              />
            </Grid>

            {!isSystem && (
              <Grid item>
                <FormInputDropdown
                  name="type"
                  label="Attribute Type"
                  control={control}
                  required
                  options={ATTRIBUTE_TYPES.map((type) => ({ value: type }))}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <DialogActions className="justify-start pl-24 pb-16">
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={_.isEmpty(dirtyFields) || !isValid}
          >
            {attributeDialog.type === 'new' ? 'Add' : 'Save'}
          </Button>

          <Button
            variant="outlined"
            color="error"
            type="button"
            onClick={() => closeAttributeDialog()}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AttributeDialog;
