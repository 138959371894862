import FusePageCarded from '@fuse/core/FusePageCarded';
import { styled } from '@mui/material/styles';
import { DataGridPro as DataGrid, GridToolbar } from '@mui/x-data-grid-pro';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import withReducer from '../../../store/withReducer';
import reducer from '../store';
import PageHeader from '../../../shared-components/PageHeader';
import { getMnos, openMnoDialog, selectMnos } from '../store/mnosSlice';
import MnoDialog from './MnoDialog';

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 136,
      height: 136,
    },
  },
  '& .FusePageCarded-content': {
    display: 'flex',
  },
  '& .FusePageCarded-contentCard': {
    overflow: 'hidden',
  },
}));

const columns = [
  { field: 'id', headerName: 'ID', flex: 1 },
  { field: 'mno', headerName: 'Mno', flex: 1 },
  { field: 'displayName', headerName: 'Display Name', flex: 1 },
  { field: 'company', headerName: 'Company', flex: 1 },
  { field: 'country', headerName: 'Country', flex: 1 },
  { field: 'countryIsoCode', headerName: 'Country Iso Code', flex: 1 },
  { field: 'localCurrency', headerName: 'Local Currency', flex: 1 },
  { field: 'timeZoneOffset', headerName: 'Time Zone Offset', flex: 1 },
  { field: 'status', headerName: 'Status', flex: 1 },
];

function Mnos({ isSystem, ...props }) {
  const dispatch = useDispatch();
  const mnosData = useSelector(({ support }) => support.mnos);
  const mnos = useSelector(selectMnos);
  const { loading } = mnosData;

  useEffect(() => {
    dispatch(getMnos());
  }, []);

  function onRowClick({ row }) {
    dispatch(openMnoDialog({ actionType: 'edit', actionData: row }));
  }

  return (
    <>
      <Root
        header={
          <PageHeader
            iconName="group"
            title="MNO"
            buttonName="New Attribute"
            onButtonClick={() => dispatch(openMnoDialog({ actionType: 'new' }))}
          />
        }
        content={
          loading ? (
            <FuseLoading />
          ) : (
            <DataGrid
              rows={mnos}
              onRowClick={onRowClick}
              columns={columns}
              autoPageSize
              pagination
              disableColumnResize={false}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          )
        }
        innerScroll
      />
      <MnoDialog />
    </>
  );
}

export default withReducer('support', reducer)(Mnos);
