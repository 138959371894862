import { SELECT_ALL_ITEM_VALUE } from '../main/admin/permission/constants';
import { mergeArrays } from './filterUtil';

const N_A = 'N/A';

export function getMergedPermissionAttribute(permissions, attribute) {
  if (!permissions.length) return [];
  const mergeResult = mergeArrays(permissions.map((permission) => permission[attribute]));
  return mergeResult && mergeResult.includes(SELECT_ALL_ITEM_VALUE)
    ? [SELECT_ALL_ITEM_VALUE]
    : mergeResult;
}

export function convertMnoNamesToIDs(mnoDefinitions, mnoNames) {
  if (!mnoNames.length || mnoNames.includes(SELECT_ALL_ITEM_VALUE)) return mnoNames;

  return mnoNames.map(
    (mnoName) => mnoDefinitions.find((mnoDef) => mnoDef.mno === mnoName)?.id || N_A
  );
}

export function convertProductFamilyNamesToIDs(
  mnoDefinitions,
  productFamilyDefinitions,
  productFamilyNames
) {
  if (!productFamilyNames.length || productFamilyNames.includes(SELECT_ALL_ITEM_VALUE))
    return productFamilyNames;

  return productFamilyNames.map((productFamily) => {
    if (!productFamily) return N_A;
    const productFamilyArray = productFamily.split(':');
    if (productFamilyArray.length > 1) {
      const mnoName = productFamilyArray[0];
      const productFamilyName = productFamilyArray[1];
      return `${mnoDefinitions.find((mnoDef) => mnoDef.mno === mnoName)?.id || N_A}:${
        productFamilyDefinitions.find((prodDef) => prodDef.productFamilyName === productFamilyName)
          ?.id || N_A
      }`;
    }
    return productFamily;
  });
}
