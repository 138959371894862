import FlowNode from '../shared-components/flow/FlowNode';
import DisplayTextForm from '../main/orders/order/journey/forms/DisplayTextForm';
import SelectFromMenuForm from '../main/orders/order/journey/forms/SelectFromMenuForm';
import LaunchBrowserForm from '../main/orders/order/journey/forms/LaunchBrowserForm';
import ServiceAPICallForm from '../main/orders/order/journey/forms/ServiceApiCallForms/ServiceAPICallForm';
import SendTextMessageToSubscriberForm from '../main/orders/order/journey/forms/SendTextMessageToSubscriberForm';
import SendTextMessageFromSubscriberForm from '../main/orders/order/journey/forms/SendTextMessageFromSubscriberForm';
import SendUssdStringForm from '../main/orders/order/journey/forms/SendUssdStringForm';
import DsmDoiForm from '../main/orders/order/journey/forms/DsmDoiForm';

export const Constants = {
  DEFAULT_DATE_FORMAT: 'yyyy-MM-dd',
  DEFAULT_DATE_TIME_FORMAT: 'yyyy-MM-dd HH:mm',
  DEFAULT_TIME_FORMAT: 'HH:mm',
  DEFAULT_DATE_TIME_FULL_FORMAT: 'yyyy-MM-dd HH:mm:ss',
  FILE_UPLOAD_MAX_COUNT: 5,
  FILE_UPLOAD_MAX_BYTES: 157286400,
  FILE_UPLOAD_LINE_BYTES: 15,
  MNO_UTC_OFFSETS: {
    mtn_sa: 2,
    mtn_ng: 1,
    mtn_cm: 1,
    mtn_ci: 0,
    orange_drc: 1,
    orange_cm: 1,
    mtn_gh: 0,
    sunrise_ch: 1,
    BERD_3: 3,
    OP_CO: 3,
  },
};

export const JourneyResultMap = {
  PROCESSED: { name: 'Processed', color: 'green', orderBy: 1 },
  RESPONSE_NOT_RECEIVED: { name: 'No Response (Expired)', color: 'blue', orderBy: 2 },
  OK: { name: 'OK', color: 'green', orderBy: 3 },
  200: { name: '200', color: 'green', orderBy: 3 },
  ACCEPT: { name: 'ACCEPT', color: 'green', orderBy: 3 },
  RESPONSE_RECEIVED_SUCCESS: { name: 'OK', color: 'green', orderBy: 3 },
  RESPONSE_RECEIVED_BACK: { name: 'BACK', color: 'red', orderBy: 4 },
  RESPONSE_RECEIVED_CANCEL: { name: 'CANCEL', color: 'red', orderBy: 5 },
  RESPONSE_RECEIVED_TIMEOUT: { name: 'TIMEOUT', color: 'blue', orderBy: 6 },
  RESPONSE_RECEIVED_TEMPORARY_ERROR: { name: 'Temporary Error', color: 'blue', orderBy: 7 },
  RESPONSE_RECEIVED_PERMANENT_ERROR: { name: 'Permanent Error', color: 'blue', orderBy: 8 },
  REJECTED_ENGAGEMENT_NOT_SUPPORTED: { name: 'Not Supported', orderBy: 99 },
  REJECTED_SUBSCRIBER_NOT_FOUND: { name: 'Subscriber not found', orderBy: 99 },
  REJECTED_HANDSET_IS_NOT_SUPPORTED: { name: 'Handset is not supported', orderBy: 99 },
  REJECTED_DISABLED_SUBSCRIBER: { name: 'Disabled subscriber', orderBy: 99 },
  REJECTED_CONTACT_POLICY_EXCEEDED: { name: 'Contact policy exceeded', orderBy: 199 },
  REJECTED_INVALID_CONTACT_POLICY_ID: { name: 'System error', orderBy: 199 },
  REJECTED_INVALID_JOURNEY_ID: { name: 'Invalid Journey Id', orderBy: 199 },
  REJECTED_EXPIRED_JOURNEY_ID: { name: 'Expired Journey Id', orderBy: 199 },
  RESPONSE_RECEIVED_USER_HELP_REQUESTED: { name: 'User Help Requested', orderBy: 199 },
  REJECTED_UNSUPPORTED_MSISDN: { name: 'Unsupported Msisdn', orderBy: 199 },
  REJECTED_SYSTEM_ERROR: { name: 'System Error', color: 'blue', orderBy: 199 },
};

export const DEPResultMap = {
  PROCESSED: { name: 'Processed', color: 'green', orderBy: 1 },
  0: { name: 'Success', color: 'green', orderBy: 1 },
  1: { name: 'Failed', color: 'green', orderBy: 1 },
  200: { name: 'Success', color: 'green', orderBy: 1 },
  429: { name: 'Too many requests', color: 'green', orderBy: 2 },
  452: { name: 'Invalid API Version', color: 'green', orderBy: 3 },
  454: { name: 'MSISDN not provided', color: 'green', orderBy: 4 },
  464: { name: 'Subscription not found', color: 'green', orderBy: 5 },
  466: { name: 'Service not found', color: 'green', orderBy: 6 },
  467: { name: 'Active Subscription Exists', color: 'green', orderBy: 7 },
  468: { name: 'Subscription not pending', color: 'green', orderBy: 8 },
  470: { name: 'Service not linked to Billing Channel', color: 'green', orderBy: 9 },
  472: { name: 'Service not linked to Billing Channel', color: 'green', orderBy: 10 },
  473: { name: 'Parameter: "svc_id|svc_ref" not found', color: 'green', orderBy: 11 },
  478: { name: 'Invalid MSISDN', color: 'green', orderBy: 12 },
  480: { name: 'Invalid billing rate', color: 'green', orderBy: 13 },
  485: { name: 'Pending Subscription Exists', color: 'green', orderBy: 14 },
  500: { name: 'Internal Server Error', color: 'green', orderBy: 15 },
  514: { name: 'DOI request failed', color: 'green', orderBy: 16 },
};

export const DSMResultMap = {
  PROCESSED: { name: 'Processed', color: 'green', orderBy: 1 },
  '0(AUTO_RENEW_Y)': { name: 'Success with Auto-renewal', color: 'green', orderBy: 1 },
  '0(AUTO_RENEW_N)': { name: 'Success with One-off', color: 'green', orderBy: 1 },
  0: { name: 'Success', color: 'green', orderBy: 1 },
  2002: { name: 'Internal Error', color: 'red', orderBy: 2 },
  2032: { name: 'Subscriber has insufficient balance', color: 'red', orderBy: 2 },
  4005: { name: 'Charging Error', color: 'red', orderBy: 2 },
  1002: { name: 'Validation class missing for Request API', color: 'red', orderBy: 2 },
  2061: { name: 'Invalid Request', color: 'red', orderBy: 2 },
  2072: { name: 'Not a Valid Plan', color: 'red', orderBy: 2 },
  2084: { name: 'You have Already Subscribed Requested Services', color: 'red', orderBy: 2 },
  2088: { name: 'You have not subscribed this service', color: 'red', orderBy: 2 },
  2095: { name: 'Blank request received', color: 'red', orderBy: 2 },
  3018: {
    name: 'Dear subscriber, You have already unsubscribed this service',
    color: 'red',
    orderBy: 2,
  },
  3024: { name: 'Service id not configured for service node', color: 'red', orderBy: 2 },
  3033: { name: 'Invalid bearer Id', color: 'red', orderBy: 2 },
  3051: {
    name: 'Service node in request XML not matching with allowed service node in request URL',
    color: 'red',
    orderBy: 2,
  },
  4105: { name: 'MSISDN is blacklisted', color: 'red', orderBy: 2 },
  7001: { name: 'Your request under process', color: 'red', orderBy: 2 },
  101: { name: 'Consent failure: User Cancelled', color: 'red', orderBy: 2 },
  102: { name: 'Consent failure: User No Input', color: 'red', orderBy: 2 },
  103: { name: 'Consent failure: Other Error', color: 'red', orderBy: 2 },
};

export const OrderStatus = {
  DRAFT: {
    name: 'DRAFT',
    isEditable: true,
    isViewable: true,
    isCancelable: true,
    color: 'primary',
    order: -1,
    allowedStates() {
      return [
        OrderStatus.DRAFT,
        OrderStatus.CANCELED,
        OrderStatus.WAITING_FOR_APPROVAL,
        OrderStatus.SCHEDULED,
      ];
    },
  },
  WAITING_FOR_APPROVAL: {
    name: 'WAITING_FOR_APPROVAL',
    isEditable: true,
    isViewable: true,
    isCancelable: true,
    isApprovable: true,
    order: 1,
    allowedStates() {
      return [
        OrderStatus.CANCELED,
        OrderStatus.REJECTED,
        OrderStatus.SCHEDULED,
        OrderStatus.WAITING_FOR_APPROVAL,
      ];
    },
  },
  SCHEDULED: {
    name: 'SCHEDULED',
    isEditable: true,
    isViewable: true,
    isCancelable: true,
    order: 2,
    allowedStates() {
      return [OrderStatus.SCHEDULED, OrderStatus.CANCELED, OrderStatus.ACTIVE];
    },
  },
  ACTIVE: {
    name: 'ACTIVE',
    isViewable: true,
    isCanTerminated: true,
    order: 3,
    allowedStates() {
      return [OrderStatus.TERMINATED];
    },
  },
  CANCELED: {
    name: 'CANCELED',
    isViewable: true,
    order: 4,
    allowedStates() {
      return [];
    },
  },
  REJECTED: {
    name: 'REJECTED',
    isViewable: true,
    isEditable: true,
    isCancelable: true,
    order: 5,
    allowedStates() {
      return [OrderStatus.WAITING_FOR_APPROVAL, OrderStatus.CANCELED];
    },
  },
  TERMINATED: {
    name: 'TERMINATED',
    isViewable: true,
    order: 6,
    allowedStates() {
      return [];
    },
  },
  COMPLETED: {
    name: 'COMPLETED',
    isViewable: true,
    order: 7,
    allowedStates() {
      return [];
    },
  },
};

export const EXCLUDE_DRAFT_FILTER = { status: { ne: OrderStatus.DRAFT.name } };

export const MNO_MAP = {
  mtn_sa: {
    currency: 'ZAR',
    minimumBid: 81,
    maximumBid: 1000000,
    priceConstant: 1.3,
    bidPriceHelperText: 'Price per one thousand engagements',
    sundayExecutionDisabled: true,
  },
  mtn_ng: {
    currency: 'NGN',
    minimumBid: 2687.5,
    maximumBid: 1000000,
    priceConstant: 1,
    bidPriceHelperText: 'Price per one thousand messages',
    minTime: new Date(0, 0, 0, 8),
    maxTime: new Date(0, 0, 0, 18, 59),
  },
  mtn_cm: {
    currency: 'USD',
    minimumBid: 2,
    maximumBid: 1000000,
    priceConstant: 1,
    bidPriceHelperText: 'Price per one thousand messages',
  },
  mtn_ci: {
    currency: 'USD',
    minimumBid: 2,
    maximumBid: 1000000,
    priceConstant: 1,
    bidPriceHelperText: 'Price per one thousand messages',
  },
  orange_drc: {
    currency: 'USD',
    minimumBid: 2,
    maximumBid: 1000000,
    priceConstant: 1,
    bidPriceHelperText: 'Price per one thousand messages',
  },
  orange_cm: {
    currency: 'USD',
    minimumBid: 2,
    maximumBid: 1000000,
    priceConstant: 1,
    bidPriceHelperText: 'Price per one thousand messages',
  },
  mtn_gh: {
    currency: 'USD',
    minimumBid: 0.5,
    maximumBid: 1000000,
    priceConstant: 1,
    bidPriceHelperText: 'Price per one thousand messages',
  },
  sunrise_ch: {
    currency: 'USD',
    minimumBid: 2,
    maximumBid: 1000000,
    priceConstant: 1,
    bidPriceHelperText: 'Price per one thousand messages',
  },
  OP_CO: {
    currency: 'USD',
    minimumBid: 81,
    maximumBid: 2000,
    priceConstant: 1,
    bidPriceHelperText: 'Price per one thousand messages',
    minTime: new Date(0, 0, 0, 8),
    maxTime: new Date(0, 0, 0, 18, 59),
    sundayExecutionDisabled: true,
  },
  BERD_3: {
    currency: 'USD',
    minimumBid: 81,
    maximumBid: 2000,
    priceConstant: 1,
    bidPriceHelperText: 'Price per one thousand messages',
    minTime: new Date(0, 0, 0, 8),
    maxTime: new Date(0, 0, 0, 18, 59),
    sundayExecutionDisabled: true,
  },
  cxnpi_t1: {
    currency: 'USD',
    minimumBid: 81,
    maximumBid: 2000,
    priceConstant: 1,
    bidPriceHelperText: 'Price per one thousand messages',
  },
};

export function getOrderStatusByName(value) {
  return OrderStatus[
    Object.keys(OrderStatus).find(
      (key) => OrderStatus[key].name.toLowerCase() === value.toLowerCase()
    )
  ];
}

export const JourneyStepTypeMap = {
  DisplayTextStep: {
    name: 'DisplayTextStep',
    node: FlowNode,
    form: DisplayTextForm,
    isEditable: true,
    previewEnabled: true,
    required: ['text', 'optionalDisplayText'],
    isContentCanBeCopied: true,
  },
  SelectFromMenuStep: {
    name: 'SelectFromMenuStep',
    node: FlowNode,
    form: SelectFromMenuForm,
    isEditable: true,
    previewEnabled: true,
    required: ['menuTitle', 'menuItemList'],
    isContentCanBeCopied: true,
  },
  DsmDoiStep: {
    name: 'DsmDoiStep',
    node: FlowNode,
    form: DsmDoiForm,
    isEditable: true,
    previewEnabled: true,
    required: ['productId', 'menuTitle', 'menuItemList'],
    isContentCanBeCopied: false,
  },
  LaunchWebBrowserStep: {
    name: 'LaunchWebBrowserStep',
    node: FlowNode,
    form: LaunchBrowserForm,
    isEditable: true,
    previewEnabled: true,
    required: ['url', 'optionalDisplayText'],
    isContentCanBeCopied: true,
  },
  RemindMeLaterStep: {
    name: 'RemindMeLaterStep',
    node: FlowNode,
    isEditable: false,
    title: 'Access Controls',
    isContentCanBeCopied: true,
  },
  ServiceCallStep: {
    name: 'ServiceCallStep',
    form: ServiceAPICallForm,
    node: FlowNode,
    isEditable: true,
    required: ['variables.serviceId', 'variables.tuneId'],
    isContentCanBeCopied: false,
  },
  SendTextMessageToSubscriberStep: {
    name: 'SendTextMessageToSubscriberStep',
    node: FlowNode,
    form: SendTextMessageToSubscriberForm,
    isEditable: true,
    previewEnabled: true,
    required: ['message'],
    isContentCanBeCopied: true,
  },
  GetHandsetImeiStep: {
    name: 'GetHandsetImeiStep',
    node: FlowNode,
    isEditable: false,
    previewEnabled: false,
    isContentCanBeCopied: false,
    title: 'Get Handset Imei',
  },
  SendTextMessageFromSubscriberStep: {
    name: 'SendTextMessageFromSubscriberStep',
    node: FlowNode,
    form: SendTextMessageFromSubscriberForm,
    isEditable: true,
    previewEnabled: true,
    required: ['message', 'optionalDisplayText'],
    isContentCanBeCopied: true,
  },
  SendUssdStringStep: {
    name: 'SendUssdStringStep',
    node: FlowNode,
    form: SendUssdStringForm,
    isEditable: true,
    previewEnabled: false,
    required: ['ussd'],
    isContentCanBeCopied: false,
  },
  CallbackStep: {
    name: 'CallbackStep',
    node: FlowNode,
    form: null,
    isEditable: false,
    previewEnabled: false,
    isContentCanBeCopied: false,
  },
};

export const ChartLabelDescriptionMap = {
  Ordered: 'Number of subscribers requested in the order.',
  Targetable:
    'An estimated number of subscribers<br/>available for targeting. The actual number of<br/>subscribers available for targeting may be<br/>impacted by Contact Policy rules.',
  Targeted: 'Number of subscribers who were targeted a message.',
  Responses:
    'Number of subscribers<br/>that have sent responses to the<br/>first screen of the user journey.',
  Interest: 'Number of subscribers that have accepted<br/>the first screen of the user journey.',
  Confirmed: 'Number of subscribers that have accepted<br/>the DOI of the user journey.',
  Success: 'Number of subscribers that have successfully<br/>completed a subscription request.',
  'Opted Out': 'Number of subscribers that have chosen to opt out.',
  Transactions:
    'Number of billable CPM transactions. The CPM count may be inaccurate for a few hours after Order execution.',
};

export const ATTRIBUTE_TYPE_SYSTEM = 'System';
export const ATTRIBUTE_NAME_LOGIN_PAGE_MESSAGE = 'loginPageMessage';
export const ATTRIBUTE_NAME_QS_DASHBOARD_ID_PERFORMANCE_REPORT = 'QSDashboardIDPerformanceReport';
export const ATTRIBUTE_NAME_QS_DASHBOARD_ID_BILLING_REPORT_CP = 'QSDashboardIDBillingReportCP';
export const ATTRIBUTE_NAME_QS_DASHBOARD_ID_BILLING_REPORT_MNO = 'QSDashboardIDBillingReportMNO';
export const ATTRIBUTE_NAME_QS_DASHBOARD_ID_BILLING_REPORT_RESELLER =
  'QSDashboardIDBillingReportReseller';
export const ATTRIBUTE_NAME_QS_DASHBOARD_ID_PAYMENT_HISTORY = 'QSDashboardIDPaymentHistory';
export const ATTRIBUTE_NAME_QS_DASHBOARD_ID_PROVISIONED_SUBSCRIBERS_REPORT =
  'QSDashboardIDProvisionedSubscribersReport';
export const ATTRIBUTE_NAME_QS_DASHBOARD_ID_ACCOUNT_BALANCE_REPORT =
  'QSDashboardAccountBalanceReport';
export const BILLING_ADMIN_DATASET_IDS_TO_REFRESH = 'billingAdminDatasetIdsToRefresh';
export const BILLING_ADMIN_DATASET_NAMES_TO_REFRESH = 'billingAdminDatasetNamesToRefresh';

export const PRODUCT_FAMILIES = ['Life Services', 'MediaX', 'BoosterX', 'BrandX', 'Zillions'];
export const PRODUCT_FAMILIES_FOR_ORDERS = ['MediaX', 'BoosterX', 'BrandX'];

export const DSM_SERVICE_LABELS = {
  serviceId: 'Product ID',
  productName: 'Product Name',
  subscriptionId: 'Service ID',
  nodeId: 'Aggregator',
  aggregatorId: 'Aggregator',
};

export const SIXD_SERVICE_LABELS = {
  cpId: 'CP ID',
  contentProvider: 'Content Provider',
  productName: 'Product Name',
  price: 'Price',
  validity: 'Validity',
  subscriptionId: 'Subscription ID',
  nodeId: 'Node ID',
  productId: 'Product ID',
};

export const MAD_SERVICE_LABELS = {
  serviceId: 'Service ID',
  status: 'Status',
};

export const DEP_SERVICE_LABELS = {
  serviceName: 'Service Name',
  billingCycle: 'Billing Cycle',
  billingRate: 'Billing Rate',
  subscriptionId: 'Subscription ID',
  serviceId: 'Service ID',
  partnerId: 'Partner ID',
};

export const CIS_SERVICE_LABELS = {
  serviceName: 'Service Name',
  billingCycle: 'Billing Cycle',
  billingRate: 'Billing Rate',
  subscriptionId: 'Subscription ID',
  serviceId: 'Service ID',
};

export const UTILITY_ACTIONS_MAP = {
  GET_TIME: 'getTime',
  GET_USER_ROLES: 'getUserRoles',
};

export const CALLER_TUNEZ_SERVICE_LABELS = {
  tuneId: 'Tune ID',
};
