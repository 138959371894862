import { Logger } from 'aws-amplify';

const flightdeckFrontend = 'flightdeck_frontend';

class FlightdeckLogger extends Logger {
  constructor(name, level) {
    super(name, level);
    this.user = {};
    this._pluggables = [];
  }

  setUser(user) {
    this.user = user;
  }

  addPluggable(pluggable) {
    if (pluggable) {
      this._pluggables.push(pluggable);
      pluggable.configure(this._config);
    }
  }

  async _logToPluggables(level, component, func, ...messages) {
    messages.unshift({ component, function: func, level, user: this.user });
    const formattedMessages = this.format(messages);
    const logEvent = {
      message: formattedMessages.join(','),
      timestamp: Date.now(),
    };

    for (const plugin of this._pluggables) {
      await plugin.pushLogs([logEvent]);
    }
  }

  async error(componentName, functionName, ...messages) {
    await this._logToPluggables('ERROR', componentName, functionName, ...messages);
  }

  async info(componentName, functionName, ...messages) {
    await this._logToPluggables('INFO', componentName, functionName, ...messages);
  }

  async log(componentName, functionName, ...messages) {
    await this._logToPluggables('LOG', componentName, functionName, ...messages);
  }

  async warn(componentName, functionName, ...messages) {
    await this._logToPluggables('WARN', componentName, functionName, ...messages);
  }

  format(messages) {
    const formattedMessages = messages.map((msg) => {
      if (typeof msg === 'object') {
        try {
          return JSON.stringify(msg, null, 2);
        } catch (e) {
          return msg;
        }
      }
      return msg;
    });

    return formattedMessages;
  }
}

const LOGGER = new FlightdeckLogger(flightdeckFrontend, 'INFO');

export default LOGGER;
