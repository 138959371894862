import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormInputDropdown from './FormInputDropdown';
import FuseLoading from '../../@fuse/core/FuseLoading';
import { getAccounts, selectAccounts } from '../main/billing-admin/store/accountsSlice';
import { getAccountList } from '../services/permissionUtil';

function FormAccountDropdown({ mno, displayOnlyAccountName, additionaFilter, ...props }) {
  const dispatch = useDispatch();
  const { permissions } = useSelector(({ auth }) => auth.user);
  const accounts = useSelector(selectAccounts);
  const [loading, setLoading] = useState(true);
  const [availableAccounts, setAvailableAccounts] = useState([]);

  useEffect(() => {
    dispatch(getAccounts()).then(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    const filterCondition = (account) =>
      account.mno === mno && (!additionaFilter || additionaFilter(account));
    setAvailableAccounts(
      getAccountList(permissions, accounts, mno)
        .filter(filterCondition)
        .sort((acc1, acc2) => acc1.name.localeCompare(acc2.name))
        .map((account) => {
          return {
            value: account.id,
            label:
              account.name === account.mno || displayOnlyAccountName
                ? account.name
                : `${account.name} (${account.mno})`,
          };
        })
    );
  }, [mno, accounts]);

  if (loading) {
    return <FuseLoading />;
  }

  return <FormInputDropdown {...props} options={availableAccounts} />;
}

export default FormAccountDropdown;
