import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { showMessage } from '../../../store/fuse/messageSlice';

const invalidateCacheHeader = { headers: { 'Cache-Control': 'max-age=0' } };

export const getPlatforms = createAsyncThunk(
  'billing/platforms/getPlatforms',
  async (invalidate = false) => {
    const response = await API.get(
      'billing-api',
      'platforms',
      invalidate ? invalidateCacheHeader : {}
    );
    return response.map((code) => Object.fromEntries(Object.entries(code).filter(([k, v]) => !!v)));
  }
);

export const savePlatform = createAsyncThunk(
  'billing/platforms/savePlatform',
  async (platform, { dispatch, getState }) => {
    const state = getState();
    const request = {
      body: platform,
    };
    try {
      const response = await API.put('billing-api', 'platforms', request);
      dispatch(showMessage({ message: 'Platform saved successfully!', variant: 'success' }));
      dispatch(getPlatforms(true));

      return response;
    } catch (err) {
      dispatch(showMessage({ message: 'Platform already exists!', variant: 'error' }));
      return err.response.data;
    }
  }
);

const platformsAdapter = createEntityAdapter({});

export const { selectAll: selectPlatforms, selectById: selectPlatformById } =
  platformsAdapter.getSelectors((state) => state.billing.platforms);

const platformsSlice = createSlice({
  name: 'billing/platforms',
  initialState: platformsAdapter.getInitialState({
    loading: false,
    platformDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openNewPlatformDialog: (state, action) => {
      state.platformDialog = {
        type: 'new',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewPlatformDialog: (state, action) => {
      state.platformDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditPlatformDialog: (state, action) => {
      state.platformDialog = {
        type: 'edit',
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditPlatformDialog: (state, action) => {
      state.platformDialog = {
        type: 'edit',
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [savePlatform.pending]: (state) => {
      state.loading = true;
    },
    [savePlatform.fulfilled]: (state) => {
      state.loading = false;
    },
    [getPlatforms.pending]: (state) => {
      state.loading = true;
    },
    [getPlatforms.fulfilled]: (state, action) => {
      state.loading = false;
      platformsAdapter.setAll(state, action.payload);
    },
  },
});

export const {
  openNewPlatformDialog,
  closeNewPlatformDialog,
  openEditPlatformDialog,
  closeEditPlatformDialog,
} = platformsSlice.actions;

export default platformsSlice.reducer;
