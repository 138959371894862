import { Divider, Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { InfoOutlined } from '@mui/icons-material';
import { isNodeTypeEditable, getNodeTitle } from '../../main/orders/order/orderHelper';

function FlowNodeHeader(props) {
  let title = getNodeTitle(props.stepType);
  if (!title) title = props.id;

  return (
    <Grid item xs={12}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Typography
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '1.5rem',
            fontWeight: 'bold',
          }}
        >
          {title}
        </Typography>
      </div>
      <Divider color="primary" />
    </Grid>
  );
}
export default FlowNodeHeader;
