import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { showMessage } from '../../../store/fuse/messageSlice';

const invalidateCacheHeader = { headers: { 'Cache-Control': 'max-age=0' } };

export const saveContract = createAsyncThunk(
  'billing/contracts/saveContract',
  async (payload, { dispatch }) => {
    const request = {
      body: payload,
    };

    try {
      const response = await API.put('billing-api', 'contracts', request);
      dispatch(showMessage({ message: 'Contract saved successfully!', variant: 'success' }));
      dispatch(getContracts(true));
      return response;
    } catch (err) {
      dispatch(
        showMessage({ message: 'An error occurred while saving the contract.', variant: 'error' })
      );
      return err.response.data;
    }
  }
);

export const getContracts = createAsyncThunk(
  'billing/contracts/getContracts',
  async (invalidate = false) => {
    const response = await API.get(
      'billing-api',
      'contracts',
      invalidate ? invalidateCacheHeader : {}
    );
    return response;
  }
);

const contractsAdapter = createEntityAdapter({});

export const { selectAll: selectContracts, selectById: selectContractById } =
  contractsAdapter.getSelectors((state) => state.billing.contracts);

const contractsSlice = createSlice({
  name: 'billing/contracts',
  initialState: contractsAdapter.getInitialState({
    loading: true,
    contractDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openNewContractDialog: (state, action) => {
      const { type: actionType, data: actionData } = action.payload;
      state.contractDialog = {
        type: actionType,
        props: {
          open: true,
        },
        data: actionData,
      };
    },
    closeNewContractDialog: (state) => {
      state.contractDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [getContracts.pending]: (state, action) => {
      state.loading = true;
    },
    [getContracts.fulfilled]: (state, action) => {
      state.loading = false;
      contractsAdapter.setAll(state, action.payload);
    },
    [getContracts.rejected]: (state, action) => {
      state.loading = false;
    },
    [saveContract.pending]: (state) => {
      state.loading = true;
    },
    [saveContract.fulfilled]: (state) => {
      state.loading = false;
    },
    [saveContract.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { openNewContractDialog, closeNewContractDialog } = contractsSlice.actions;
export default contractsSlice.reducer;
