import { Button, DialogActions, DialogContent, Grid, InputAdornment, Tooltip } from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import _ from '@lodash';
import Typography from '@mui/material/Typography';
import { API } from 'aws-amplify';
import useDebouncedValue from '@fuse/hooks/useDebouncedValue';
import { JourneyStepTypeMap } from 'app/services/constants';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import PreviewDisplay from '../../../../../shared-components/preview/PreviewDisplay';
import * as queries from '../../../../../../graphql/queries';

import { convertPeriodToDaysOrMonths } from './formHelper';

function DsmDoiForm(props) {
  const menuItemList = props?.data?.data?.menuItemList;
  const defaultValues = {
    ...props.data.data,
    menuItemList: menuItemList.map((item) => ({ item })),
  };

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    productId: yup.string().required('Product ID is required'),
    menuTitle: yup.string().required('Text is required'),
    menuItemList: yup
      .array()
      .of(
        yup.object().shape({
          item: yup.string().required('Text is required'),
        })
      )
      .required()
      .min(1, 'At least one select option is required'),
  });

  const dispatch = useDispatch();
  const { control, handleSubmit, formState, register, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'menuItemList',
  });

  const { isValid, dirtyFields, errors } = formState;
  const menuTitle = watch('menuTitle');
  const menuItems = watch('menuItemList');
  const productId = useDebouncedValue(watch('productId'), 500);
  const [isValidating, setValidating] = useState(false);
  const defaultProductInfo = {
    verified: !!defaultValues.productId,
    description: null,
    data: null,
  };
  const [productInfo, setProductInfo] = useState(defaultProductInfo);
  const [isQueryExecuted, setQueryExecuted] = useState(false);

  useEffect(() => {
    if (isQueryExecuted) setQueryExecuted(false);
  }, [productId]);

  function handleQuery() {
    setValidating(true);
    setProductInfo(defaultProductInfo);
    setValue('menuTitle', '');
    setValue('menuItemList', []);

    fetchDsmProductInfo()
      .then((result) => {
        setProductInfo(result);
        if (result.verified) {
          setValue('menuTitle', getDynamicMenuTitle(result.data));
          setValue('menuItemList', getDynamicMenuItems(result.data));
        }
      })
      .finally(() => {
        setValidating(false);
        setQueryExecuted(true);
      });
  }

  function getDynamicMenuTitle(data) {
    if (data.freeTrial === 'ENABLED') {
      return `Thank you for your request for ${data.productName} at Naira ${
        data.renewalPrice
      }/${convertPeriodToDaysOrMonths(
        data.renewalValidity,
        data.validityType,
        false
      )} with auto renewal. You'll get ${convertPeriodToDaysOrMonths(
        data.validityPeriod,
        data.validityType
      )} for FREE. Please reply:`;
    }

    if (data.renewalPrice === '0' && data.validityPeriod === '0') {
      return `Thank you for your request for ${data.productName} at Naira ${data.subscriptionPrice} charged one time only. Please reply:`;
    }

    if (data.renewalPrice === '0' && data.validityPeriod !== '0') {
      return `Thank you for your request for ${data.productName} at Naira ${
        data.subscriptionPrice
      } for ${convertPeriodToDaysOrMonths(data.renewalValidity, data.validityType)}. Please reply:`;
    }

    if (data.renewalPrice === data.subscriptionPrice) {
      return `Thank you for your request for ${data.productName} at Naira ${
        data.renewalPrice
      }/${convertPeriodToDaysOrMonths(
        data.renewalValidity,
        data.validityType,
        false
      )}. Please reply:`;
    }

    return '';
  }

  function getDynamicMenuItems(data) {
    const items = [];
    if (data.freeTrial === 'ENABLED') {
      items.push({ item: 'Accept' });
    } else {
      items.push({ item: 'Accept with Auto-renewal' });
      items.push({ item: 'Accept One-off' });
    }
    items.push({ item: 'Cancel' });
    return items;
  }

  function isDoiContentGenerated() {
    return !!menuTitle;
  }

  const fetchDsmProductInfo = async function () {
    try {
      const response = await API.graphql({
        query: queries.validateAPI,
        variables: {
          api: 'DsmServiceCall',
          serviceId: productId,
        },
      });
      const result = response.data.validateAPI;
      return {
        verified: result.statusCode === 200 && !!result.data,
        description: result.description,
        data: result.data ? JSON.parse(result.data) : null,
      };
    } catch (error) {
      console.log(error);
      return { verified: false, description: 'Unable to validate service!', data: null };
    }
  };

  function handleSave(data) {
    const dsmServiceCallStep = props.data.data.parent.find(
      (step) => step.type === JourneyStepTypeMap.ServiceCallStep.name
    );
    if (dsmServiceCallStep) {
      props.onSubmit({
        ...dsmServiceCallStep,
        data: {
          ...dsmServiceCallStep.data,
          variables: {
            serviceId: productId,
            productName: productInfo.data.productName,
            subscriptionId: productInfo.data.serviceId,
            nodeId: productInfo.data.aggregatorName,
          },
        },
      });
    } else {
      console.error('DsmServiceCall step not found in the flow!');
    }

    props.onSubmit({
      ...props.data,
      data: {
        ...data,
        menuItemList: data.menuItemList.map((item) => {
          return item.item;
        }),
      },
    });

    dispatch(closeDialog());
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit(handleSave)}
      className="flex flex-col md:overflow-hidden"
    >
      <DialogContent className="sm:min-w-512">
        <div className="pt-0">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2} rowSpacing={3}>
                <Grid container item xs={12} spacing={1} alignItems="center">
                  <Grid item xs={10}>
                    <Controller
                      control={control}
                      name="productId"
                      render={({ field }) => (
                        <Tooltip
                          title={
                            <Typography component="div" sx={{ fontSize: '11px' }}>
                              {isValidating && (
                                <div key="validationMsg">Querying data, please wait...</div>
                              )}
                              {!isValidating &&
                                !productInfo.verified &&
                                productInfo.description && (
                                  <div key="error">{productInfo.description}</div>
                                )}
                              {!isValidating && productInfo.data && (
                                <>
                                  <div key="productName">
                                    Product Name: {productInfo.data.productName}
                                  </div>
                                  <div key="aggregatorName">
                                    Aggregator Name: {productInfo.data.aggregatorName}
                                  </div>
                                  <div key="serviceId">
                                    Service ID: {productInfo.data.serviceId}
                                  </div>
                                </>
                              )}
                            </Typography>
                          }
                          placement={isValidating ? 'top-start' : 'top-end'}
                          open={isValidating || !!productInfo.description || !!productInfo.data}
                          style={{ maxWidth: '500px', marginTop: '1.5rem' }}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: isValidating || !productInfo.data ? 'red' : 'green',
                                '& .MuiTooltip-arrow': {
                                  color: isValidating || !productInfo.data ? 'red' : 'green',
                                },
                              },
                            },
                          }}
                        >
                          <TextField
                            {...field}
                            label={props.data.data?.placeholder?.productId || 'Product ID'}
                            id="productId"
                            type="text"
                            error={!!errors.productId}
                            helperText={
                              errors?.productId?.message ||
                              (menuTitle
                                ? 'DOI content is generated based on the selected product'
                                : "Enter Product ID and hit 'Query' button to generate DOI content")
                            }
                            variant="outlined"
                            fullWidth
                            required
                            size="small"
                          />
                        </Tooltip>
                      )}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="outlined"
                      onClick={handleQuery}
                      size="medium"
                      className="font-bold px-20"
                      disabled={!productId || isValidating}
                    >
                      Query
                    </Button>
                  </Grid>
                </Grid>

                {!isValidating &&
                  productInfo.verified &&
                  (isDoiContentGenerated() ? (
                    <Grid container item spacing={2}>
                      <Grid item xs={12}>
                        <span className="font-bold text-md">Generated Content</span>
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name="menuTitle"
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={defaultValues.placeholder?.menuTitle || 'Menu Title'}
                              id="menuTitle"
                              error={!!errors.menuTitle}
                              helperText={errors?.menuTitle?.message}
                              variant="outlined"
                              multiline
                              fullWidth
                              required
                              disabled
                            />
                          )}
                        />
                      </Grid>

                      {fields.map((item, index) => (
                        <Grid key={index} container item className="pt-20 align-middle" spacing={1}>
                          <Grid item xs={12}>
                            <TextField
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">{index + 1}</InputAdornment>
                                ),
                              }}
                              label="Option"
                              disabled
                              control={control}
                              variant="outlined"
                              error={!!errors?.menuItemList?.[index]?.item}
                              helperText={errors?.menuItemList?.[index]?.item?.message}
                              {...register(`menuItemList[${index}].item`)}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <span className="text-md text-red-500">
                        Sorry, but the DOI content cannot be created for your Product ID. Please
                        reach out to MediaX team for support.
                      </span>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
            {!isValidating && productInfo.verified && isDoiContentGenerated() && (
              <Grid item xs={12} md={4}>
                <PreviewDisplay menuTitle={menuTitle} menuItems={menuItems} />
              </Grid>
            )}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Grid item xs={12} md={8}>
          <Button variant="outlined" onClick={() => dispatch(closeDialog())} color="primary">
            Cancel
          </Button>
          {productInfo.verified && (
            <Button
              variant="outlined"
              disabled={
                _.isEmpty(dirtyFields) ||
                !isValid ||
                !productInfo.verified ||
                !isDoiContentGenerated() ||
                !isQueryExecuted
              }
              type="submit"
              color="primary"
            >
              Save
            </Button>
          )}
        </Grid>
      </DialogActions>
    </form>
  );
}

export default DsmDoiForm;
