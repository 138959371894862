import { isNode, MarkerType, Position } from 'reactflow';
import dagre from 'dagre';
import _ from '@lodash';
import {
  Constants,
  getOrderStatusByName,
  JourneyResultMap,
  JourneyStepTypeMap,
  OrderStatus,
} from 'app/services/constants';
import { hasUserSupportRole } from 'app/services/permissionUtil';
import { HANDSET_OPTIONS_ALL } from 'app/shared-components/FormHandsetDropdown';
import moment from 'moment-timezone';
import { addTimeToDate } from 'app/services/dateUtil';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import { getS3FileSize } from '../store/orderSlice';

const logger = new ComponentLogger('orderHelper');

export const isEditable = (element) => {
  return !element.data?.readOnly && JourneyStepTypeMap[element.type]
    ? JourneyStepTypeMap[element.type].isEditable
    : false;
};

export function mergeSameDestinationTransitions(data) {
  const seen = {};
  return data.filter((entry) => {
    let previous;
    const { condition, task, responseText, ...rest } = entry;
    if (seen[JSON.stringify(rest)]) {
      previous = seen[JSON.stringify(rest)];
      const label = responseText ? responseText.toUpperCase() : condition;
      if (!previous.condition.includes(label))
        previous.condition = `${previous.condition} | ${label}`;
      // merge it into the previous one and discard
      return false;
    }
    entry.condition = responseText ? responseText.toUpperCase() : condition;
    seen[JSON.stringify(rest)] = entry;
    return true;
  });
}

function getElements(steps, transitions, isSupportRole) {
  const backTransition = findCycleAndItsBackStep(transitions, steps);
  const stepMap = Array.from(Object.entries(steps)).map(([key, value]) => ({
    id: key,
    position: { x: 0, y: 0 },
    data: { ...value, id: key },
    hidden: !isSupportRole && !!value.hidden,
    type: getNodeTypeByStepType(value.stepType),
    ...(backTransition &&
      (backTransition.originatingStep === key || backTransition.destinationStep === key) && {
        backNode: true,
      }),
  }));
  const transitionMap = mergeSameDestinationTransitions(_.cloneDeep(transitions)).map(
    (transition) => {
      return {
        id: `${transition.originatingStep}-${transition.destinationStep}-${transition.condition}-${transition.responseText}`,
        source: transition.originatingStep,
        target: transition.destinationStep,
        label: shortenLabels(transition.condition, isSupportRole),
        labelStyle: { fontSize: '1rem' },
        position: { x: 0, y: 0 },
        hidden: !isSupportRole && !!transition.hidden,
        type: 'smart',
        style: getEdgeStyle(transition.condition),
        animated: true,
        markerEnd: {
          type: MarkerType.Arrow,
          color: getEdgeColor(transition.condition),
        },
        ...(backTransition &&
          backTransition.originatingStep === transition.originatingStep &&
          backTransition.destinationStep === transition.destinationStep && { backEdge: true }),
      };
    }
  );
  return [...stepMap, ...transitionMap];
}

function getKeyValueList(tagMap) {
  if (tagMap) {
    return Object.entries(JSON.parse(tagMap)).map(([key, value]) => ({
      name: key,
      value,
    }));
  }
  return [];
}

export function setValueAtPath(obj, path, value) {
  const keys = path.split('.');
  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc[key] = value;
    }
    return acc[key] || (acc[key] = {});
  }, obj);
}

export function updateStepsWithVariables(steps, variables) {
  if (variables) {
    variables.forEach(({ path, value }) => {
      setValueAtPath(steps, path, value);
    });
  }
  return steps;
}

export function getStepsFromJourneyTemplate(template, isSupportRole) {
  if (template && template.journey) {
    const { journeyID, ...restTemplate } = template;
    const { id, ...restJourney } = restTemplate.journey;
    const layoutItems = getLayoutedElements(
      getElements(
        updateStepsWithVariables(JSON.parse(restJourney.steps), template.variables),
        restJourney.transitions,
        isSupportRole
      )
    );
    const steps = [...layoutItems.nodeList, ...layoutItems.edgeList];

    const tags = getKeyValueList(restJourney.tags);
    return { ...restTemplate, journey: { ...restJourney, steps, tags } };
  }
  return null;
}

export function isFieldRequired(stepType, field) {
  return JourneyStepTypeMap[stepType]
    ? JourneyStepTypeMap[stepType].required && JourneyStepTypeMap[stepType].required.includes(field)
    : false;
}

export function getRequiredFields(stepType) {
  return JourneyStepTypeMap[stepType] ? JourneyStepTypeMap[stepType].required : null;
}

export function getJourneyFromJourneyDefinition(definition, isSupportRole, template = null) {
  if (definition) {
    const definitionSteps = JSON.parse(definition.steps);

    const layoutItems = getLayoutedElements(
      getElements(
        template ? getStepsWithInfoFromTemplate(definitionSteps, template) : definitionSteps,
        definition.transitions,
        isSupportRole
      )
    );
    const steps = [...layoutItems.nodeList, ...layoutItems.edgeList];

    const tags = getKeyValueList(definition.tags);
    const res = {
      ...definition,
      steps,
      tags,
    };
    return res;
  }
  return null;
}

function getStepsWithInfoFromTemplate(steps, template) {
  const templateSteps = JSON.parse(template.journey.steps);
  Object.entries(steps).forEach(([id, step]) => {
    const templateStep = templateSteps[id];
    if (templateStep && templateStep.info) step.info = templateStep.info;
  });
  return steps;
}

export function cloneOrder(defaultValues, order, role, userSettings) {
  const { cloneDeep, assign, pick, keys } = _;
  const cloned = cloneDeep(defaultValues);
  assign(cloned.definition, pick(order.definition, keys(cloned.definition)));

  const cloneableKeys = keys(cloned).filter((key) => key !== 'definition' && key !== 'status');
  assign(cloned, pick(order, cloneableKeys));

  cloned.excludedDays = updateExcludedDays(cloned.excludedDays);

  if (isStartDateInvalid(cloned.validFrom))
    [cloned.validFrom, cloned.validTo] = getShiftedDates(cloned.validFrom, cloned.validTo);

  if (!cloned.handsetsToTarget && userSettings?.deviceTypeTargeting)
    cloned.handsetsToTarget = HANDSET_OPTIONS_ALL;

  cloned.definition = getJourneyFromJourneyDefinition(cloned.definition, hasUserSupportRole(role));
  return cloned;
}

function updateExcludedDays(excludedDays) {
  return !excludedDays || !excludedDays.length || !excludedDays.includes(7)
    ? [...(excludedDays || []), 7]
    : excludedDays;
}

export function isStartDateInvalid(startDateStr) {
  return Date.parse(startDateStr, Constants.DEFAULT_DATE_FORMAT) < getBeginningOfToday();
}

export function getBeginningOfToday() {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
}

export function getShiftedDates(startDateStr, endDateStr) {
  const startDate = Date.parse(startDateStr, Constants.DEFAULT_DATE_FORMAT);
  const endDate = Date.parse(endDateStr, Constants.DEFAULT_DATE_FORMAT);
  return [formatDate(new Date()), formatDate(new Date(Date.now() + endDate - startDate))];
}

export function formatDate(date) {
  return date.toISOString().slice(0, 10);
}

export function getOrderStatus(journeyOrder) {
  if (journeyOrder.status) {
    return getOrderStatusByName(journeyOrder.status);
  }
  return OrderStatus.SCHEDULED;
}

const STEP_WIDTH = 250;
const MIN_STEP_HEIGHT = 250;
const MAX_STEP_HEIGHT = 700;
const NEW_LINE_HEIGHT = 20;

function getStepContentHeight(el) {
  const data = el.data || {};
  let height = MIN_STEP_HEIGHT;
  if (data.optionalDisplayText) {
    height += data.optionalDisplayText.split('\n').length * NEW_LINE_HEIGHT;
  }
  if (data.text) {
    height += data.text.split('\n').length * NEW_LINE_HEIGHT;
  } else if (data.message) {
    height += data.message.split('\n').length * NEW_LINE_HEIGHT;
  }
  return Math.min(MAX_STEP_HEIGHT, height);
}

export function getLayoutedElements(elements) {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));
  dagreGraph.setGraph({ rankdir: 'TB' });

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, {
        width: STEP_WIDTH,
        height: getStepContentHeight(el),
      });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  const result = elements.map((el) => {
    if (isNode(el)) {
      const { width, height, x, y } = dagreGraph.node(el.id);
      el.targetPosition = Position.Top;
      el.sourcePosition = Position.Bottom;
      el.position = {
        x: x - width / 2,
        y: y - height / 2,
      };
    }
    return el;
  });
  const [nodeList, edgeList] = splitList(result);
  return { nodeList, edgeList };
}

function splitList(elements) {
  return elements.reduce(
    ([nodeList, otherList], el) =>
      isNode(el) ? [[...nodeList, el], otherList] : [nodeList, [...otherList, el]],
    [[], []]
  );
}

export function getNodeTypeByStepType(stepType) {
  return JourneyStepTypeMap[stepType]
    ? JourneyStepTypeMap[stepType].name
    : JourneyStepTypeMap.DisplayTextStep.name;
}
export function isNodeTypeEditable(stepType) {
  return JourneyStepTypeMap[stepType] ? JourneyStepTypeMap[stepType].isEditable : false;
}

export function getNodeTitle(stepType) {
  return JourneyStepTypeMap[stepType] ? JourneyStepTypeMap[stepType].title : null;
}

export function getStepComponents(type) {
  return Object.fromEntries(
    Object.entries(JourneyStepTypeMap).map(([key, value]) => [[key], value[type]])
  );
}

export function shortenLabels(data, isSupportRole = false) {
  const items = data.split(' | ');
  const processedItems = items.map((item) => {
    Object.entries(JourneyResultMap).forEach(([key, value]) => {
      if (item.includes(key) && !isSupportRole) {
        item = value.name;
      } else {
        item = item.replace(key, value.name);
      }
    });
    return item;
  });
  return Array.from(new Set(processedItems)).join(' | ');
}

export function getEdgeColor(data) {
  for (const key of Object.keys(JourneyResultMap)) {
    if (data.includes(key)) {
      return JourneyResultMap[key].color;
    }
  }
  return null;
}

export function getEdgeStyle(data) {
  const edgeColor = getEdgeColor(data);
  return edgeColor
    ? { stroke: getEdgeColor(data), strokeWidth: 2 }
    : { stroke: 'blue', strokeWidth: 2 };
}

export function getDecimalScale(MIN_VAL) {
  return 2;
}

function findCycleAndItsBackStep(transitions, steps) {
  // Helper function to perform DFS and find a cycle
  function dfs(current, visited, recStack, path) {
    if (recStack.has(current)) {
      return { cyclePath: [...path, current], found: true };
    }
    if (visited.has(current)) {
      return { cyclePath: [], found: false };
    }

    visited.add(current);
    recStack.add(current);
    path.push(current);

    for (const neighbour of graph[current] || []) {
      const { cyclePath, found } = dfs(
        neighbour,
        new Set(visited),
        new Set(recStack),
        Array.from(path)
      );
      if (found) {
        return { cyclePath, found };
      }
    }

    recStack.delete(current);
    return { cyclePath: [], found: false };
  }

  // Constructing a graph from the transitions
  const graph = {};
  transitions.forEach((transition) => {
    if (!graph[transition.originatingStep]) {
      graph[transition.originatingStep] = [];
    }
    graph[transition.originatingStep].push(transition.destinationStep);
  });

  // Find the start step
  const startStep = Object.keys(steps).find((step) => steps[step].startStep);

  // Detect cycle
  for (const node of Object.keys(graph)) {
    const visited = new Set();
    const recStack = new Set();
    const { cyclePath, found } = dfs(node, visited, recStack, []);

    if (found) {
      const loopStart = cyclePath[cyclePath.length - 1];
      const backTransition = transitions.find(
        (transition) =>
          transition.destinationStep === loopStart &&
          transition.originatingStep === cyclePath[cyclePath.length - 2]
      );

      return backTransition; // Return the back transition record
    }
  }

  return null;
}

export const noCurlyBracesPattern = /^[^{}]*$/;

export function getOptionalDisplayTextSchema(defaultValues) {
  return (val) => {
    if (defaultValues.optionalDisplayText !== null) {
      return isGSMAlphabet(val)
        ? yup
            .string()
            .required('You must enter a text')
            .max(232, 'Must be less than 232 character')
            .matches(noCurlyBracesPattern, 'Text should not include curly braces')
        : yup
            .string()
            .required('You must enter a text')
            .max(116, 'Must be less than 116 character')
            .matches(noCurlyBracesPattern, 'Text should not include curly braces');
    }
    return val !== null
      ? isGSMAlphabet(val)
        ? yup
            .string()
            .notRequired()
            .nullable()
            .max(232, 'Must be less than 232 character')
            .matches(noCurlyBracesPattern, 'Text should not include curly braces')
        : yup
            .string()
            .notRequired()
            .nullable()
            .max(116, 'Must be less than 116 character')
            .matches(noCurlyBracesPattern, 'Text should not include curly braces')
      : yup.object().notRequired().nullable();
  };
}

export function convertPeriodToDaysOrMonths(period, type, displayOne = true) {
  let days;
  period = parseInt(period, 10);
  switch (type) {
    case 'HH':
      days = period / 24;
      break;
    case 'DD':
      days = period;
      break;
    case 'MM':
      return getUnitText(period, displayOne, 'Month');
    case 'YY':
      days = period * 365; // Approximating to 365 days per year
      break;
    default:
      return 'Invalid type';
  }

  // For days, decide whether to return days directly or convert to months based on the length

  if (days === 7) return getUnitText(1, displayOne, 'Week');
  if (days === 14) return getUnitText(1, false, 'Two Weeks');
  if (days < 30) return getUnitText(days, displayOne, 'Day');

  // Assuming an average of 30 days per month for conversion
  const months = Math.round(days / 30);
  return getUnitText(months, displayOne, 'Month');
}

function getUnitText(number, displayOne, baseText) {
  if (number === 1) {
    // If number is 1 and displayOne is true, return "1 [baseText]"
    // If displayOne is false, return just the "[baseText]" without the number
    return displayOne ? `1 ${baseText}` : baseText;
  }
  // For numbers greater than 1, always return "[number] [baseText]s" (plural)
  return `${number} ${baseText}s`;
}

export async function estimateFileUploadMsisdnCount(filePaths) {
  if (filePaths?.length) {
    const promises = [];
    filePaths.map((path) =>
      promises.push(getS3FileSize(path.substring(path.lastIndexOf('/') + 1)))
    );

    try {
      const fileSizes = await Promise.all(promises);
      return Math.floor(
        fileSizes.reduce((total, fileSize) => {
          return total + fileSize / Constants.FILE_UPLOAD_LINE_BYTES;
        }, 0)
      );
    } catch (error) {
      logger.error('estimateFileUploadMsisdnCount', { error });
    }
  }
  return 0;
}

export const isStartTimeValid = (value, { validFrom, serverTime, timeZoneDifference }) => {
  const validFromDate = new Date(validFrom);
  const combinedDateTime = addTimeToDate(validFromDate, value);
  const serverDate = new Date(serverTime);
  const serverHours = serverDate.getUTCHours();
  const serverMinutes = serverDate.getUTCMinutes();
  const serverDateTime = moment().hours(serverHours).minutes(serverMinutes).toDate();
  const adjustedServerTime = moment(serverDateTime).add(timeZoneDifference, 'hours').toDate();
  return combinedDateTime > adjustedServerTime;
};
