import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import DOMPurify from 'dompurify';

export function isContentValidForPreview(content) {
  return content.split('\n').length - 1 <= 19;
}

export function convertUnicodeToEmoji(str) {
  return str.replace(/\\u(\w{4})/g, (match, capture) =>
    String.fromCodePoint(parseInt(capture, 16))
  );
}

export function convertLineStringToParagraph(str, fontSize, color) {
  // Split the input string into separate lines
  const lines = str.split(/\r?\n/);
  const typographyTags = lines.map((line) => {
    return `<p style="font-size:${fontSize}; color:${color};">${line}</>`;
  });

  // Join the Typography tags with <br/> tags in between
  return DOMPurify.sanitize(typographyTags.join('<br/>'));
}

export function InvalidPreviewContent(props) {
  return (
    <>
      {props.content && !isContentValidForPreview(props.content) && (
        <Typography variant="body1" justifyContent="center" align="center">
          Preview cannot display the content.
        </Typography>
      )}
    </>
  );
}

export const ChatContainer = styled('div')({
  fontFamily: 'Helvetica',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
});

export const Chat = styled('div')({
  background: 'white',
  width: '306px',
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
});

export const Messages = styled('div')({
  marginTop: '30px',
  display: 'flex',
  flexDirection: 'column',
});

export const Message = styled('div')({
  borderRadius: '20px',
  padding: '8px 15px',
  marginTop: '5px',
  marginBottom: '5px',
  maxWidth: '206px',
  wordWrap: 'break-word',
});

export const Yours = styled('div')({
  alignItems: 'flex-start',
});

export const YoursMessage = styled(Message)({
  marginRight: '25%',
  backgroundColor: '#eee',
  position: 'relative',
  textAlign: 'left',
});

export const YoursLastMessageBefore = styled('div')({
  content: '""',
  position: 'absolute',
  zIndex: '0',
  bottom: '0',
  left: '-7px',
  height: '20px',
  width: '20px',
  background: '#eee',
  borderBottomRightRadius: '15px',
});

export const YoursLastMessageAfter = styled('div')({
  content: '""',
  position: 'absolute',
  zIndex: '1',
  bottom: '0',
  left: '-10px',
  width: '10px',
  height: '20px',
  background: 'white',
  borderBottomRightRadius: '10px',
});

export const Mine = styled('div')({
  alignItems: 'flex-end',
});

export const MineMessage = styled(Message)({
  color: 'white',
  marginLeft: '25%',
  background: '#0B93F6',
  backgroundAttachment: 'fixed',
  position: 'relative',
});
export const MineMessageSamsung = styled(Message)({
  color: 'white',
  marginLeft: '25%',
  background: '#029f92',
  backgroundAttachment: 'fixed',
  position: 'relative',
});

export const MineLastMessageBefore = styled('div')({
  content: '""',
  position: 'absolute',
  zIndex: '0',
  bottom: '0',
  right: '-8px',
  height: '20px',
  width: '20px',
  background: '#0B93F6',
  backgroundAttachment: 'fixed',
  borderBottomLeftRadius: '15px',
});

export const MineLastMessageAfter = styled('div')({
  content: '""',
  position: 'absolute',
  zIndex: '1',
  bottom: '0',
  right: '-10px',
  width: '10px',
  height: '20px',
  background: 'white',
  borderBottomLeftRadius: '10px',
});
