import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import { showMessage } from '../../../store/fuse/messageSlice';

const logger = new ComponentLogger('ReportsSlice');

export const getMnos = createAsyncThunk('reports/getMnos', async (variables, { dispatch }) => {
  try {
    return API.get('billing-api', 'mno');
  } catch (err) {
    dispatch(
      showMessage({ message: 'An error occurred while fetching the MNO records', variant: 'error' })
    );
    return [];
  }
});

export const getProductFamilies = createAsyncThunk(
  'reports/getProductFamilies',
  async (variables, { dispatch }) => {
    try {
      return API.get('billing-api', 'product_family');
    } catch (err) {
      dispatch(
        showMessage({
          message: 'An error occurred while fetching the Product Family records',
          variant: 'error',
        })
      );
      return [];
    }
  }
);

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    loadingMnos: false,
    loadingProductFamilies: false,
    mnos: [],
    productFamilies: [],
  },
  reducers: {},
  extraReducers: {
    [getMnos.pending]: (state) => {
      state.loadingMnos = true;
    },
    [getMnos.fulfilled]: (state, action) => {
      state.loadingMnos = false;
      state.mnos = action.payload;
    },
    [getMnos.rejected]: (state, action) => {
      logger.error('getMnos', { error: action.error });
      state.loadingMnos = false;
    },
    [getProductFamilies.pending]: (state) => {
      state.loadingProductFamilies = true;
    },
    [getProductFamilies.fulfilled]: (state, action) => {
      state.loadingProductFamilies = false;
      state.productFamilies = action.payload;
    },
    [getProductFamilies.rejected]: (state, action) => {
      logger.error('getProductFamilies', { error: action.error });
      // Backup until https://onepinjira.atlassian.net/browse/BILL-86 is deployed. Can be removed later.
      state.productFamilies = [
        { id: 1, productFamilyName: 'Life Services' },
        { id: 2, productFamilyName: 'MediaX' },
        { id: 3, productFamilyName: 'BoosterX' },
        { id: 4, productFamilyName: 'BrandX' },
        { id: 5, productFamilyName: 'Zillions' },
      ];
      state.loadingProductFamilies = false;
    },
  },
});

export const {} = reportsSlice.actions;

export default reportsSlice.reducer;
