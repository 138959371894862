import DsmApiCallForm from './DsmApiCallForm';
import SixDServiceAPICallForm from './SixDServiceAPICallForm';
import MadApiCallForm from './MadApiCallForm';
import CallerTunezApiCallForm from './CallerTunezApiCallForm';
import DepServiceCallForm from './DepServiceCallForm';
import CisServiceCallForm from './CisServiceCallForm';

function ServiceAPICallForm(props) {

  if (props.data.data.name.toLowerCase().startsWith('dep')) {
    return <DepServiceCallForm key='dep-service-call' {...props} />
  }

  if (props.data.data.name === 'CisServiceCall') {
    return <CisServiceCallForm key="cis-service-call" {...props} />;
  }

  if (props.data.data.name === 'DsmServiceCall') {
    return <DsmApiCallForm key="dsm-service-call" {...props} />;
  }

  if (props.data.data.name === '6DServiceCall') {
    return <SixDServiceAPICallForm key="sixd-service-call" {...props} />;
  }

  if (props.data.data.name === 'MadServiceCall') {
    return <MadApiCallForm key="mad-service-call" {...props} />;
  }

  if (props.data.data.name === 'CallerTunezCall') {
    return <CallerTunezApiCallForm key="caller-tunez-call" {...props} />;
  }
}

export default ServiceAPICallForm;
