import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';

function Maintenance() {
  return (
    <div className="flex flex-col flex-1 items-center justify-center p-16">
      <div className="w-full max-w-3xl text-center">
        <motion.div
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
        >
          <Typography variant="h6" color="text.secondary" className="mt-8 text-center">
            We are currently making improvements. Please try again momentarily. Thanks for your
            patience.
          </Typography>
        </motion.div>
      </div>
      <motion.div
        initial={{ opacity: 0, scale: 0.6 }}
        animate={{ opacity: 1, scale: 0.8, transition: { delay: 0.1 } }}
      >
        <img width="100%" height="100%" src="assets/images/logos/maintenance_logo.png" alt="logo" />
      </motion.div>

      <div className="flex flex-col items-center justify-center py-16">
        <a onClick={() => window.location.reload()} style={{ cursor: 'pointer' }}>
          Try again
        </a>
      </div>
    </div>
  );
}

export default Maintenance;
