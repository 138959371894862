import React from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from 'app/store/fuse/dialogSlice';
import TextViewDialog from './TextViewDialog';

function formatJson(value) {
  if (!value) return '';

  try {
    const parsed = JSON.parse(value);
    return JSON.stringify(parsed, null, 2);
  } catch (e) {
    return value;
  }
}

function getFirstLines(str) {
  let lines = str.split('\n');
  if(lines.length > 3) {
    lines = lines.slice(0, 3);
  }
  return lines.join('\n');
}

const GridBigDataColumnRenderer = ({ value, title }) => {
  value = value || '';

  const data = formatJson(value);
  const dispatch = useDispatch();

  const style = {
    whiteSpace: 'pre-wrap',
    fontFamily: 'monospace',
    fontSize: '9px',
    color: '#333',
    cursor: 'pointer',
  };

  return (
    <div>
      <span style={style} onClick={() => {
        dispatch(
          openDialog({
            children: <TextViewDialog data={data} title={title} />,
          })
        );
      }}>
        {getFirstLines(value.substring(0, 100))}{value ? '...' : ''}
      </span>
    </div>
  );
};


export default GridBigDataColumnRenderer;
