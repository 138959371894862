import { combineReducers } from '@reduxjs/toolkit';
import platforms from './platformsSlice';
import accounts from './accountsSlice';
import codes from './codesSlice';
import prices from './pricesSlice';
import corrections from './correctionsSlice';
import contracts from './contractsSlice';
import datasetIngestions from './datasetIngestionsSlice';

const reducer = combineReducers({
  platforms,
  accounts,
  codes,
  prices,
  corrections,
  contracts,
  datasetIngestions,
});

export default reducer;
