import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from 'react';
import _ from '@lodash';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { getOptionalDisplayTextSchema, isGSMAlphabet } from './formHelper';
import PreviewDisplay from '../../../../../shared-components/preview/PreviewDisplay';

const MAX_ITEM_SIZE = 25;
const MAX_TITLE_SIZE = 100;
const BASE_COUNT = 15;
const MAX_MESSAGE_SIZE = 250;
const UNICODE_MULTIPLIER = 2;
const SELECT_ITEM_MULTIPLIER = 4;

function totalMessageSizeCheck() {
  return function (value) {
    const { from } = this;
    const { menuItemList, menuTitle } = this.path === 'menuTitle' ? from[0].value : from[1].value;

    const itemString = menuItemList.map((item) => item.item).join('');
    const isGSMA = isGSMAlphabet(menuTitle) && isGSMAlphabet(itemString);

    const totalCount = isGSMA
      ? BASE_COUNT +
        menuTitle.length +
        itemString.length +
        menuItemList.length * SELECT_ITEM_MULTIPLIER
      : BASE_COUNT +
        UNICODE_MULTIPLIER * menuTitle.length +
        UNICODE_MULTIPLIER * itemString.length +
        menuItemList.length * SELECT_ITEM_MULTIPLIER;

    if (isGSMA && totalCount > MAX_MESSAGE_SIZE) {
      return this.createError({
        message: 'Select From Menu text is too long.',
        path: this.path,
      });
    }
    if (!isGSMA && totalCount > MAX_MESSAGE_SIZE) {
      return this.createError({
        message:
          'Select From Menu text is too long. Unicode characters have been detected, try substituting them with regular characters.',
        path: this.path,
      });
    }
    return true;
  };
}

function SelectFromMenuForm(props) {
  const menuItemList = props?.data?.data?.menuItemList;
  const menuItemIdList = props?.data?.data?.menuItemIdList;
  const readOnly = props?.data?.data?.readOnly;
  const defaultValues = {
    ...props.data.data,
    ...(props.data.data.menuItemIdList && {
      menuItemIdList: props.data.data.menuItemIdList.map((id) => ({ id })),
    }),
    menuItemList: menuItemList.map((item) => ({ item })),
  };
  const optionalDisplayTextSchema = getOptionalDisplayTextSchema(defaultValues);
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    menuTitle: yup
      .string()
      .required('Text is required')
      .max(MAX_TITLE_SIZE, 'Must be less than 100 character')
      .test('global-ok', 'The data is not correct', totalMessageSizeCheck()),
    menuItemIdList: yup.array().of(
      yup.object().shape({
        id: yup
          .string()
          .required('Text is required')
          .max(MAX_TITLE_SIZE, `Must be less than ${MAX_TITLE_SIZE} character`),
      })
    ),
    menuItemList: yup
      .array()
      .of(
        yup.object().shape({
          item: yup
            .string()
            .required('Text is required')
            .max(MAX_ITEM_SIZE, `Must be less than ${MAX_ITEM_SIZE} character`)
            .test('global-ok', 'The data is not correct', totalMessageSizeCheck()),
        })
      )
      .required()
      .min(1, 'At least one select option is required'),
    optionalDisplayText: yup.lazy(optionalDisplayTextSchema),
  });

  const dispatch = useDispatch();
  const { control, handleSubmit, formState, register, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'menuItemList',
  });

  const { isValid, dirtyFields, errors } = formState;
  const [itemCount, setItemCount] = useState(menuItemList?.length || 0);

  const optionalDisplayText = watch('optionalDisplayText');
  const menuTitle = watch('menuTitle');
  const menuItems = watch('menuItemList');

  function handleSave(data) {
    dispatch(closeDialog());
    props.onSubmit({
      ...props.data,
      data: {
        ...data,
        ...(data.menuItemIdList && {
          menuItemIdList: data.menuItemIdList.map((item) => {
            return item.id;
          }),
        }),
        menuItemList: data.menuItemList.map((item) => {
          return item.item;
        }),
      },
    });
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit(handleSave)}
      className="flex flex-col md:overflow-hidden"
    >
      <DialogContent className="sm:min-w-512">
        <div className="pt-8">
          <Grid container spacing={2}>
            <Grid item xs={12} md={optionalDisplayText ? 4 : 8}>
              <Grid container spacing={2}>
                {defaultValues.optionalDisplayText !== null && (
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="optionalDisplayText"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={
                            (defaultValues.placeholder &&
                              defaultValues.placeholder.optionalDisplayText) ||
                            'Optional Display Text'
                          }
                          id="optionalDisplayText"
                          error={!!errors.optionalDisplayText}
                          helperText={errors?.optionalDisplayText?.message}
                          variant="outlined"
                          multiline
                          required
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="menuTitle"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={defaultValues.placeholder?.menuTitle || 'Menu Title'}
                        id="menuTitle"
                        error={!!errors.menuTitle}
                        helperText={errors?.menuTitle?.message}
                        variant="outlined"
                        multiline
                        fullWidth
                        required
                      />
                    )}
                  />
                </Grid>

                {fields.map((item, index) => (
                  <Grid key={index} container item className="pt-20 align-middle" spacing={1}>
                    {menuItemIdList && (
                      <Grid item xs={5}>
                        <TextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">{index + 1}</InputAdornment>
                            ),
                          }}
                          label="Id"
                          control={control}
                          variant="outlined"
                          disabled={readOnly}
                          error={!!errors?.menuItemIdList?.[index]?.id}
                          helperText={errors?.menuItemIdList?.[index]?.id?.message}
                          {...register(`menuItemIdList[${index}].id`)}
                          fullWidth
                        />
                      </Grid>
                    )}

                    <Grid item xs={!menuItemIdList ? (readOnly ? 12 : 11) : readOnly ? 7 : 6}>
                      <TextField
                        InputProps={
                          !menuItemIdList && {
                            startAdornment: (
                              <InputAdornment position="start">{index + 1}</InputAdornment>
                            ),
                          }
                        }
                        label="Name"
                        disabled={readOnly}
                        control={control}
                        variant="outlined"
                        error={!!errors?.menuItemList?.[index]?.item}
                        helperText={errors?.menuItemList?.[index]?.item?.message}
                        {...register(`menuItemList[${index}].item`)}
                        fullWidth
                      />
                    </Grid>
                    {!readOnly && (
                      <Grid item xs={1} className="align-middle">
                        <IconButton
                          disabled={!!props.disabled || itemCount <= 1}
                          aria-label="delete"
                          size="medium"
                          onClick={() => {
                            remove(index);
                            setItemCount(itemCount - 1);
                          }}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                ))}

                {!readOnly && (
                  <Grid item>
                    <IconButton
                      disabled={!!props.disabled || itemCount >= 10}
                      aria-label="add"
                      size="medium"
                      onClick={() => {
                        menuItemIdList ? append({ item: '', id: '' }) : append({ item: '' });
                        setItemCount(itemCount + 1);
                      }}
                    >
                      <AddCircleIcon fontSize="inherit" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={optionalDisplayText ? 8 : 4}>
              <PreviewDisplay
                optionalDisplayText={optionalDisplayText}
                menuTitle={menuTitle}
                menuItems={menuItems}
              />
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => dispatch(closeDialog())} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          type="submit"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </form>
  );
}

export default SelectFromMenuForm;
