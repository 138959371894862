import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from '@lodash';
import { useNavigate } from 'react-router-dom';
import FormSwitch from '../../../shared-components/FormSwitch';

import { closeDialog } from '../../../store/fuse/dialogSlice';
import { saveUserNotification } from '../../../auth/store/userSlice';

function UserNotificationDialog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(({ auth }) => auth.user);
  const { control, reset, handleSubmit, formState, getValues } = useForm({
    mode: 'onChange',
    defaultValues: user.userNotifications,
  });
  const { isValid, dirtyFields } = formState;

  function redirectToOrdersIfRequired() {
    const urlParams = new URLSearchParams(window.location.search);
    const configureNotifications = urlParams.get('notificationSettings');
    if (configureNotifications) {
      navigate('/orders');
    }
  }

  function onSubmit(data) {
    dispatch(saveUserNotification(data, dispatch));
    dispatch(closeDialog());
    redirectToOrdersIfRequired();
  }

  return (
    <Dialog
      classes={{
        paper: 'm-24',
      }}
      open
      fullWidth
      maxWidth="sm"
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            Configure Notifications
          </Typography>
        </Toolbar>
      </AppBar>
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col md:overflow-hidden"
      >

        <DialogContent classes={{ root: 'p-24' }}>

          <Grid container direction="column" rowSpacing={2}>

            <Grid container item alignItems="center" justifyContent="center" >
                <span className="font-bold">
                  Notify me via e-mail message about the following events:
                </span>
            </Grid>
            <Grid container alignItems="center" className="mt-10 border border-gray-400">
              <FormSwitch
                label="Order Approval is Required by Me"
                name="orderApprovalIsRequiredByMe"
                control={control}
              />

              <FormSwitch
                label="Order Approved by Me is Canceled or Terminated"
                name="orderApprovedByMeCanceledOrTerminated"
                control={control}
              />

              <FormSwitch
                label="My Order is Approved or Rejected"
                name="myOrderIsApprovedOrRejected"
                control={control}
              />

              <FormSwitch
                label="My Order is Cancelled or Terminated"
                name="myOrderIsCancelledOrTerminated"
                control={control}
              />

              <FormSwitch
                label="My Order is Completed"
                name="myOrderIsCompleted"
                control={control}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className="justify-start pl-24 pb-16">
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={_.isEmpty(dirtyFields) || !isValid}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            color="error"
            type="button"
            onClick={() => {
              dispatch(closeDialog());
              redirectToOrdersIfRequired();
            }
          }
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default UserNotificationDialog;
