import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import { showMessage } from '../../../store/fuse/messageSlice';
import { throwErrorIfHandledByMiddleware } from '../../../store';

const logger = new ComponentLogger('ReceivedUserNotificationsSlice');

export const listReceivedUserNotifications = createAsyncThunk(
  'journey/notifications',
  async ({ username }) => {
    const response = await API.graphql({
      query: queries.listReceivedUserNotifications,
      variables: {
        username,
        limit: 100,
        sortDirection: 'DESC',
      },
    }).catch((error) => {
      logger.error('listReceivedUserNotifications', { error });
      throwErrorIfHandledByMiddleware(error);
    });

    return response.data.listReceivedUserNotifications.items;
  }
);

export const consumeNotifications = createAsyncThunk(
  'journey/updateNotifications',
  async (items, { dispatch }) => {
    const unreadItems = items.filter((item) => item.readDate == null);
    const promiseList = unreadItems.map((item) => {
      return API.graphql({
        query: mutations.updateReceivedUserNotification,
        variables: { input: { ...item, readDate: new Date() } },
      });
    });
    const results = await Promise.all(promiseList);
    if (promiseList.length > 1)
      dispatch(
        showMessage({ message: 'All notifications are marked as read!', variant: 'success' })
      );

    return results;
  }
);

export const receivedUserNotificationsSlice = createSlice({
  name: 'receivedUserNotifications',
  initialState: {
    loading: false,
    data: [],
  },
  reducers: {},
  extraReducers: {
    [listReceivedUserNotifications.pending]: (state) => {
      state.loading = true;
    },
    [listReceivedUserNotifications.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [listReceivedUserNotifications.rejected]: (state, action) => {
      state.loading = false;
      state.data = [];
    },
    [consumeNotifications.fulfilled]: (state, action) => {
      for (let i = 0; i < action.payload.length; i++) {
        const newNotification = action.payload[i];
        const notification = state.data.find(
          (obj) => obj.notificationDate === newNotification.notificationDate
        );
        if (notification) {
          const index = state.data.indexOf(notification);
          state.data[index].readDate = newNotification.readDate;
        }
      }
    },
  },
});

export default receivedUserNotificationsSlice.reducer;
