import { useState } from 'react';
import { Button } from '@mui/material';

function ButtonClickOnce({ disabled, text, onClick, ...extra }) {
  const [clicked, setClicked] = useState(false);
  const getText = (originalText) => (clicked ? 'Processing...' : originalText);
  const handleClick = () => {
    setClicked(true);
    onClick();
  };

  return (
    <Button
      className="whitespace-nowrap"
      variant="contained"
      color="secondary"
      disabled={disabled || clicked}
      onClick={handleClick}
      {...extra}
    >
      <span className="hidden sm:flex">{getText(text)}</span>
      <span className="flex sm:hidden">
        {getText(text.split(' ').length ? text.split(' ')[0] : text)}
      </span>
    </Button>
  );
}

export default ButtonClickOnce;
