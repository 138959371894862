const TEMPLATE_VARIABLE = '{$TEMPLATE_VARIABLE}';

const findTemplateVariables = (obj) => {
  const result = [];
  const search = (obj, path = []) => {
    if (obj && typeof obj === 'object' && !Array.isArray(obj)) {
      Object.keys(obj).forEach(key => {
        let value = obj[key];
        let newPath = [...path, key]; // Keep track of the path
        if (typeof value === 'object') {
          search(value, newPath);
        } else if (typeof value === 'string' && value.includes(TEMPLATE_VARIABLE)) {
          result.push({ path: newPath.join('.'), value: '' });
        }
      });
    }
  };

  search(obj);
  return result;
};

export default findTemplateVariables;
