import FusePageCarded from '@fuse/core/FusePageCarded';
import withReducer from 'app/store/withReducer';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import {
  BILLING_ADMIN_DATASET_IDS_TO_REFRESH,
  BILLING_ADMIN_DATASET_NAMES_TO_REFRESH,
} from 'app/services/constants';
import reducer from '../store';
import PageHeader from '../../../shared-components/PageHeader';
import BillingSpinner from '../BillingSpinner';
import { isUserBillingAdmin } from '../../../services/permissionUtil';
import CustomDataGrid from '../../../shared-components/CustomDataGrid';
import { getMostRecentIngestions, refreshUbsDatasets } from '../store/datasetIngestionsSlice';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import { getAttributes, selectAttributes } from '../../support/store/attributesSlice';

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 136,
      height: 136,
    },
  },
  '& .FusePageCarded-content': {
    display: 'flex',
  },
  '& .FusePageCarded-contentCard': {
    overflow: 'hidden',
  },
}));

const columns = [
  { field: 'id', headerName: 'Ingestion ID', flex: 1 },
  { field: 'name', headerName: 'Dataset Name', flex: 1 },
  { field: 'status', headerName: 'Ingestion Status', flex: 1 },
  { field: 'startTime', headerName: 'Ingestion Start Date', flex: 1 },
  { field: 'duration', headerName: 'Ingestion Duration', flex: 1 },
];

function DatasetIngestions() {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const { loading, data, isRefreshing } = useSelector(({ billing }) => billing.datasetIngestions);
  const isBillingAdmin = isUserBillingAdmin(user);
  const attributes = useSelector(selectAttributes);
  const datasetIds = getDatasetIds();
  const datasetNames = getDatasetNames();

  const datasetIdToName = {};

  if (datasetIds && datasetNames) {
    for (let i = 0; i < datasetNames.length; i += 1) {
      datasetIdToName[datasetIds[i]] = datasetNames[i];
    }
  }

  function getDatasetIds() {
    const datasetAttribute = attributes?.find((attr) => {
      return attr.name === BILLING_ADMIN_DATASET_IDS_TO_REFRESH;
    });
    return datasetAttribute ? datasetAttribute.value.split(',') : null;
  }

  function getDatasetNames() {
    const datasetAttribute = attributes?.find((attr) => {
      return attr.name === BILLING_ADMIN_DATASET_NAMES_TO_REFRESH;
    });
    return datasetAttribute ? datasetAttribute.value.split(',') : null;
  }

  useEffect(() => {
    if (!attributes.length) {
      dispatch(getAttributes());
    }
  }, [dispatch]);

  useEffect(() => {
    if (datasetIds && datasetIds.length && !data) {
      dispatch(getMostRecentIngestions(datasetIds));
    }
  }, [dispatch, datasetIds]);

  const rows = data?.map((row, i) => {
    return { ...row, name: datasetIdToName[row.datasetId], id: row.ingestionId };
  });

  const isDisabled =
    rows?.filter((row) => {
      return row.status === 'RUNNING';
    }).length > 0 || isRefreshing;

  return (
    <>
      <Root
        header={<PageHeader iconName="layers" title="Refresh Datasets" noHeaderButton />}
        content={
          loading ? (
            <FuseLoading />
          ) : (
            <div className="flex flex-col w-full gap-24 pt-24">
              <Button
                variant="contained"
                color="secondary"
                size="large"
                sx={{ width: 'fit-content', marginX: 'auto' }}
                onClick={() => dispatch(refreshUbsDatasets(datasetIds))}
                disabled={isDisabled}
              >
                {isDisabled
                  ? 'Ingestion is currently running, please try again later'
                  : 'Refresh Datasets'}
              </Button>
              <CustomDataGrid name="ingestions" rows={rows} columns={columns} density="compact" />
            </div>
          )
        }
      />
      <BillingSpinner />
    </>
  );
}

export default withReducer('billing', reducer)(DatasetIngestions);
