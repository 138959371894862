import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import _ from '@lodash';
import { useState } from 'react';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { getOptionalDisplayTextSchema } from './formHelper';
import PreviewDisplay from '../../../../../shared-components/preview/PreviewDisplay';

function LaunchBrowserForm(props) {
  const defaultValues = props.data.data;
  const optionalDisplayTextSchema = getOptionalDisplayTextSchema(defaultValues);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const schema = yup.object().shape({
    url: yup
      .string()
      .required('You must enter a valid url')
      .min(4, 'URL Must be greater than 3 character')
      .max(240, 'URL Must be less than 240 character')
      .test('is-url', 'URL is invalid.', (value) => {
        try {
          const url = new URL(`http://${value}`);
          return true;
        } catch {
          return false;
        }
      }),
    optionalDisplayText: yup.lazy(optionalDisplayTextSchema),
  });

  const dispatch = useDispatch();
  const { control, reset, handleSubmit, formState, watch, setValue, trigger } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const optionalDisplayText = watch('optionalDisplayText');
  const url = watch('url');

  function handleSave(data) {
    dispatch(closeDialog());
    props.onSubmit({ ...props.data, data });
  }

  function handleUrlChange(e, onChange) {
    const { value } = e.target;
    if (value.match(/^https?:\/\//)) {
      setIsTooltipVisible(true);
      setTimeout(() => {
        setIsTooltipVisible(false);
      }, 2000);
    }
    onChange(value.replace(/^https?:\/\//, ''));
    trigger();
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit(handleSave)}
      className="flex flex-col md:overflow-hidden"
    >
      <DialogContent className="sm:min-w-512">
        <div className="pt-8">
          <Grid container spacing={2}>
            <Grid item xs={12} md={optionalDisplayText ? 4 : 8}>
              <Grid container spacing={2}>
                {defaultValues.optionalDisplayText !== null && (
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="optionalDisplayText"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={
                            (defaultValues.placeholder &&
                              defaultValues.placeholder.optionalDisplayText) ||
                            'Optional Display Text'
                          }
                          id="optionalDisplayText"
                          error={!!errors.optionalDisplayText}
                          helperText={errors?.optionalDisplayText?.message}
                          variant="outlined"
                          required
                          multiline
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="url"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={
                          (defaultValues.placeholder && defaultValues.placeholder.url) || 'URL'
                        }
                        onChange={(e) => handleUrlChange(e, field.onChange)}
                        id="url"
                        error={!!errors.url}
                        helperText={errors?.url?.message}
                        variant="outlined"
                        multiline
                        required
                        fullWidth
                      />
                    )}
                  />
                  <p
                    key={isTooltipVisible ? 'visible-tooltip' : 'invisible-tooltip'}
                    className={`text-white bg-gray-800 text-xs font-semibold ml-4 mt-8 py-6 px-2 rounded-lg text-center max-w-xs ${
                      isTooltipVisible ? 'opacity-90' : 'opacity-0'
                    }`}
                  >
                    URL is automatically updated without http prefix.
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={optionalDisplayText ? 8 : 4}>
              <Grid item>
                <PreviewDisplay url={url} optionalDisplayText={optionalDisplayText} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => dispatch(closeDialog())} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          type="submit"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </form>
  );
}

export default LaunchBrowserForm;
