import { IconButton, Tooltip } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useDispatch } from 'react-redux';
import AlertDialog from '../../../shared-components/AlertDialog';
import { openDialog } from '../../../store/fuse/dialogSlice';
import { openEditAttributeDialog, deleteAttribute } from '../../support/store/attributesSlice';

function AttributeAction(props) {
  const dispatch = useDispatch();

  function editAttributeRow(row) {
    dispatch(openEditAttributeDialog(row));
  }

  function deleteAttributeRow(row) {
    dispatch(deleteAttribute(row));
  }

  return (
    <>
      <Tooltip title="Edit">
        <IconButton
          aria-label="Edit"
          size="medium"
          onClick={() => editAttributeRow(props.cellValues.row)}
        >
          <EditOutlinedIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>

      <Tooltip title="Delete">
        <IconButton
          aria-label="delete"
          size="medium"
          onClick={() =>
            dispatch(
              openDialog({
                children: (
                  <AlertDialog
                    title="Are you sure want to delete the attribute?"
                    okButtonName="Yes, delete the attribute"
                    cancelButtonName="No"
                    handleOk={() => deleteAttributeRow(props.cellValues.row)}
                    okButtonColor="error"
                  />
                ),
              })
            )
          }
        >
          <CancelOutlinedIcon fontSize="inherit" />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default AttributeAction;
