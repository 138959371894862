import withReducer from 'app/store/withReducer';
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import reducer, {
  saveFilter,
  saveSorting,
  saveColumnVisibilityModel,
  saveColumnOrder,
} from '../store/customDataGridSlice';

function CustomDataGrid({ name, onRefresh, onRowClick, customToolbar, customFooter, ...props }) {
  const dispatch = useDispatch();
  const apiRef = useGridApiRef();
  const { filter, sorting, columnVisibilityModel, columnOrder } = useSelector(
    ({ dataGrid }) => dataGrid
  );
  const columns = getColumns();
  const DefaultToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          csvOptions={{
            delimiter: ';',
            fileName: `${name}_${new Date().toISOString().split('T')[0]}`,
          }}
        />
        {onRefresh && (
          <Button
            variant="text"
            size="small"
            color="success"
            onClick={onRefresh}
            startIcon={<RefreshIcon />}
          >
            Refresh
          </Button>
        )}
      </GridToolbarContainer>
    );
  };

  function getColumns() {
    if (!(columnOrder[name] && columnOrder[name].length)) return props.columns;
    const orderedColumns = [];
    for (let i = 0; i < props.columns.length; i += 1)
      orderedColumns[i] = props.columns.find((c) => c.field === columnOrder[name][i]);
    return orderedColumns;
  }

  function getInitialColumnVisibilityModel() {
    const result = {};
    columns.forEach((c) => {
      result[c.field] = !c.hide;
    });
    return result;
  }

  const [hiddenColumnResized, setHiddenColumnResized] = useState(false);

  return (
    <DataGridPro
      {...props}
      apiRef={apiRef}
      onFilterModelChange={(model) => {
        props.onFilterChange && props.onFilterChange(model);
        dispatch(saveFilter({ name, model }));
      }}
      onSortModelChange={(model) => dispatch(saveSorting({ name, model }))}
      onColumnVisibilityModelChange={(model) => {
        // Ignore undesired column visibility change event fired after resize of an initially-hidden column
        // In this scenario, we also need to restore column visibilities from redux state (columnVisibilityModel)
        if (hiddenColumnResized && columnVisibilityModel[name]) {
          for (let i = 0; i < props.columns.length; i += 1) {
            props.columns[i].hide = !columnVisibilityModel[name][props.columns[i].field];
            apiRef.current.setColumnVisibility(props.columns[i].field, !props.columns[i].hide); // needed to reflect changes to UI immediately
          }
          setHiddenColumnResized(false);
        } else {
          dispatch(saveColumnVisibilityModel({ name, model }));
        }
      }}
      onColumnOrderChange={(model, event, details) => {
        dispatch(
          saveColumnOrder({
            name,
            model: details.api.state.columns.all,
          })
        );
      }}
      initialState={{
        filter: {
          filterModel: filter[name],
        },
        sorting: {
          sortModel: sorting[name] || props.defaultSorting,
        },
        columns: {
          columnVisibilityModel: columnVisibilityModel[name] || getInitialColumnVisibilityModel(),
        },
      }}
      rows={props.rows}
      columns={columns}
      density={props.density}
      rowHeight={props.rowHeight || 58}
      pagination
      components={{
        Toolbar: customToolbar || DefaultToolbar,
        Footer: customFooter,
      }}
      onRowClick={onRowClick || null}
      onColumnResize={(params) => {
        if (params.colDef.hide) {
          setHiddenColumnResized(true);
        }
      }}
    />
  );
}

export default withReducer('dataGrid', reducer)(CustomDataGrid);
