import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { submitLogin, submitOTP } from 'app/auth/store/loginSlice';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import _ from '@lodash';
import { Typography } from '@mui/material';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  code: yup
    .string()
    .required('Please enter your code')
    .matches(/^\d+$/, 'Must be only digits')
    .min(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits'),
});

const defaultValues = {
  code: '',
};

function SMSForm(props) {
  const dispatch = useDispatch();
  const login = useSelector(({ auth }) => auth.login);

  const { control, formState, handleSubmit, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const [showPassword, setShowPassword] = useState(false);

  const [timer, setTimer] = useState(180);
  const timeOutCallback = useCallback(() => setTimer((currTimer) => currTimer - 1), []);

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  useEffect(() => {
    if (login.errors && login.errors.type) {
      setError(login.errors.type, {
        type: 'manual',
        message: login.errors.message,
      });
    }
  }, [login.errors, setError]);

  const resetTimer = function () {
    if (!timer) {
      setTimer(180);
    }
    dispatch(submitLogin(login.authUser));
  };

  function onOTPChangeSubmit(model) {
    model.user = login.authUser.user;
    model.challengeName = login.authUser.challengeName;
    dispatch(submitOTP(model));
  }

  function strPadLeft(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  function prettifyTimeout(time) {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    return `${strPadLeft(minutes, '0', 2)}:${strPadLeft(seconds, '0', 2)}`;
  }

  return (
    <div className="w-full">
      <Typography variant="h6" className="mt-16 mb-24 font-semibold text-18 sm:text-24">
        Enter the code
      </Typography>
      <form
        className="flex flex-col justify-center w-full"
        onSubmit={handleSubmit(onOTPChangeSubmit)}
      >
        <Controller
          name="code"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-8"
              label="Code"
              type="number"
              error={!!errors.code}
              helperText={errors?.code?.message}
              variant="outlined"
              required
            />
          )}
        />

        <Button
          type="button"
          disabled={timer > 0}
          className="mb-16"
          color="primary"
          onClick={() => resetTimer()}
        >
          Resend Code ({prettifyTimeout(timer)})
        </Button>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-full mx-auto mt-16"
          aria-label="Submit"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          value="cognito"
        >
          Submit
        </Button>
      </form>
    </div>
  );
}

export default SMSForm;
