import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import { showMessage } from '../../../store/fuse/messageSlice';

const logger = new ComponentLogger('CorrectionsSlice');
const invalidateCacheHeader = { headers: { 'Cache-Control': 'max-age=0' } };

let billingAccounts = [];
let billingPlatforms = [];
let contracts = [];

export const getCorrections = createAsyncThunk(
  'billing/corrections/getCorrections',
  async (invalidate = false) => {
    const response = await API.get(
      'billing-api',
      'corrections',
      invalidate ? invalidateCacheHeader : {}
    );

    billingAccounts = await API.get('billing-api', 'accounts');
    billingPlatforms = await API.get('billing-api', 'platforms');
    contracts = await API.get('billing-api', 'contracts');

    response.sort((correction1, correction2) =>
      correction2.dateEntered?.localeCompare(correction1.dateEntered)
    );
    return response.map((correction) => {
      const { part1, part2 } = parseProductDetail(correction.productDetail);
      return {
        ...correction,
        billingAccountName: getAccountNames(billingAccounts, correction.billingAccountId),
        platformName: getPlatformName(billingPlatforms, correction.platformId),
        contractName: getContractName(contracts, correction.contractId),
        customerVisibleDescription: part1,
        contentProviderId: part2,
      };
    });
  }
);

export const deleteCorrection = createAsyncThunk(
  'billing/corrections/deleteCorrection',
  async (correctionID, { dispatch }) => {
    const request = {
      id: correctionID,
    };

    try {
      const response = await API.del('billing-api', `corrections/${correctionID}`, {});
      if (response === 1) {
        // row deleted
        dispatch(
          showMessage({
            message: 'Correction record has been deleted successfully!',
            variant: 'success',
          })
        );
        dispatch(getCorrections(true));
        return correctionID;
      }
      dispatch(
        showMessage({
          message: 'Correction record could not be deleted! Please refresh the page and try again!',
          variant: 'error',
        })
      );
    } catch (error) {
      logger.error('deleteCorrection', { error });
      dispatch(
        showMessage({
          message: `An error occurred while deleting the correction!`,
          variant: 'error',
        })
      );
    }
    return null;
  }
);

const parseProductDetail = (productDetail) => {
  if (productDetail === '') {
    return { part1: '', part2: '' };
  }
  const arr = productDetail.split(',');
  if (arr.length === 1) {
    return arr[0].startsWith('providerId=')
      ? { part1: '', part2: arr[0].split('providerId=')[1] }
      : { part1: arr[0], part2: '' };
  }
  if (arr.length === 2) {
    return { part1: arr[0], part2: arr[1].split('providerId=')[1] };
  }
  return { part1: '', part2: '' };
};

const getAccountNames = (accounts, accountId) => {
  const result = accounts.find((item) => item.id === accountId);
  if (result) return result.name === result.mno ? result.name : `${result.name} (${result.mno})`;
  return accountId;
};

const getPlatformName = (platforms, platformId) => {
  const result = platforms.find((item) => item.id === platformId);
  if (result) return result.name === result.mno ? result.name : `${result.name} (${result.mno})`;
  return platformId;
};

export const getContractName = (contracts, contractId) => {
  const result = contracts.find((item) => item.id === contractId);
  if (result) return result.name === result.mno ? result.name : `${result.name} (${result.id})`;
  return contractId === '0' ? 'NOT SET' : contractId;
};

const generateProductDetail = (customerVisibleDescription, providerId) => {
  const descStr = customerVisibleDescription !== '' ? `${customerVisibleDescription}` : '';
  const provId = providerId !== '' ? `providerId=${providerId}` : '';
  if (provId === '') return descStr;
  if (descStr === '') return provId;
  return `${descStr},${provId}`;
};

export const saveCorrection = createAsyncThunk(
  'billing/corrections/saveCorrection',
  async (correction, { dispatch, getState }) => {
    const state = getState();
    const productDetail = generateProductDetail(
      correction.customerVisibleDescription,
      correction.contentProviderId
    );
    correction.productDetail = productDetail;
    delete correction.customerVisibleDescription;
    delete correction.contentProviderId;
    const request = {
      body: correction,
    };
    try {
      const response = await API.post('billing-api', 'corrections', request);
      dispatch(showMessage({ message: 'Correction saved successfully!', variant: 'success' }));
      dispatch(getCorrections(true));

      return response;
    } catch (err) {
      dispatch(
        showMessage({ message: 'An error occurred while saving the correction.', variant: 'error' })
      );
      return err.response.data;
    }
  }
);

const correctionsAdapter = createEntityAdapter({});

export const { selectAll: selectCorrections, selectById: selectCorrectionById } =
  correctionsAdapter.getSelectors((state) => state.billing.corrections);

const correctionsSlice = createSlice({
  name: 'billing/corrections',
  initialState: correctionsAdapter.getInitialState({
    loading: false,
    correctionDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openNewCorrectionDialog: (state, action) => {
      state.correctionDialog = {
        type: 'new',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewCorrectionDialog: (state, action) => {
      state.correctionDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [saveCorrection.pending]: (state) => {
      state.loading = true;
    },
    [saveCorrection.fulfilled]: (state) => {
      state.loading = false;
    },
    [getCorrections.pending]: (state) => {
      state.loading = true;
    },
    [getCorrections.fulfilled]: (state, action) => {
      state.loading = false;
      correctionsAdapter.setAll(state, action.payload);
    },
    [deleteCorrection.pending]: (state) => {
      state.loading = true;
    },
    [deleteCorrection.fulfilled]: (state, action) => {
      state.loading = false;
      if (action.payload) correctionsAdapter.removeOne(state, action.payload);
    },
  },
});

export const { openNewCorrectionDialog, closeNewCorrectionDialog } = correctionsSlice.actions;

export default correctionsSlice.reducer;
