import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { showMessage } from '../../../store/fuse/messageSlice';
import { existsOrEqualsZero } from '../../../services/generalUtil';

const invalidateCacheHeader = { headers: { 'Cache-Control': 'max-age=0' } };

export const getAccounts = createAsyncThunk(
  'billing/accounts/getAccounts',
  async (invalidate = false) => {
    const response = await API.get(
      'billing-api',
      'accounts',
      invalidate ? invalidateCacheHeader : {}
    );
    return response.map((account) =>
      Object.fromEntries(Object.entries(account).filter(([k, v]) => existsOrEqualsZero(v)))
    );
  }
);

export const saveAccount = createAsyncThunk(
  'billing/accounts/saveAccount',
  async (data, { dispatch }) => {
    const account = Object.fromEntries(
      Object.entries(data).map(([k, v]) => (v === '' ? [k, null] : [k, v]))
    );
    const request = {
      body: account,
    };
    try {
      const response = await API.put('billing-api', 'accounts', request);
      dispatch(getAccounts(true));
      dispatch(showMessage({ message: 'Account saved successfully!', variant: 'success' }));
      return response;
    } catch (err) {
      dispatch(showMessage({ message: 'Account already exists!', variant: 'error' }));
      return err.response.data;
    }
  }
);

const accountsAdapter = createEntityAdapter({});

export const { selectAll: selectAccounts, selectById: selectAccountById } =
  accountsAdapter.getSelectors((state) => state.billing.accounts);

const accountsSlice = createSlice({
  name: 'billing/accounts',
  initialState: accountsAdapter.getInitialState({
    loading: false,
    accountDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openNewAccountDialog: (state, action) => {
      state.accountDialog = {
        type: 'new',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewAccountDialog: (state, action) => {
      state.accountDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditAccountDialog: (state, action) => {
      state.accountDialog = {
        type: 'edit',
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditAccountDialog: (state, action) => {
      state.accountDialog = {
        type: 'edit',
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [saveAccount.pending]: (state) => {
      state.loading = true;
    },
    [saveAccount.fulfilled]: (state) => {
      state.loading = false;
    },
    [getAccounts.pending]: (state) => {
      state.loading = true;
    },
    [getAccounts.fulfilled]: (state, action) => {
      state.loading = false;
      accountsAdapter.setAll(state, action.payload);
    },
  },
});

export const {
  openNewAccountDialog,
  closeNewAccountDialog,
  openEditAccountDialog,
  closeEditAccountDialog,
} = accountsSlice.actions;

export default accountsSlice.reducer;
