import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { SELECT_ALL_ITEM_VALUE } from '../../main/admin/permission/constants';

const Root = styled('div')(({ theme }) => ({
  '& > .logo-icon': {
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  '& > .badge, & > .logo-text': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function Logo(props) {
  const defaultBrandName = 'OnePIN';
  const { size } = props;
  const user = useSelector(({ auth }) => auth.user);
  const brandName = () => {
    if (user.role.includes('admin')) return defaultBrandName;
    const template =
      user.permissions && user.permissions.length ? user.permissions[0].campaignTemplate[0] : '';
    return !template || template === SELECT_ALL_ITEM_VALUE ? defaultBrandName : template.product;
  };

  return (
    <Root className="flex items-center">
      <img src="assets/images/logos/logo.png" alt="logo" width={size === 'large' ? 64 : 32} />
      <Typography className={`logo-text ${  size === 'large' ? 'text-32' : 'text-16'  } leading-none mx-12 font-medium`} color="inherit">
        {brandName()}
      </Typography>
    </Root>
  );
}

export default Logo;
