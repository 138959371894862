import FormInputDropdown from './FormInputDropdown';

const HANDSET_OPTIONS = [
  { label: 'Feature Phone', value: 'Feature Phone' },
  { label: 'Smartphone - Android', value: 'Smartphone - Android' },
  { label: 'Smartphone - iOS', value: 'Smartphone - iOS' },
];

export const HANDSET_OPTIONS_ALL = HANDSET_OPTIONS.map((opt) => opt.value);

function FormHandsetDropdown(props) {
  const propsMultiple = { ...props, multiple: true };
  return <FormInputDropdown {...propsMultiple} options={HANDSET_OPTIONS} />;
}

export default FormHandsetDropdown;
