export function filterByMnoStatus(
  mnos,
  data,
  validMnoStatuses = ['testing', 'deployed', 'retired']
) {
  return data.filter((row) => {
    return validMnoStatuses.includes(mnos.find(({ mno }) => mno === row.mno)?.status);
  });
}

export function createBiddingMapFromString(bidString) {
  if (!bidString) return {};
  const bidMap = {};
  bidString.split(',').forEach((bid) => {
    const [mno, bidValue] = bid.split('=');
    bidMap[mno] = bidValue;
  });
  return bidMap;
}
