import { Grid, Icon, IconButton, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import withRouter from '../../@fuse/core/withRouter';

function PageHeader(props) {
  return (
    <Grid container direction="row" alignItems="center">
      <Grid container item xs={6} gap={1} alignItems="center">
        <Icon
          component={motion.span}
          initial={{ scale: 0 }}
          animate={{ scale: 1, transition: { delay: 0.2 } }}
        >
          {props.iconName}
        </Icon>
        <Typography
          component={motion.span}
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="hidden sm:flex text-base md:text-lg mx-12 font-semibold"
        >
          {props.title}
        </Typography>
      </Grid>

      {!props.noHeaderButton && (
        <Grid container item xs={6} direction="row-reverse">
          <IconButton
            size="large"
            disabled={props.disabled}
            color="secondary"
            title={props.buttonName}
            onClick={props.onButtonClick || (() => props.navigate(props.buttonLink))}
          >
            <AddCircleIcon fontSize="large" />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

export default withRouter(PageHeader);
