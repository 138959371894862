import * as yup from 'yup';

export function getOptionalDisplayTextSchema(defaultValues) {
  return (val) => {
    if (defaultValues.optionalDisplayText !== null) {
      return isGSMAlphabet(val)
        ? yup
          .string()
          .required('You must enter a text')
          .max(232, 'Must be less than 232 character')
          .matches(noCurlyBracesPattern, 'Text should not include curly braces')
        : yup
          .string()
          .required('You must enter a text')
          .max(116, 'Must be less than 116 character')
          .matches(noCurlyBracesPattern, 'Text should not include curly braces');
    }
    return val !== null
      ? isGSMAlphabet(val)
        ? yup
          .string()
          .notRequired()
          .nullable()
          .max(232, 'Must be less than 232 character')
          .matches(noCurlyBracesPattern, 'Text should not include curly braces')
        : yup
          .string()
          .notRequired()
          .nullable()
          .max(116, 'Must be less than 116 character')
          .matches(noCurlyBracesPattern, 'Text should not include curly braces')
      : yup.object().notRequired().nullable();
  };
}

export const noCurlyBracesPattern = /^[^{}]*$/;

export function isGSMAlphabet(text) {
  const regexp = new RegExp(
    '^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!"#$%&\'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$'
  );
  return regexp.test(text);
}

export function convertPeriodToDaysOrMonths(period, type, displayOne = true) {
  let days;
  period = parseInt(period, 10);
  switch (type) {
    case 'HH':
      days = period / 24;
      break;
    case 'DD':
      days = period;
      break;
    case 'MM':
      return getUnitText(period, displayOne, 'Month');
    case 'YY':
      days = period * 365; // Approximating to 365 days per year
      break;
    default:
      return 'Invalid type';
  }

  // For days, decide whether to return days directly or convert to months based on the length

  if (days === 7) return getUnitText(1, displayOne, 'Week');
  if (days === 14) return getUnitText(1, false, 'Two Weeks');
  if (days < 30) return getUnitText(days, displayOne, 'Day');

  // Assuming an average of 30 days per month for conversion
  const months = Math.round(days / 30);
  return getUnitText(months, displayOne, 'Month');
}

function getUnitText(number, displayOne, baseText) {
  if (number === 1) {
    // If number is 1 and displayOne is true, return "1 [baseText]"
    // If displayOne is false, return just the "[baseText]" without the number
    return displayOne ? `1 ${baseText}` : baseText;
  }
  // For numbers greater than 1, always return "[number] [baseText]s" (plural)
  return `${number} ${baseText}s`;
}