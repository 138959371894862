import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Logo from '../../fuse-layouts/shared-components/Logo';
import { closeDialog } from '../../store/fuse/dialogSlice';

function AboutDialog() {
  const dispatch = useDispatch();
  const { version } = useSelector(({ fuse }) => fuse.settings);

  return (
    <Dialog
      classes={{
        paper: 'm-24',
      }}
      open
      fullWidth
      maxWidth="sm"
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            About
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent classes={{ root: 'p-24' }}>
        <Grid container direction="column" rowSpacing={2}>
          <Grid container item alignItems="center" justifyContent="center">
            <Logo size="large" />
          </Grid>
          <Grid container item alignItems="center" justifyContent="center">
            <span>Version ID: {version}</span>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="info"
          type="button"
          onClick={() => {
            dispatch(closeDialog());
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AboutDialog;
