import { Handle } from 'reactflow';
import { Box, Grid, Popover } from '@mui/material';
import { useState } from 'react';
import StatsContent from './StatsContent';
import JourneyContent from './JourneyContent';
import JourneyInfo from './JourneyInfo';
import FlowNodeHeader from './FlowNodeHeader';

function FlowNode({ data }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const maxStepWidth = '280px';
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <Box
      sx={{
        border: '1.2px solid',
        textAlign: 'center',
        padding: 2,
        borderRadius: '5px',
        maxWidth: maxStepWidth,
        maxHeight: '400px',
      }}
    >
      <Handle type="target" position="top" />
      <Grid container>
        <FlowNodeHeader
          id={data.id}
          stepType={data.stepType}
          hasStats={data.stats !== undefined}
          onClick={(e) => handleClick(e)}
          isNodeDisabled={data.isNodeDisabled}
        />
        {!data.stats && <JourneyContent data={data} stepType={data.stepType} />}

        {data.stats && (
          <Grid item className="pt-8">
            <StatsContent stats={data.stats} stepType={data.stepType} />
          </Grid>
        )}
      </Grid>
      <Handle type="source" position="bottom" />
      {(!data.isNodeDisabled || data.stats) && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box
            sx={{
              textAlign: 'center',
              maxWidth: maxStepWidth,
            }}
          >
            {!data.stats && <JourneyInfo data={data} />}
            {data.stats && <JourneyContent data={data} />}
          </Box>
        </Popover>
      )}
    </Box>
  );
}
export default FlowNode;
