import { MarkerType } from 'reactflow';
import {
  getEdgeColor,
  getEdgeStyle,
  getLayoutedElements,
  getNodeTypeByStepType,
  mergeSameDestinationTransitions,
  shortenLabels,
} from '../order/orderHelper';
import { JourneyResultMap, OrderStatus } from '../../../services/constants';
import _ from '../../../../@lodash';
import { toMnoLocalTimeStr } from '../../../services/dateUtil';
import { roleNames } from '../../../auth/authRoles';

function getEdgeStatusLabel(executedDateStatus, transition) {
  for (const key of Object.keys(JourneyResultMap)) {
    if (transition.condition.includes(key)) {
      const metric = executedDateStatus.steps[transition.originatingStep]
        ? executedDateStatus.steps[transition.originatingStep][key]
        : null;
      if (metric) {
        transition.condition = transition.condition.replace(
          key,
          `${shortenLabels(key)} - ${metric}(${(
            metric / executedDateStatus.metrics.targeted
          ).toLocaleString('en', {
            style: 'percent',
          })})`
        );
      }
    }
  }
  return transition.condition;
}

export function getExecutionStats(orderData, executionDatetime, role) {
  let executedDateStatus = {};
  const notRejectedExecutionStatuses = orderData.executionStatuses.items.filter(
    (status) => !status.rejected
  );
  function aggregateDailyExecutionStats() {
    const steps = notRejectedExecutionStatuses
      .filter((status) => orderData.status === OrderStatus.DRAFT.name || !status.singleMsisdn)
      .map((status) => {
        return JSON.parse(status.steps);
      })
      .reduce((prev, item) => {
        for (const stepName in item) {
          if (prev[stepName]) {
            for (const metricName in item[stepName]) {
              if (prev[stepName][metricName]) {
                prev[stepName][metricName] += item[stepName][metricName];
              } else {
                prev[stepName][metricName] = item[stepName][metricName];
              }
            }
          } else {
            prev[stepName] = item[stepName];
          }
        }
        return prev;
      }, {});

    const total = notRejectedExecutionStatuses
      .filter((status) => orderData.status === OrderStatus.DRAFT.name || !status.singleMsisdn)
      .map((status) => {
        return status.metrics;
      })
      .reduce((metrics, metric) => {
        for (const [metricName, count] of Object.entries(metric)) {
          if (!metrics[metricName]) {
            metrics[metricName] = 0;
          }

          metrics[metricName] += count;
        }
        return metrics;
      }, {});
    return { steps, total };
  }

  if (executionDatetime) {
    executedDateStatus = notRejectedExecutionStatuses.find(
      (status) => status.executionDatetime === executionDatetime
    );
    executedDateStatus = { ...executedDateStatus, steps: JSON.parse(executedDateStatus.steps) };
  } else {
    const { steps, total } = aggregateDailyExecutionStats();
    executedDateStatus = { steps, metrics: total };
  }
  const steps = JSON.parse(orderData.definition.steps);
  const { transitions } = orderData.definition;

  const stepMap = Array.from(Object.entries(steps)).map(([key, value]) => ({
    id: key,
    position: { x: 0, y: 0 },
    data: { ...value, id: key, stats: executedDateStatus.steps[key] },
    type: getNodeTypeByStepType(value.stepType),
  }));

  const transitionMap = mergeSameDestinationTransitions(_.cloneDeep(transitions)).map(
    (transition) => {
      return {
        id: `${transition.originatingStep}-${transition.destinationStep}-${transition.condition}-${transition.responseText}`,
        source: transition.originatingStep,
        target: transition.destinationStep,
        style: getEdgeStyle(transition.condition),
        label: getEdgeStatusLabel(executedDateStatus, transition) || '',
        labelStyle: { fontSize: '1rem' },
        type: 'smart',
        position: { x: 0, y: 0 },
        animated: true,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          color: getEdgeColor(transition.condition),
        },
      };
    }
  );
  const layoutItems = getLayoutedElements([...stepMap, ...transitionMap]);
  const elements = [...layoutItems.nodeList, ...layoutItems.edgeList].filter((element) => {
    return !element.data || !element.data.hidden || role.includes(roleNames.SUPPORT);
  });
  return {
    ...orderData.definition,
    steps: elements,
    metrics: executedDateStatus.metrics,
    singleMsisdn: executedDateStatus.singleMsisdn,
  };
}

export function getTargetedCount(orderData, metricList = null, executionDatetime = null) {
  // single msisdn tests
  if (executionDatetime) {
    const isSingleMsisdnTestExecution = orderData.executionStatuses?.items?.find(
      (item) => item.executionDatetime === executionDatetime
    )?.singleMsisdn;
    if (isSingleMsisdnTestExecution) return metricList.targeted;
  }

  // multi day executions (single msisdn tests excluded): use targeted metric in execution status
  if (
    orderData.executionStatuses?.items?.filter((item) => !item.singleMsisdn).length > 1 &&
    metricList
  )
    return Math.min(metricList.targeted, orderData.targetable || orderData.targetRequested);

  // single day executions
  if (orderData.targeted)
    // new order structure contains "targeted" field directly
    return Math.min(orderData.targeted, orderData.targetable || orderData.targetRequested);

  // old order structure - "targeted" calculated based on "REQUEST_SENT" field of the step results.
  const targetedCount = orderData.executionStatuses?.items?.length
    ? orderData.executionStatuses.items
        .filter(
          (item) =>
            (executionDatetime && item.executionDatetime === executionDatetime) ||
            (!executionDatetime &&
              (orderData.status === OrderStatus.DRAFT.name || !item.singleMsisdn))
        )
        .map((item) =>
          item.steps
            ? Math.max(...Object.values(JSON.parse(item.steps)).map((o) => o.REQUEST_SENT || 0))
            : 0
        )
        .reduce((a, b) => a + b, 0)
    : 0;

  return Math.min(targetedCount, orderData.targetable || orderData.targetRequested);
}

export function findEdges(buildingBlocks, buildingBlock) {
  return buildingBlocks.filter((element) => element.target && element.source === buildingBlock.id);
}

export function getTargetedPercentage(orderData) {
  if (orderData.status === OrderStatus.DRAFT.name) return 0;
  const targetable = orderData.targetable ?? orderData.targetRequested;
  return Math.round(
    (100 * getTargetedCount(orderData)) / Math.min(targetable, orderData.targetRequested)
  );
}

export function getRejectionReason(orderData) {
  if ('description' in orderData && orderData.description) return orderData.description;
  return null;
}

export function getSendingStartDateTime(orderData) {
  const normalExecutions = orderData.executionStatuses.items.filter((item) => !item.singleMsisdn);
  if (normalExecutions && normalExecutions.length > 0) {
    const items = normalExecutions
      .map((item) => item.targetingStartDateTime || item.executionDatetime)
      .sort((c, n) => new Date(c).getTime() - new Date(n).getTime());
    return items.length ? toMnoLocalTimeStr(items[0], orderData.mno) : null;
  }
  return null;
}

export function getSendingEndDateTime(orderData) {
  if (orderData.executionStatuses.items && orderData.executionStatuses.items.length > 0) {
    const sendingEndDatetimes = orderData.executionStatuses.items
      .filter((item) => item.targetingEndDateTime && !item.singleMsisdn)
      .map((item) => item.targetingEndDateTime)
      .sort((c, n) => new Date(n).getTime() - new Date(c).getTime());
    return sendingEndDatetimes.length
      ? toMnoLocalTimeStr(sendingEndDatetimes[0], orderData.mno)
      : null;
  }
  return null;
}

export function hasOrderAnyValidExecutionStats(order, includeTestExecutions = true) {
  return (
    order?.executionStatuses?.items?.filter(
      (status) => !status.rejected && (includeTestExecutions || !status.singleMsisdn)
    ).length > 0
  );
}
