import { Controller } from 'react-hook-form';
import Switch from '@mui/material/Switch';
import { Grid } from '@mui/material';

function FormSwitch({ name, control, label }) {
  return (
    <Grid container alignItems="center">
      <Grid item xs={9} pl={1}>
        {label}
      </Grid>
      <Grid item xs={3}>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => (
            <Switch checked={value} onChange={onChange} />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default FormSwitch;
