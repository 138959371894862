import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import DialogContent from '@mui/material/DialogContent';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect } from 'react';
import { Grid } from '@mui/material';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { closeMnoDialog, saveMno } from '../store/mnosSlice';
import _ from '../../../../@lodash';
import FormInputDropdown from '../../../shared-components/FormInputDropdown';

const statusOptions = [
  { label: 'TESTING', value: 'testing' },
  { label: 'DEPLOYED', value: 'deployed' },
  { label: 'RETIRED', value: 'retired' },
  { label: 'NEVER DEPLOYED', value: 'never_deployed' },
];
const defaultValues = {
  mno: '',
  country: '',
  localCurrency: '',
  timeZoneOffset: 0,
  status: '',
};
export default function MnoDialog() {
  const dispatch = useDispatch();
  const mnoDialog = useSelector(({ support }) => support.mnos.mnoDialog);

  const schema = yup.object().shape({
    mno: yup
      .string()
      .required('You must select MNO Name')
      .matches(
        '^[^_]+_[^_]+$',
        'MNO should include exactly 1 underscore that is not in the first or last character'
      ),
    country: yup.string().required('You must select Country'),
    localCurrency: yup.string().required('You must select local currency'),
    timeZoneOffset: yup
      .number()
      .min(-12, 'Timezone offset should be between -12 and +12')
      .max(12, 'Timezone offset should be between -12 and +12'),
    status: yup.string().required('Status is required'),
  });

  const { control, reset, handleSubmit, formState  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  const initDialog = useCallback(() => {
    reset({ ...defaultValues, ...mnoDialog.data });
  }, [mnoDialog.data, reset]);

  useEffect(() => {
    if (mnoDialog.props.open) {
      initDialog();
    }
  }, [mnoDialog.props.open, initDialog]);

  function onSubmit(payload) {
    dispatch(saveMno(payload));
    onDialogClose();
  }
  function onDialogClose() {
    dispatch(closeMnoDialog());
  }
  return (
    <Dialog
      {...mnoDialog.props}
      fullWidth
      onClose={onDialogClose}
      onSubmit={handleSubmit(onSubmit)}
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            {_.capitalize(mnoDialog.type)} MNO
          </Typography>
        </Toolbar>
      </AppBar>
      <form noValidate className="flex flex-col md:overflow-hidden">
        <DialogContent>
          <Grid container direction="column" rowSpacing={2}>
            <Grid item>
              <Controller
                name="mno"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Mno" fullWidth required type="text" />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Country" fullWidth required type="text" />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="localCurrency"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Local Currency" fullWidth required type="text" />
                )}
              />
            </Grid>
            <Grid item>
              <Controller
                name="timeZoneOffset"
                control={control}
                render={({ field }) => (
                  <TextField {...field} label="Time Zone Offset" fullWidth required type="number" />
                )}
              />
            </Grid>
            <Grid item>
              <FormInputDropdown
                name="status"
                options={statusOptions}
                required
                fullWidth
                control={control}
                label="Status"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="justify-start px-24 pb-16">
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            disabled={_.isEmpty(dirtyFields) || !isValid}
          >
            {mnoDialog.type === 'edit' ? 'Save' : 'Add'}
          </Button>
          <Button variant="outlined" color="error" onClick={() => dispatch(closeMnoDialog())}>
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
