import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import Typography from '@mui/material/Typography';
import { Box, Button, Paper, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AppleIcon from '@mui/icons-material/Apple';
import ShopIcon from '@mui/icons-material/Shop';
import { reLogin } from '../auth/store/loginSlice';
import { logoutUser } from '../auth/store/userSlice';
import { showMessage } from '../store/fuse/messageSlice';

const steps = [
  {
    label: 'Install Google Authenticator App',
    description: `Google Authenticator App generates time-sensitive verification codes, adding an extra layer of protection to your important accounts. Ensure a secure digital experience by easily verifying your identity during the two-step authentication process.`,
  },
  {
    label: 'Scan QR code',
    description: 'Open Installed Google Authenticator app and Select + and Select Scan a QR Code',
  },
  {
    label: 'Enter Verification Code',
    description: `Enter time sensitive six digit code displayed on Google Authenticator app and verify.`,
  },
];

function SetupMFATOTPForm(props) {
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [qrCode, setQrCode] = useState(null);
  const [secretCode, setSecretCode] = useState(null);

  const [otp, setOtp] = useState('');

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    getQRCode();
  }, []);

  async function getQRCode() {
    const user = await Auth.user;
    const code = await Auth.setupTOTP(user);
    setSecretCode(code);
    setQrCode(`otpauth://totp/ONEPIN:${user.username}?secret=${code}&issuer=ONEPIN`);
  }

  async function verifyCode() {
    try {
      const user = await Auth.user;
      await Auth.verifyTotpToken(user, otp);
      await Auth.setPreferredMFA(user, 'SOFTWARE_TOKEN_MFA');
      dispatch(logoutUser());
      dispatch(reLogin());
      dispatch(
        showMessage({ message: 'Authenticator code verified successfully!', variant: 'success' })
      );
    } catch (error) {
      dispatch(
        showMessage({
          message: 'Invalid code provided, please check the code and try again!',
          variant: 'error',
        })
      );
    }
  }

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  return (
    <Card className="w-full">
      <CardContent className="px-32 py-24">
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                <div className="mb-8">
                  <Typography>{step.description}</Typography>
                </div>
                {index === 0 && (
                  <section className="flex flex-row gap-12 my-24">
                    <button
                      onClick={() => {
                        window.open(
                          'https://apps.apple.com/us/app/google-authenticator/id388497605'
                        );
                      }}
                      className="flex flex-row items-center gap-8 border-2 border-black p-4 rounded-lg"
                    >
                      <AppleIcon />
                      <div className="flex flex-col gap-2 items-start">
                        <p className="text-sm font-300">Download on</p>
                        <p className="font-bold">App Store</p>
                      </div>
                    </button>
                    <button
                      onClick={() => {
                        window.open(
                          'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
                        );
                      }}
                      className="flex flex-row items-center gap-8 border-2 border-black p-4 rounded-lg"
                    >
                      <ShopIcon />
                      <div className="flex flex-col gap-2 items-start">
                        <p className="text-sm font-300">Download on</p>
                        <p className="font-bold">Google Play</p>
                      </div>
                    </button>
                  </section>
                )}
                {index === 1 && (
                  <>
                    <div className="mb-16">
                      {/* <Typography className="font-semibold mb-4 text-15">Gender</Typography> */}
                      <QRCodeCanvas value={qrCode} />
                    </div>

                    <Typography className="mb-16 text-xs font-light text-opacity-60 flex flex-col gap-4">
                      <div>Please Enter Setup Key</div>
                      <div>{secretCode}</div>
                    </Typography>
                  </>
                )}
                {index === 2 && (
                  <>
                    <div className="mb-8">
                      <TextField
                        value={otp}
                        onChange={handleOtpChange}
                        label="Code"
                        type="number"
                        variant="outlined"
                      />
                    </div>
                  </>
                )}

                <Box sx={{ mb: 2 }}>
                  <div>
                    {index !== steps.length - 1 ? (
                      <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                        Continue
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={verifyCode}
                        disabled={!otp}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Verify
                      </Button>
                    )}

                    <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                      Back
                    </Button>
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </CardContent>
    </Card>
  );
}

export default SetupMFATOTPForm;
