import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import * as queries from '../../graphql/queries';
const logger = new ComponentLogger('QuickSightSlice');

export const getQuickSightEmbedUrl = createAsyncThunk('getQuickSightEmbedUrl', async (input) => {
  try {
    const { dashboardIdAttributeName, urlParams, ...params } = input;
    const response = await API.graphql({
      query: queries.getQuickSightEmbedUrl,
      variables: params,
    });
    return {
      name: dashboardIdAttributeName,
      url: response.data.getQuickSightEmbedUrl + formatUrlParams(urlParams),
    };
  } catch (error) {
    logger.error('getQuickSightEmbedUrl', { error });
    throw Error(error.errors[0].errorType);
  }
});

function formatUrlParams(urlParams) {
  if (!urlParams) return '';
  let result = '#';
  for (const [key, value] of Object.entries(urlParams)) {
    result += `p.${key}=${value}&`;
  }
  return result.length > 1 ? result.slice(0, -1) : '';
}

const quickSightSlice = createSlice({
  name: 'quickSight',
  initialState: {
    loading: false,
    embedUrl: null,
  },
  reducers: {},
  extraReducers: {
    [getQuickSightEmbedUrl.pending]: (state) => {
      state.loading = true;
    },
    [getQuickSightEmbedUrl.fulfilled]: (state, action) => {
      state.loading = false;
      state.embedUrl = action.payload.url;
    },
    [getQuickSightEmbedUrl.rejected]: (state) => {
      state.loading = false;
      state.embedUrl = null;
    },
  },
});

export default quickSightSlice.reducer;
