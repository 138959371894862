import { API } from 'aws-amplify';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import { showMessage } from '../../../store/fuse/messageSlice';
import { throwErrorIfHandledByMiddleware } from '../../../store';

const logger = new ComponentLogger('JourneySlice');

export const getJourneys = createAsyncThunk('support/journey/getJourneys', async (dispatch) => {
  const response = await API.graphql({
    query: queries.listJourneys,
  }).catch((error) => {
    logger.error('listJourneys', { error });
  });
  const data = response.data.listJourneys.items;
  if (data.length === 0) {
    return [];
  }

  return data;
});

export const saveJourney = createAsyncThunk(
  'support/journey/saveJourney',
  async (journey, { dispatch, rejectWithValue }) => {
    let data;

    const validationResponse = await API.graphql({
      query: queries.validateJourney,
      variables: { journey: JSON.stringify(journey) },
    }).catch((error) => {
      logger.error('validateJourney', { data: journey, error });
      throwErrorIfHandledByMiddleware(error);
    });
    if (validationResponse.data.validateJourney.statusCode !== 200) {
      logger.error('validateJourneyStatusError', { data: journey, error: validationResponse });
      dispatch(
        showMessage({
          message: `Validation has been failed!, error: ${JSON.stringify(
            validationResponse.data.validateJourney.description
          )}`,
          variant: 'error',
        })
      );
      return (rejectWithValue(validationResponse.data.validateJourney.description));
    }
    if (!journey.id) {
      const response = await API.graphql({
        query: mutations.createJourney,
        variables: { input: journey },
      }).catch((error) => {
        logger.error('createJourney', { data: journey, error });
        throwErrorIfHandledByMiddleware(error);
      });

      data = await response.data.createJourney;
      dispatch(
        showMessage({
          message: 'Journey has been created successfully!',
          variant: 'success',
        })
      );
    } else {
      const { createdAt, updatedAt, version, _version, _deleted, _lastChangedAt, ...rest } =
        journey;
      const response = await API.graphql({
        query: mutations.updateJourney,
        variables: { input: rest },
      }).catch((error) => {
        logger.error('updateJourney', { data: journey, error });
        throwErrorIfHandledByMiddleware(error);
      });

      data = await response.data.updateJourney;
      dispatch(
        showMessage({
          message: 'Journey has been updated successfully!',
          variant: 'success',
        })
      );
    }
    return data;
  }
);

const journeyAdapter = createEntityAdapter({});

export const { selectAll: selectJourneys, selectById: selectJourneyByID } =
  journeyAdapter.getSelectors((state) => state.support.journeys);

export const journeysSlice = createSlice({
  name: 'journey',
  initialState: journeyAdapter.getInitialState({
    loading: true,
  }),
  reducers: {},
  extraReducers: {
    [getJourneys.pending]: (state) => {
      state.loading = true;
    },
    [getJourneys.fulfilled]: (state, action) => {
      state.loading = false;
      journeyAdapter.setAll(state, action.payload);
    },
    [getJourneys.rejected]: (state, action) => {
      state.loading = false;
    },
    [saveJourney.fulfilled]: (state, action) => {
      const Journey = action.payload;
      journeyAdapter.upsertOne(state, Journey);
    },
  },
});

export default journeysSlice.reducer;
