import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import { showMessage } from '../../../store/fuse/messageSlice';

const logger = new ComponentLogger('MnosSlice');

const invalidateCacheHeader = { headers: { 'Cache-Control': 'max-age=0' } };
export const getMnos = createAsyncThunk(
  'support/getMnos',
  async (invalidate = false, { dispatch }) => {
    try {
      const response = await API.get('billing-api', 'mno', invalidate ? invalidateCacheHeader : {});
      return response.sort((a, b) => a.id - b.id);
    } catch (err) {
      dispatch(
        showMessage({
          message: 'An error occurred while fetching the MNO records',
          variant: 'error',
        })
      );
      return [];
    }
  }
);

export const saveMno = createAsyncThunk('support/saveMno', async (mno, { dispatch }) => {
  try {
    const response = await API.put('billing-api', 'mno', {
      body: mno,
    });
    dispatch(showMessage({ message: 'MNO is successfully saved', variant: 'success' }));
    dispatch(getMnos(true));
    return response;
  } catch (err) {
    dispatch(showMessage({ message: 'An error occurred while saving the MNO', variant: 'error' }));
    dispatch(getMnos(true));
  }
});

const mnosAdapter = createEntityAdapter({});
export const { selectAll: selectMnos, selectById: selectMnoByID } = mnosAdapter.getSelectors(
  (state) => state.support.mnos
);

export const mnosSlice = createSlice({
  name: 'support/mno',
  initialState: mnosAdapter.getInitialState({
    loading: false,
    mnoDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openMnoDialog: (state, action) => {
      const { actionType, actionData } = action.payload;
      state.mnoDialog = {
        type: actionType,
        props: {
          open: true,
        },
        data: actionData,
      };
    },
    closeMnoDialog: (state, action) => {
      state.mnoDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [getMnos.pending]: (state) => {
      state.loading = true;
    },
    [getMnos.fulfilled]: (state, action) => {
      state.loading = false;
      mnosAdapter.setAll(state, action.payload);
    },
    [getMnos.rejected]: (state, action) => {
      logger.error('getMnos', { error: action.error });
      state.loading = false;
    },
    [saveMno.pending]: (state, action) => {
      state.loading = true;
    },
    [saveMno.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [saveMno.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { openMnoDialog, closeMnoDialog } = mnosSlice.actions;

export default mnosSlice.reducer;
