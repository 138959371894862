import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
    opacity: 0.7,
  },
  progress: {
    color: theme.palette.secondary.dark,
  },
}));

function BillingSpinner() {
  const classes = useStyles();

  const { loading: loadingPlatform } = useSelector(({ billing }) => billing.platforms);
  const { loading: loadingCode } = useSelector(({ billing }) => billing.codes);
  const { loading: loadingAccount } = useSelector(({ billing }) => billing.accounts);
  const { loading: loadingCorrections } = useSelector(({ billing }) => billing.corrections);
  const { loading: loadingPrices } = useSelector(({ billing }) => billing.prices);

  return (
    <div
      className={clsx(
        classes.root,
        `flex absolute z-50 top-0 left-0 w-full h-full items-center justify-center ${
          loadingPlatform || loadingCode || loadingAccount || loadingCorrections || loadingPrices
            ? ''
            : 'hidden'
        }`
      )}
    >
      <CircularProgress className={classes.progress} />
    </div>
  );
}

export default React.memo(BillingSpinner);
