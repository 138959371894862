import { DataGridPro as DataGrid } from '@mui/x-data-grid-pro';

import { DialogTitle } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import {
  ATTRIBUTE_NAME_LOGIN_PAGE_MESSAGE,
  ATTRIBUTE_NAME_QS_DASHBOARD_ID_BILLING_REPORT_CP,
  ATTRIBUTE_NAME_QS_DASHBOARD_ID_BILLING_REPORT_MNO,
  ATTRIBUTE_NAME_QS_DASHBOARD_ID_BILLING_REPORT_RESELLER,
  ATTRIBUTE_NAME_QS_DASHBOARD_ID_PERFORMANCE_REPORT,
} from '../../../services/constants';

const columns = [
  { field: 'name', headerName: 'Attribute Name', flex: 0.75 },
  { field: 'description', headerName: 'Description', flex: 1 },
];

const rows = [
  {
    id: 1,
    name: ATTRIBUTE_NAME_LOGIN_PAGE_MESSAGE,
    description: 'Maintenance message displayed on login page',
  },
  {
    id: 2,
    name: ATTRIBUTE_NAME_QS_DASHBOARD_ID_PERFORMANCE_REPORT,
    description: 'QuickSight Dashboard ID for Performance Report',
  },
  {
    id: 3,
    name: ATTRIBUTE_NAME_QS_DASHBOARD_ID_BILLING_REPORT_CP,
    description: 'QuickSight Dashboard ID for CP Billing Report',
  },
  {
    id: 4,
    name: ATTRIBUTE_NAME_QS_DASHBOARD_ID_BILLING_REPORT_MNO,
    description: 'QuickSight Dashboard ID for MNO Billing Report',
  },
  {
    id: 5,
    name: ATTRIBUTE_NAME_QS_DASHBOARD_ID_BILLING_REPORT_RESELLER,
    description: 'QuickSight Dashboard ID for Reseller Billing Report',
  },
];

function SystemAttributesInfo() {
  return (
    <>
      <DialogTitle id="alert-dialog-title" className="text-sm">
        Supported System Attributes
      </DialogTitle>
      <DialogContent sx={{ width: '600px' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          density="compact"
          pageSize={1}
          rowsPerPageOptions={[1]}
          disableColumnMenu
          disableColumnFilter
          hideFooter
          hideFooterPagination
          autoHeight
        />
      </DialogContent>
    </>
  );
}

export default SystemAttributesInfo;
