import React from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { closeDialog } from 'app/store/fuse/dialogSlice';

function TextViewDialog(props) {
  const json = props.data;
  const dispatch = useDispatch();

  return (
    <Dialog
      classes={{
        paper: 'm-24',
      }}
      open
      fullWidth
      maxWidth="sm"
    >
      <AppBar position="static" elevation={0}>
        <Toolbar className="flex w-full">
          <Typography variant="subtitle1" color="inherit">
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>

      <DialogContent classes={{ root: 'p-24' }}>
        <pre style={{whiteSpace: "pre-wrap"}}>{json}</pre>
      </DialogContent>
      <DialogActions className="flex flex-row items-center  justify-center">
        <Button
          variant="outlined"
          color="error"
          type="button"
          onClick={() => dispatch(closeDialog())}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default TextViewDialog;
