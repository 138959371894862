import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import _ from '@lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import PreviewDisplay from '../../../../../shared-components/preview/PreviewDisplay';
import { noCurlyBracesPattern, getOptionalDisplayTextSchema, isGSMAlphabet } from './formHelper';
/**
 * Form Validation Schema
 */

function DisplayTextForm(props) {
  const defaultValues = props.data.data;

  const optionalDisplayTextSchema = getOptionalDisplayTextSchema(defaultValues);

  const schema = yup.object().shape({
    text: yup.lazy((val) =>
      isGSMAlphabet(val)
        ? yup
            .string()
            .required('You must enter a text')
            .max(240, 'Must be less than 240 character')
            .matches(noCurlyBracesPattern, 'Text should not include curly braces')
        : yup
            .string()
            .required('You must enter a text')
            .max(120, 'Must be less than 120 character')
            .matches(noCurlyBracesPattern, 'Text should not include curly braces')
    ),
    optionalDisplayText: yup.lazy(optionalDisplayTextSchema),
  });

  const dispatch = useDispatch();
  const { control, reset, handleSubmit, formState, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const text = watch('text');
  const optionalDisplayText = watch('optionalDisplayText');
  const { isValid, dirtyFields, errors } = formState;

  function handleSave(data) {
    dispatch(closeDialog());
    props.onSubmit({ ...props.data, data });
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit(handleSave)}
      className="flex flex-col md:overflow-hidden"
    >
      <DialogContent className="sm:min-w-512">
        <div className="pt-8">
          <Grid container spacing={2}>
            <Grid item xs={12} md={optionalDisplayText ? 4 : 8}>
              <Grid container spacing={2}>
                {defaultValues.optionalDisplayText !== null && (
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="optionalDisplayText"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={
                            (defaultValues.placeholder &&
                              defaultValues.placeholder.optionalDisplayText) ||
                            'Optional Display Text'
                          }
                          id="optionalDisplayText"
                          error={!!errors.optionalDisplayText}
                          helperText={errors?.optionalDisplayText?.message}
                          variant="outlined"
                          multiline
                          required
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="text"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={
                          (defaultValues.placeholder && defaultValues.placeholder.text) || 'Text'
                        }
                        id="text"
                        error={!!errors.text}
                        helperText={errors?.text?.message}
                        variant="outlined"
                        multiline
                        required
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={optionalDisplayText ? 8 : 4}>
              <Grid item>
                <PreviewDisplay text={text} optionalDisplayText={optionalDisplayText} />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => dispatch(closeDialog())} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          type="submit"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </form>
  );
}

export default DisplayTextForm;
