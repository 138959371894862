import FusePageSimple from '@fuse/core/FusePageSimple';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import SetupMFASMSForm from './SetupMFASMSForm';
import SetupMFATOTPForm from './SetupMFATOTPForm';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
}));

function SetupMFAForm() {
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth.login);
  return (
    <Root
      content={
        <div className="flex flex-auto justify-center w-full max-w-md p-24 sm:p-32">
          <div className="md:flex">
            <div className="w-full flex flex-col flex-1 md:ltr:pr-32 md:rtl:pl-32">
              <Typography className="text-center text-xl font-semibold leading-tight mb-32">
                Please set up an Authenticator App for Multi-Factor Authentication (MFA).
              </Typography>
              <SetupMFATOTPForm />
            </div>
          </div>
        </div>
      }
    />
  );
}

export default SetupMFAForm;
