import { API } from 'aws-amplify';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import { showMessage } from '../../../store/fuse/messageSlice';
import {
  MAX_PERMISSION_LIMIT,
  SELECT_ALL_ITEM_LABEL,
  SELECT_ALL_ITEM_VALUE,
} from '../permission/constants';
import { throwErrorIfHandledByMiddleware } from '../../../store';
import { createDefaultUserSettings } from './userSettingsSlice';

let billingAccounts = [];
let billingCodes = [];
const ID_SEPARATOR = '#';
const logger = new ComponentLogger('PermissionSlice');

export const getPermissions = createAsyncThunk('admin/getPermissions', async (dispatch) => {
  let data = [];
  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    const response = await API.graphql({
      query: queries.listPermissions,
      variables: {
        limit: MAX_PERMISSION_LIMIT,
        nextToken,
      },
    }).catch((error) => {
      logger.error('listPermissions', { error });
      throwErrorIfHandledByMiddleware(error);
    });
    nextToken = response.data.listPermissions.nextToken;
    data = data.concat(response.data.listPermissions.items);
  } while (nextToken);

  if (data.length === 0) {
    dispatch(showMessage({ message: 'No permissions found!', variant: 'info' }));
    return [];
  }

  billingAccounts = await API.get('billing-api', 'accounts');
  billingCodes = await API.get('billing-api', 'codes');

  return data.map((permission) => {
    return {
      ...permission,
      id: getPermissionId(permission),
      billingAccountNames: getAccountNames(billingAccounts, permission.billingAccount),
      billingCodeNames: getCodeNames(billingCodes, permission.billingCode),
    };
  });
});

const getAccountNames = (accounts, accountIds) => {
  return accountIds.map((accountId) => {
    if (accountId === SELECT_ALL_ITEM_VALUE) return SELECT_ALL_ITEM_LABEL;
    const result = accounts.find((item) => item.id === accountId);
    if (result) return result.name === result.mno ? result.name : `${result.name} (${result.mno})`;
    return `<unknown account:${accountId}>`;
  });
};

const getCodeNames = (codes, codeIds) => {
  if (!codeIds) return null;
  return codeIds.map((codeId) => {
    if (codeId === SELECT_ALL_ITEM_VALUE) return SELECT_ALL_ITEM_LABEL;
    const result = codes.find((item) => item.id === codeId);
    if (result) return result.name;
    return `<unknown code:${codeId}>`;
  });
};

const setAccountNames = (permission) => {
  if (permission.billingAccount.length)
    permission.billingAccountNames = getAccountNames(billingAccounts, permission.billingAccount);
  else permission.billingAccountNames = [];
};

const setCodeNames = (permission) => {
  if (permission.billingCode.length)
    permission.billingCodeNames = getCodeNames(billingCodes, permission.billingCode);
  else permission.billingCodeNames = [];
};

export const getPermissionId = (permission) =>
  `${permission.username}${ID_SEPARATOR}${permission.permission}`;
const setPermissionId = (permission) => {
  if (!permission.id) permission.id = getPermissionId(permission);
};

async function createPermissionRecord(permission) {
  const response = await API.graphql({
    query: mutations.createPermission,
    variables: { input: permission },
  }).catch((error) => {
    logger.error('createPermissionRecord', { data: permission, error });
  });
  return response.data.createPermission;
}

export const savePermission = createAsyncThunk(
  'admin/permissions/savePermission',
  async ({ permission, isFirstPermissionRecordForUser, initialPermissionType }, { dispatch }) => {
    let data;
    if (!permission.id) {
      const { billingAccountNames: accounts, billingCodeNames: codes, createdAt: created, updatedAt: updated, product, ...rest } = permission;
      data = await createPermissionRecord(rest);
      const isDefaultUserSettingsCreated = isFirstPermissionRecordForUser
        ? await createDefaultUserSettings(permission.username, permission.updaterId)
        : false;
      dispatch(
        showMessage({
          message: `Permission ${
            isDefaultUserSettingsCreated ? ' and default User Settings have' : 'has'
          } been created successfully!`,
          variant: 'success',
        })
      );
    } else {
      const { id, billingAccountNames, billingCodeNames, createdAt, updatedAt, ...permissionData } =
        permission;
      if (id === getPermissionId(permissionData)) {
        // normal update
        const response = await API.graphql({
          query: mutations.updatePermission,
          variables: { input: permissionData },
        }).catch((error) => {
          logger.error('invokePermission', { data: permissionData, error });
        });
        data = await response.data.updatePermission;
      } else {
        // id changed
        // delete existing record first and then insert updated record
        const existingKeyArr = id.split(ID_SEPARATOR);
        await deletePermissionRecord({
          username: existingKeyArr[0],
          permission: existingKeyArr[1],
        });
        data = await createPermissionRecord(permissionData);
        data.deletedId = id;
      }
      dispatch(
        showMessage({ message: 'Permission has been updated successfully!', variant: 'success' })
      );
    }
    return data;
  }
);

async function deletePermissionRecord(permission) {
  const request = {
    username: permission.username,
    permission: permission.permission,
  };
  const response = await API.graphql({
    query: mutations.deletePermission,
    variables: { input: request },
  }).catch((error) => {
    logger.error('deletePermissionRecord', { data: permission, error });
  });
  return response.data.deletePermission;
}

export const deletePermission = createAsyncThunk(
  'admin/permissions/deletePermission',
  async (permission, { dispatch }) => {
    // ugly workaround to save deleter
    const { id, billingAccountNames, billingCodeNames, createdAt, updatedAt, ...permissionData } =
      permission;
    await API.graphql({
      query: mutations.updatePermission,
      variables: { input: permissionData },
    }).catch((error) => {
      logger.error('deletePermission', { data: permission, error });
    });
    const data = await deletePermissionRecord(permission);
    dispatch(
      showMessage({ message: 'Permission has been deleted successfully!', variant: 'success' })
    );

    return data;
  }
);

const permissionsAdapter = createEntityAdapter({});

export const { selectAll: selectPermissions, selectById: selectPermissionById } =
  permissionsAdapter.getSelectors((state) => state.admin.permissions);

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: permissionsAdapter.getInitialState({
    loading: true,
    permissionDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    closePermissionDialog: (state, action) => {
      state.permissionDialog = {
        type: action.payload.type,
        props: {
          open: false,
        },
        data: null,
      };
    },
    openPermissionDialog: (state, action) => {
      const { type, data } = { ...action.payload };
      let dialogData = data;

      if (type === 'edit' && data) {
        dialogData = {
          ...data,
          billingCode: data.billingCode || [],
          productFamily: data.productFamily || [],
        };
      }

      if (type === 'clone') {
        const { id, ...rest } = data;
        dialogData = rest;
      }

      state.permissionDialog = {
        type,
        props: {
          open: true,
        },
        data: type === 'new' ? null : dialogData,
      };
    },
  },
  extraReducers: {
    [getPermissions.pending]: (state) => {
      state.loading = true;
    },
    [getPermissions.fulfilled]: (state, action) => {
      state.loading = false;
      permissionsAdapter.setAll(state, action.payload);
    },
    [getPermissions.rejected]: (state, action) => {
      state.loading = false;
    },
    [savePermission.fulfilled]: (state, action) => {
      const { deletedId, ...permission } = action.payload;
      if (deletedId) {
        permissionsAdapter.removeOne(state, deletedId);
      }
      setPermissionId(permission);
      setAccountNames(permission);
      setCodeNames(permission);
      permissionsAdapter.upsertOne(state, permission);
    },
    [deletePermission.pending]: (state) => {
      state.loading = true;
    },
    [deletePermission.fulfilled]: (state, action) => {
      permissionsAdapter.removeOne(state, getPermissionId(action.payload));
      state.loading = false;
    },
  },
});

export const { closePermissionDialog, openPermissionDialog } = permissionsSlice.actions;

export default permissionsSlice.reducer;
