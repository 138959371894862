export const DATA_GRID_NAME = 'orders';

export const DEFAULT_SORTING = [
  {
    field: 'validFrom',
    sort: 'desc',
  },
  {
    field: 'sendingStart',
    sort: 'desc',
  },
  {
    field: 'state',
    sort: 'asc',
  },
];
