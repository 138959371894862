import { API } from 'aws-amplify';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import { showMessage } from '../../../store/fuse/messageSlice';
import { throwErrorIfHandledByMiddleware } from '../../../store';
import ComponentLogger from 'app/services/logger/ComponentLogger';

const logger = new ComponentLogger('MTNSAProviderIDsSlice');

let billingAccounts = [];

export const getMTNSAProviderIDs = createAsyncThunk(
  'admin/edr/getMTNSAProviderIDs',
  async (dispatch) => {
    const response = await API.graphql({
      query: queries.listMTNSAProviderIDs,
    }).catch((error) => {
      logger.error('getMTNSAProviderIDs', { error });
      throwErrorIfHandledByMiddleware(error);
    });

    billingAccounts = await API.get('billing-api', 'accounts');
    const data = await response.data.listMTNSAProviderIDs.items;
    if (data.length === 0) {
      return [];
    }

    return data.map((provider) => {
      return {
        ...provider,
        id: provider.billingAccountId,
        billingAccountName: getAccountName(provider.billingAccountId),
      };
    });
  }
);

const getAccountName = (accountId) =>
  billingAccounts.find((item) => item.id === accountId)?.name || `<unknown account:${accountId}>`;

export const saveMTNSAProviderID = createAsyncThunk(
  'admin/edr/saveMTNSAProviderID',
  async (MTNSAProviderID, { dispatch }) => {
    const { billingAccountId, providerIds, ...rest } = MTNSAProviderID;
    const input = { billingAccountId, providerIds };
    let data;
    if (!MTNSAProviderID.createdAt) {
      const response = await API.graphql({
        query: mutations.createMTNSAProviderID,
        variables: { input },
      }).catch((error) => {
        logger.error('saveMTNSAProviderID', { data: rest, error });
        throwErrorIfHandledByMiddleware(error);
      });

      data = await response.data.createMTNSAProviderID;
      dispatch(
        showMessage({
          message: 'Provider IDs has been created successfully!',
          variant: 'success',
        })
      );
    } else {
      const response = await API.graphql({
        query: mutations.updateMTNSAProviderID,
        variables: { input },
      }).catch((error) => {
        logger.error('updateMTNSAProviderID', { data: input, error });
        throwErrorIfHandledByMiddleware(error);
      });

      data = await response.data.updateMTNSAProviderID;
      dispatch(
        showMessage({
          message: 'Provider IDs has been updated successfully!',
          variant: 'success',
        })
      );
    }
    return data;
  }
);

export const deleteMTNSAProviderID = createAsyncThunk(
  'admin/edr/deleteMTNSAProviderID',
  async (MTNSAProviderID, { dispatch }) => {
    const request = {
      billingAccountId: MTNSAProviderID.billingAccountId,
    };

    const response = await API.graphql({
      query: mutations.deleteMTNSAProviderID,
      variables: { input: request },
    }).catch((error) => {
      logger.error('deleteMTNSAProviderID', { data: MTNSAProviderID, error });
      throwErrorIfHandledByMiddleware(error);
    });

    const data = await response.data.deleteMTNSAProviderID;
    dispatch(
      showMessage({
        message: 'Provider IDs has been deleted successfully!',
        variant: 'success',
      })
    );

    return data;
  }
);

const MTNSAProviderIDAdapter = createEntityAdapter({});

export const { selectAll: selectMTNSAProviderIDs, selectById: selectMTNSAProviderIDByID } =
  MTNSAProviderIDAdapter.getSelectors((state) => state.admin.edr);

export const MTNSAProviderIDsSlice = createSlice({
  name: 'edr',
  initialState: MTNSAProviderIDAdapter.getInitialState({
    loading: true,
    providerIDDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openNewProviderIDDialog: (state, action) => {
      state.providerIDDialog = {
        type: 'new',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewProviderIDDialog: (state, action) => {
      state.providerIDDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditProviderIDDialog: (state, action) => {
      const payload = { ...action.payload };
      state.providerIDDialog = {
        type: 'edit',
        props: {
          open: true,
        },
        data: payload,
      };
    },
    closeEditProviderIDDialog: (state, action) => {
      state.providerIDDialog = {
        type: 'edit',
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [getMTNSAProviderIDs.pending]: (state) => {
      state.loading = true;
    },
    [getMTNSAProviderIDs.fulfilled]: (state, action) => {
      state.loading = false;
      MTNSAProviderIDAdapter.setAll(state, action.payload);
    },
    [getMTNSAProviderIDs.rejected]: (state, action) => {
      state.loading = false;
    },
    [saveMTNSAProviderID.fulfilled]: (state, action) => {
      const MTNSAProviderID = action.payload;
      MTNSAProviderID.billingAccountName = getAccountName(action.payload.billingAccountId);
      MTNSAProviderID.id = action.payload.billingAccountId;
      MTNSAProviderIDAdapter.upsertOne(state, MTNSAProviderID);
    },
    [deleteMTNSAProviderID.pending]: (state) => {
      state.loading = true;
    },
    [deleteMTNSAProviderID.fulfilled]: (state, action) => {
      MTNSAProviderIDAdapter.removeOne(state, action.payload.billingAccountId);
      state.loading = false;
    },
  },
});

export const {
  openNewProviderIDDialog,
  closeNewProviderIDDialog,
  openEditProviderIDDialog,
  closeEditProviderIDDialog,
} = MTNSAProviderIDsSlice.actions;

export default MTNSAProviderIDsSlice.reducer;
