import { API } from 'aws-amplify';
import { createAsyncThunk } from '@reduxjs/toolkit';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import * as mutations from '../../../../graphql/mutations';
import { throwErrorIfHandledByMiddleware } from '../../../store';

const logger = new ComponentLogger('PolicySlice');

export const savePolicyLog = createAsyncThunk(
  'policy/savePolicyLog',
  async (PolicyLog, { dispatch }) => {
    const { username, approvedVersion, currentPolicy } = PolicyLog;
    const input = { username, approvedVersion };
    let data;
    if (currentPolicy === null || (currentPolicy && !currentPolicy.createdAt)) {
      const response = await API.graphql({
        query: mutations.createPolicyLog,
        variables: { input },
      }).catch((error) => {
        logger.error('createPolicyLog', { data: PolicyLog, error });
        throwErrorIfHandledByMiddleware(error);
      });

      data = await response.data.savePolicyLog;
    } else {
      const response = await API.graphql({
        query: mutations.updatePolicyLog,
        variables: { input },
      }).catch((error) => {
        logger.error('updatePolicyLog', { data: input, error });
        throwErrorIfHandledByMiddleware(error);
      });

      data = await response.data.updatePolicyLog;
    }
    return data;
  }
);
