import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { Auth } from '@aws-amplify/auth';
import {
  CognitoIdentityProviderClient,
  ListUsersCommand,
} from '@aws-sdk/client-cognito-identity-provider';
import ComponentLogger from 'app/services/logger/ComponentLogger';

const logger = new ComponentLogger('CognitoUsersSlice');

export const listCognitoUsers = createAsyncThunk('admin/listCognitoUsers', async (dispatch) => {
  const credentials = await Auth.currentUserCredentials();
  const client = new CognitoIdentityProviderClient({
    clientId: process.env.COGNITO_CLIENT_ID,
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    credentials,
  });

  let paginationToken = null;
  const users = [];
  const commandStr = {
    UserPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
  };

  do {
    if (paginationToken) commandStr.PaginationToken = paginationToken;
    const command = new ListUsersCommand(commandStr);
    const response = await client.send(command);
    response.Users.filter((user) => user.Enabled === true)
      .map((user) => ({
        id: user.Username,
        email: user.Attributes.find((attr) => attr.Name === 'email').Value,
      }))
      .forEach((user) => users.push(user));
    paginationToken = response.PaginationToken;
  } while (paginationToken);

  return users.sort((user1, user2) => user1.email.localeCompare(user2.email));
});

const cognitoUsersAdapter = createEntityAdapter({});

export const { selectAll: selectCognitoUsers } = cognitoUsersAdapter.getSelectors(
  (state) => state.admin.cognitoUsers
);

export const cognitoUsersSlice = createSlice({
  name: 'cognitoUsers',
  initialState: cognitoUsersAdapter.getInitialState({
    loading: true,
  }),
  reducers: {},
  extraReducers: {
    [listCognitoUsers().pending]: (state) => {
      state.loading = true;
    },
    [listCognitoUsers.fulfilled]: (state, action) => {
      state.loading = false;
      cognitoUsersAdapter.setAll(state, action.payload);
    },
    [listCognitoUsers.rejected]: (state, action) => {
      logger.error('listCognitoUsers', { error: action?.error });
      state.loading = false;
    },
  },
});

export const {} = cognitoUsersSlice.actions;

export default cognitoUsersSlice.reducer;
