import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  height: 26,
  borderRadius: 2,
}));
const Value = styled('div')({
  position: 'absolute',
  lineHeight: '24px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});
const Bar = styled('div')({
  height: '100%',
});
function ProgressBar(props) {
  const { value } = props;

  return (
    <Root>
      <Bar
        className='bg-gray-400'
        style={{
          maxWidth: `${value}%`,
        }}
      >
        <Value>{value}%</Value>
      </Bar>
    </Root>
  );
}

export default ProgressBar;
