import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import * as queries from '../../../../graphql/queries';
import { showMessage } from '../../../store/fuse/messageSlice';

const logger = new ComponentLogger('OrderEventsSlice');


export const listJourneyOrderEvents = createAsyncThunk('journey/orders/events', async (journeyOrderId, { dispatch }) => {
  const response = await API.graphql({
    query: queries.listJourneyOrderEvents,
    variables: {
        "journeyOrderId": journeyOrderId
    }
  }).catch((error) => {
      logger.error('listJourneyOrderEvents', { error });
  });

  if (response.data.listJourneyOrderEvents.items.length === 0) {
    dispatch(
      showMessage({
        message: 'No event found for this order!',
        variant: 'info',
      })
    );
  }

  const result = response.data.listJourneyOrderEvents.items;

  result.forEach((element, index) => {
    result[index].id = uuidv4();
  });

  return result;
});



export const journeyOrderEventSlice = createSlice({
  name: 'journeyOrderEvents',
  initialState: {
    loading: false,
    data: [],
  },
  reducers: {
    resetData: (state) => {
      state.data = [];
    },
  },
  extraReducers: {
    [listJourneyOrderEvents.pending]: (state) => {
      state.loading = true;
    },
    [listJourneyOrderEvents.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [listJourneyOrderEvents.rejected]: (state, action) => {
      state.loading = false;
      state.data = [];
    },
  },
});

export const { resetData } = journeyOrderEventSlice.actions;

export default journeyOrderEventSlice.reducer;

