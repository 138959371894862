/**
 * Authorization Roles
 */
export const roleNames = {
  USER: 'user',
  USER_WITH_ORDER_PERMISSION: 'userWithOrderPermission',
  USER_WITH_BILLING_PERMISSION: 'userWithBillingPermission',
  USER_WITH_SEARCH_MSISDN_ACTIVITY: 'userWithSearchMSISDNActivity',
  USER_WITH_VIEW_PROVISIONED_SUBSCRIBERS: 'userWithViewProvisionedSubscribers',
  USER_WITH_PAYMENT_FUNCTIONALITY: 'userWithPaymentFunctionality',
  ADMIN: 'admin',
  SELF_ADMIN: 'selfAdmin',
  BILLING_ADMIN: 'billingAdmin',
  SUPPORT: 'support',
};

export const userVirtualRoles = [
  roleNames.USER_WITH_ORDER_PERMISSION,
  roleNames.USER_WITH_BILLING_PERMISSION,
  roleNames.USER_WITH_SEARCH_MSISDN_ACTIVITY,
  roleNames.USER_WITH_VIEW_PROVISIONED_SUBSCRIBERS,
  roleNames.USER_WITH_PAYMENT_FUNCTIONALITY,
];

export const authRoles = {
  user: [roleNames.USER],
  userWithOrderPermission: [roleNames.USER_WITH_ORDER_PERMISSION],
  userWithBillingPermission: [roleNames.USER_WITH_BILLING_PERMISSION],
  userWithSearchMSISDNActivity: [roleNames.USER_WITH_SEARCH_MSISDN_ACTIVITY],
  userWithViewProvisionedSubscribers: [roleNames.USER_WITH_VIEW_PROVISIONED_SUBSCRIBERS],
  userWithPaymentFunctionality: [roleNames.USER_WITH_PAYMENT_FUNCTIONALITY],
  admin: [roleNames.ADMIN],
  selfAdmin: [roleNames.SELF_ADMIN],
  billingAdmin: [roleNames.BILLING_ADMIN],
  support: [roleNames.SUPPORT],
  onlyGuest: [],
};

// Sorted list, first matching role becomes landing page.
export const redirectUrlsByRole = [
  { role: roleNames.USER_WITH_ORDER_PERMISSION, url: '/orders' },
  { role: roleNames.USER_WITH_BILLING_PERMISSION, url: '/billing/summary' },
  { role: roleNames.ADMIN, url: '/admin/permissions' },
  { role: roleNames.BILLING_ADMIN, url: '/billing-admin/accounts' },
  { role: roleNames.SUPPORT, url: '/support/journeys' },
  { role: roleNames.SELF_ADMIN, url: '/admin/permissions' },
];

export default authRoles;
