import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { showMessage } from 'app/store/fuse/messageSlice';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import * as queries from '../../../../graphql/queries';

const logger = new ComponentLogger('PaymentSlice');

export const getIncompletePayments = createAsyncThunk(
  'getIncompletePayments',
  async (params, { dispatch }) => {
    try {
      const response = await API.graphql({
        query: queries.listIncompletePayments,
        variables: params,
      });
      return response.data.listIncompletePayments;
    } catch (error) {
      dispatch(
        showMessage({
          message: `Error occurred while getting incomplete payments!`,
          variant: 'error',
        })
      );
      logger.error('getIncompletePayments', { error });
      throw Error(error.errors[0].errorType);
    }
  }
);

export const invokePayment = createAsyncThunk(
  'invokePayment',
  async (requestData, { dispatch }) => {
    try {
      const response = await API.post('payment-gateway-api', 'invokePayment', {
        body: requestData,
      });
      if (response && response?.message?.payPageLink) {
        return response?.message?.payPageLink;
      }
      dispatch(
        showMessage({
          message:
            'Error occurred while initiating payment! Please try again later or contact system administrator if the issue is still not resolved.',
          variant: 'error',
        })
      );
      return null;
    } catch (error) {
      dispatch(
        showMessage({
          message: `Error occurred while initiating payment!`,
          variant: 'error',
        })
      );
      logger.error('invokePayment', { data: requestData, error });
      throw Error(error.errors[0].errorType);
    }
  }
);

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    payPageLink: null,
    errorMessage: null,
    incompletePayments: null,
    incompletePaymentsLoading: false,
  },
  reducers: {},
  extraReducers: {
    [invokePayment.pending]: (state) => {
      state.payPageLink = null;
      state.errorMessage = null;
    },
    [invokePayment.fulfilled]: (state, action) => {
      state.payPageLink = action.payload;
    },
    [invokePayment.rejected]: (state, action) => {
      state.errorMessage = action.error.message;
    },
    [getIncompletePayments.pending]: (state) => {
      state.incompletePaymentsLoading = true;
    },
    [getIncompletePayments.fulfilled]: (state, action) => {
      state.incompletePayments = action.payload;
      state.incompletePaymentsLoading = false;
    },
    [getIncompletePayments.rejected]: (state, action) => {
      state.incompletePaymentsLoading = false;
    },
  },
});

export const {} = paymentSlice.actions;
export default paymentSlice.reducer;
