import { createSlice } from '@reduxjs/toolkit';
import { Auth } from '@aws-amplify/auth';
import { API } from 'aws-amplify';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import { setUserData } from './userSlice';
import * as queries from '../../../graphql/queries';
import { CURRENT_POLICY_VERSION } from '../../main/policy/Policy';

const logger = new ComponentLogger('LoginSlice');

export async function checkPolicy(email) {
  const val = await API.graphql({
    query: queries.getPolicyLog,
    variables: { username: email },
  }).catch((error) => {
    logger.error('checkPolicy', { data: email, error });
  });
  return val;
}

function route(value, dispatch, user) {
  const payload = value.data.getPolicyLog;

  if (payload === null || (payload && payload.approvedVersion !== CURRENT_POLICY_VERSION)) {
    return dispatch(otpSuccess({ user, step: 'policy', policy: payload }));
  }
  dispatch(setUserData(user));
  return dispatch(otpSuccess({ user, step: 'temp', policy: payload }));
}

export const submitLogin =
  ({ email, password }) =>
  async (dispatch) => {
    return Auth.signIn(email, password)
      .then((user) => {
        if (user.challengeName && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          return dispatch(loginTemporaryPasswordChange(user, email));
        }
        if (user.challengeName === 'SMS_MFA' || user.challengeName === 'MFA_SETUP') {
          return dispatch(userNamePasswordSuccess({ user, password, email, challengeName: user.challengeName }));
        }

        if (user.challengeName === 'SOFTWARE_TOKEN_MFA') {
          return dispatch(userNamePasswordSuccess({ user, password, email, challengeName: user.challengeName }));
        }
        if (
          process.env.NODE_ENV !== 'development' &&
          !user.challengeName &&
          user.preferredMFA === 'NOMFA'
        ) {
          return dispatch(userNamePasswordSuccess({ user, password, email, challengeName: 'NOMFA' }));
        }
        return checkPolicy(email).then((value) => {
          return route(value, dispatch, user);
        });
      })
      .catch((errors) => {
        if (errors.code === 'PasswordResetRequiredException') {
          return dispatch(forgotPasswordSubmit());
        }
        if (errors.code === 'NotAuthorizedException' && errors.message === 'User is disabled.') {
          window.localStorage.clear();
          return dispatch(maintenance());
        }
        if (errors?.name === 'QuotaExceededError') {
          window.localStorage.clear();
          return dispatch(
            loginError({
              type: 'password',
              message: 'Unexpected error occurred. Please re-login.',
            })
          );
        }
        return dispatch(
          loginError({
            type: 'password',
            message: errors.message,
          })
        );
      });
  };

export const submitOTP =
  ({ user, code, challengeName }) =>
  async (dispatch) => {
    return Auth.confirmSignIn(user, code, challengeName)
      .then((loggedUser) => {
        return Auth.currentAuthenticatedUser().then((us) => {
          checkPolicy(us.attributes.email).then((value) => {
            return route(value, dispatch, us);
          });
        });
      })
      .catch((errors) => {
        const error = {
          type: 'code',
          message: errors.message,
        };
        return dispatch(loginError(error));
      });
  };

export const submitTemporaryPasswordChange =
  ({ user, password }) =>
  async (dispatch) => {
    return Auth.completeNewPassword(user, password)
      .then((authenticatedUser) => {
        return dispatch(userNamePasswordSuccess({ user: authenticatedUser, password }));
      })
      .catch((errors) => {
        const error = {
          type: 'password',
          message: errors.message,
        };
        return dispatch(loginError(error));
      });
  };

export const forgotPassword =
  ({ email }) =>
  async (dispatch) => {
    return Auth.forgotPassword(email)
      .then((authenticatedUser) => {
        return dispatch(forgotPasswordConfirmation());
      })
      .catch((errors) => {
        const error = {
          type: 'password',
          message: errors.message,
        };
        return dispatch(loginError(error));
      });
  };

export const forgotPasswordConfirm =
  ({ email, code, password }) =>
  async (dispatch) => {
    return Auth.forgotPasswordSubmit(email, code.toString(), password)
      .then((authenticatedUser) => {
        return dispatch(reLogin());
      })
      .catch((errors) => {
        const error = {
          type: 'password',
          message: errors.message,
        };
        return dispatch(loginError(error));
      });
  };

const initialState = {
  success: false,
  step: 'login',
  errors: [],
  authUser: null,
};

const loginSlice = createSlice({
  name: 'auth/login',
  initialState,
  reducers: {
    userNamePasswordSuccess: (state, action) => {
      state.success = false;
      state.step = action.payload.challengeName || 'NOMFA';
      state.errors = [];
      state.authUser = action.payload;
    },
    otpSuccess: (state, action) => {
      state.success = false;
      state.step = action.payload.step;
      state.tempUserData = action.payload.user;
      state.policy = action.payload.policy;
      state.errors = [];
    },
    policySuccess: (state, action) => {
      state.success = true;
      state.step = 'temp';
      state.errors = [];
    },
    reLogin: (state, action) => {
      state.success = false;
      state.step = 'login';
      state.errors = [];
    },
    loginTemporaryPasswordChange: (state, action) => {
      state.success = false;
      state.step = 'temporary';
      state.authUser = action.payload;
      state.errors = [];
    },
    forgotPasswordSubmit: (state, action) => {
      state.success = false;
      state.step = 'forgotPassword';
      state.errors = [];
    },
    forgotPasswordConfirmation: (state, action) => {
      state.success = false;
      state.step = 'forgotPasswordConfirmation';
      state.errors = [];
    },
    loginError: (state, action) => {
      state.success = false;
      state.errors = action.payload;
    },
    maintenance: (state, action) => {
      state.success = false;
      state.step = 'maintenance';
    },
  },
  extraReducers: {},
});

export const {
  userNamePasswordSuccess,
  otpSuccess,
  policySuccess,
  loginTemporaryPasswordChange,
  forgotPasswordSubmit,
  forgotPasswordConfirmation,
  loginError,
  reLogin,
  maintenance,
} = loginSlice.actions;

export default loginSlice.reducer;
