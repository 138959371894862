import FusePageSimple from '@fuse/core/FusePageSimple';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import _ from '@lodash';

import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PageHeader from '../../shared-components/PageHeader';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
}));
// paymentResult?id=67920231102083910777694&traceid=a42d7519-31a1-4b0f-a05c-53550f753de4&status=success
function PaymentResult(props) {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const paymentId = searchParams.get('id');
  const traceId = searchParams.get('traceid');
  const status = searchParams.get('status');

  return (
    <Root
      header={<PageHeader iconName="settings" title="Payment" noHeaderButton />}
      content={
        <div className="flex flex-auto justify-center w-full max-w-2xl mx-auto p-24 sm:p-32">
          <div className="md:flex">
            <div className="w-full flex flex-col flex-1 md:ltr:pr-32 md:rtl:pl-32">
              <Card className="w-full max-w-512 mb-32">
                <div className="px-32 pt-24 text-center">
                  <Typography className="text-2xl font-semibold leading-tight">
                    Payment Status
                  </Typography>
                </div>
                <div className="px-32 pt-32 text-center">
                  {status === 'success' && (
                    <>
                      <CheckCircleIcon color={status} style={{ fontSize: '6rem' }} />
                      <div>
                        <Typography className="text-lg font-semibold" color="green">
                          Payment Successful
                        </Typography>
                      </div>
                      <div className="mt-16 mb-32">
                        <Typography className="text-md" color="gray">
                          Your payment has been processed. The amount will be credited to your
                          billing account shortly.
                        </Typography>
                      </div>
                      <div className="mt-16 mb-32">
                        <Typography className="text-md" color="gray">
                          Reference ID: {traceId}
                        </Typography>
                      </div>
                    </>
                  )}
                  {status !== 'success' && (
                    <>
                      <CancelIcon color="error" style={{ fontSize: '6rem' }} />
                      <div>
                        <Typography className="text-lg font-semibold" color="error">
                          Payment {_.capitalize(status)}
                        </Typography>
                      </div>
                      <div className="mt-16 mb-32">
                        <Typography className="text-md" color="gray">
                          Reference ID: {traceId}
                        </Typography>
                      </div>
                    </>
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default PaymentResult;
