import { Grid } from '@mui/material';
import { Device } from 'react-mobile-device-frames';
import {
  ChatContainer,
  Chat,
  convertLineStringToParagraph,
  convertUnicodeToEmoji,
  isContentValidForPreview,
  Messages,
  Yours,
  YoursLastMessageAfter,
  YoursLastMessageBefore,
  YoursMessage,
  InvalidPreviewContent,
} from './previewDisplayUtil';

function PreviewTextMessageToSubscriberText(props) {
  return (
    <>
      {props.textMessageToSubscriberText &&
        isContentValidForPreview(props.textMessageToSubscriberText) && (
          <Grid item xs={12} md={7} style={props.style}>
            {props.handsetType && props.handsetType === 'IOS' && (
              <Device device="iphone-13-pro" backgroundImg="assets/images/ios_send.png">
                <div
                  style={{
                    zIndex: 0,
                    maxHeight: '475px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    borderRadius: '10px',
                  }}
                >
                  <div
                    id="header"
                    style={{
                      height: '400px', // set the height of the header
                      display: 'flex',
                      padding: '0px 20px 0px 20px',
                      marginTop: '-15px',
                      justifyContent: 'center',
                      alignItems: 'top',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '1.2rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {props.originatingAddress || ''}
                    </p>
                  </div>
                  <ChatContainer>
                    <Chat>
                      <Messages>
                        <Yours>
                          <YoursMessage className="message">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: convertLineStringToParagraph(
                                  convertUnicodeToEmoji(props.textMessageToSubscriberText),
                                  '1.2rem',
                                  'black'
                                ),
                              }}
                            />
                            <YoursLastMessageBefore />
                            <YoursLastMessageAfter />
                          </YoursMessage>
                        </Yours>
                      </Messages>
                    </Chat>
                  </ChatContainer>
                </div>
              </Device>
            )}

            {props.handsetType && props.handsetType === 'Android' && (
              <Device device="galaxy-s22" backgroundImg="assets/images/android_send_to.png">
                <div
                  style={{
                    zIndex: 0,
                    maxHeight: '475px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    borderRadius: '10px',
                  }}
                >
                  <div
                    id="header"
                    style={{
                      height: '400px', // set the height of the header
                      display: 'flex',
                      padding: '0px 30px 0px 30px',
                      marginTop: '-60px',
                      justifyContent: 'center',
                      alignItems: 'top',
                    }}
                  >
                    <p
                      style={{
                        fontSize: '1.6rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {props.originatingAddress || ''}
                    </p>
                  </div>
                  <ChatContainer>
                    <Chat>
                      <Messages>
                        <Yours>
                          <YoursMessage className="message">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: convertLineStringToParagraph(
                                  convertUnicodeToEmoji(props.textMessageToSubscriberText),
                                  '1.2rem',
                                  'black'
                                ),
                              }}
                            />
                          </YoursMessage>
                        </Yours>
                      </Messages>
                    </Chat>
                  </ChatContainer>
                </div>
              </Device>
            )}
          </Grid>
        )}
      <InvalidPreviewContent content={props.textMessageToSubscriberText} />
    </>
  );
}

export default PreviewTextMessageToSubscriberText;
