import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import FormInputDropdown from 'app/shared-components/FormInputDropdown';
import PreviewDisplay from '../../../../../shared-components/preview/PreviewDisplay';
import { noCurlyBracesPattern } from './formHelper';

/**
 * Form Validation Schema
 */

const schema = yup.object().shape({
  message: yup
    .string()
    .required('You must enter a message')
    .max(500, 'Must be less than 500 character')
    .matches(noCurlyBracesPattern, 'Text should not include curly braces'),
  originatingAddress: yup.object().shape({
    addressString: yup
      .string()
      .required('You must enter originating address')
      .max(11, 'Must not exceed 11 characters')
      .matches(noCurlyBracesPattern, 'Text should not include curly braces'),
  }),
});

function SendTextMessageToSubscriberForm(props) {
  const defaultValues = props.data.data;
  const dispatch = useDispatch();
  const { control, reset, handleSubmit, formState, watch } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const message = watch('message');
  const originatingAddress = watch('originatingAddress');
  const { isValid, dirtyFields, errors } = formState;
  const isOAListProvided =
    !!defaultValues.originatingAddressList && defaultValues.originatingAddressList.length > 0;
  function handleSave(data) {
    dispatch(closeDialog());
    props.onSubmit({ ...props.data, data });
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit(handleSave)}
      className="flex flex-col md:overflow-hidden"
    >
      <DialogContent className="sm:min-w-512">
        <div className="pt-8">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="message"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={
                          (defaultValues.placeholder && defaultValues.placeholder.message) ||
                          'Message'
                        }
                        id="message"
                        error={!!errors.message}
                        helperText={errors?.message?.message}
                        variant="outlined"
                        multiline
                        required
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  {!isOAListProvided ? (
                    <Controller
                      control={control}
                      name="originatingAddress.addressString"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={
                            (defaultValues.placeholder &&
                              defaultValues.placeholder.originatingAddress) ||
                            'Originating Address'
                          }
                          id="originatingAddress.addressString"
                          disabled
                          error={!!errors.originatingAddress}
                          helperText={errors?.originatingAddress?.addressString.message}
                          variant="outlined"
                          multiline
                          required
                          fullWidth
                        />
                      )}
                    />
                  ) : (
                    <FormInputDropdown
                      label={
                        (defaultValues.placeholder &&
                          defaultValues.placeholder.originatingAddress) ||
                        'Originating Address'
                      }
                      id="originatingAddress.addressString"
                      name="originatingAddress.addressString"
                      error={!!errors.originatingAddress}
                      control={control}
                      required
                      options={defaultValues.originatingAddressList.map((val) => ({
                        value: val,
                      }))}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid item>
                <PreviewDisplay
                  textMessageToSubscriberText={message}
                  originatingAddress={originatingAddress && originatingAddress.addressString}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => dispatch(closeDialog())} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          type="submit"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </form>
  );
}

export default SendTextMessageToSubscriberForm;
