import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import Error401Page from 'app/main/401/Error401Page';
import Error404Page from 'app/main/404/Error404Page';
import Policy from 'app/main/policy/Policy';
import LoginConfig from 'app/login/LoginConfig';
import { authRoles } from 'app/auth';
import { Navigate } from 'react-router-dom';
import BillingAdminConfig from '../main/billing-admin/BillingAdminConfig';
import OrdersConfig from '../main/orders/OrdersConfig';
import ReportsConfig from '../main/orders/ReportsConfig';
import BillingConfig from '../main/billing/BillingConfig';
import SupportConfig from '../main/support/SupportConfig';
import AdminConfig from '../main/admin/AdminConfig';
import PaymentConfig from '../main/payment/PaymentConfig';

const routes = [
  // if you want to make whole app auth protected by default change defaultAuth for example:
  // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
  // The individual route configs which has auth option won't be overridden.
  ...FuseUtils.generateRoutesFromConfigs([LoginConfig], authRoles.onlyGuest),
  ...FuseUtils.generateRoutesFromConfigs([OrdersConfig], authRoles.user),
  ...FuseUtils.generateRoutesFromConfigs([ReportsConfig], authRoles.user),
  ...FuseUtils.generateRoutesFromConfigs([BillingConfig], authRoles.userWithBillingPermission),
  ...FuseUtils.generateRoutesFromConfigs([AdminConfig], authRoles.admin),
  ...FuseUtils.generateRoutesFromConfigs([BillingAdminConfig], authRoles.billingAdmin),
  ...FuseUtils.generateRoutesFromConfigs([SupportConfig], authRoles.support),
  ...FuseUtils.generateRoutesFromConfigs([PaymentConfig], authRoles.user),

  {
    path: '/loading',
    exact: true,
    element: () => <FuseLoading />,
  },
  {
    path: '/401',
    element: () => <Error401Page />,
  },
  {
    path: '/404',
    element: () => <Error404Page />,
  },
  {
    path: '/policy',
    element: () => <Policy />,
  },
  {
    element: () => <Navigate to="/404" />,
  },
];

export default routes;
