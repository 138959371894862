import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import history from '@history';
import { showMessage } from '../../../store/fuse/messageSlice';

const invalidateCacheHeader = { headers: { 'Cache-Control': 'max-age=0' } };

export const getProductFamilies = createAsyncThunk(
  'billing/product-families/getProductFamilies',
  async (invalidate = true) => {
    const response = API.get(
      'billing-api',
      'product_family',
      invalidate ? invalidateCacheHeader : {}
    );
    return response;
  }
);
export const getCodes = createAsyncThunk('billing/codes/getCodes', async (invalidate = true) => {
  const response = await API.get('billing-api', 'codes', invalidate ? invalidateCacheHeader : {});
  return response.map((code) => Object.fromEntries(Object.entries(code).filter(([k, v]) => !!v)));
});

export const saveCode = createAsyncThunk('billing/codes/saveCode', async (data, { dispatch }) => {
  const code = Object.fromEntries(Object.entries(data).filter(([k, v]) => !!v));
  try {
    if (!code.id) {
      const response = await API.post('billing-api', 'codes', { body: code });
      dispatch(showMessage({ message: 'Code updated successfully!', variant: 'success' }));
      history.push({
        pathname: `/billing-admin/codes/${response.id}`,
      });
    } else {
      await API.put('billing-api', 'codes', { body: code });
      dispatch(showMessage({ message: 'Code saved successfully!', variant: 'success' }));
    }
  } catch (err) {
    dispatch(showMessage({ message: 'Code already exists!', variant: 'error' }));
  }
});

const codesAdapter = createEntityAdapter({});

export const { selectAll: selectCodes, selectById: selectCodeById } = codesAdapter.getSelectors(
  (state) => state.billing.codes
);

const codesSlice = createSlice({
  name: 'billing/codes',
  initialState: codesAdapter.getInitialState({
    loading: false,
    productFamilies: null,
  }),
  reducers: {},
  extraReducers: {
    [saveCode.pending]: (state) => {
      state.loading = true;
    },
    [saveCode.fulfilled]: (state) => {
      state.loading = false;
    },
    [getCodes.pending]: (state, action) => {
      state.loading = true;
    },
    [getCodes.fulfilled]: (state, action) => {
      state.loading = false;
      codesAdapter.setAll(state, action.payload);
    },
    [saveCode.rejected]: (state, action) => {
      codesAdapter.upsertOne(state, action.payload);
    },
    [getProductFamilies.fulfilled]: (state, action) => {
      state.productFamilies = action.payload;
    },
  },
});

export default codesSlice.reducer;
