import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useState } from 'react';
import { showMessage } from 'app/store/fuse/messageSlice';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  '& .username, & .email': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },

  '& .avatar': {
    background: theme.palette.background.default,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
    bottom: 0,
    '& > img': {
      borderRadius: '50%',
    },
  },
}));

function UserNavbarHeader(props) {
  const user = useSelector(({ auth }) => auth.user);
  const mimickedUser = JSON.parse(localStorage.getItem('mimickedUser'));
  const [isMimicked, setIsMimicked] = useState(Boolean(mimickedUser));
  const dispatch = useDispatch();

  function handleRemoveMimic() {
    localStorage.removeItem('mimickedUser');
    setIsMimicked(false);
    dispatch(showMessage({ variant: 'success', message: 'Mimicked user being removed...' }));
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  }

  return (
    <StyledAppBar
      position="static"
      color="primary"
      className="user relative flex flex-col items-center justify-center pt-24 pb-64 mb-0 z-0 shadow-0"
    >
      <Typography
        className="email text-13 opacity-50 whitespace-nowrap font-medium"
        color="inherit"
      >
        {user.data.email}
      </Typography>
      {isMimicked && (
        <div className="flex flex-row items-center">
          <h3 className="text-sm text-gray-400 font-semibold text-center">
            {mimickedUser.permissions[0].username} (mimicked)
          </h3>
          <IconButton size="small" onClick={handleRemoveMimic}>
            <Close fontSize="small" />
          </IconButton>
        </div>
      )}
    </StyledAppBar>
  );
}

export default UserNavbarHeader;
