import { styled } from '@mui/material/styles';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import withReducer from '../../../store/withReducer';
import reducer from '../store';
import PageHeader from '../../../shared-components/PageHeader';
import { getContracts, openNewContractDialog, selectContracts } from '../store/contractsSlice';
import CustomDataGrid from '../../../shared-components/CustomDataGrid';
import BillingSpinner from '../BillingSpinner';
import ContractDialog from './ContractDialog';
import { isUserBillingAdmin } from '../../../services/permissionUtil';
import { getMnos, selectMnos } from '../../support/store/mnosSlice';
import { filterByMnoStatus } from '../utils';

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 136,
      height: 136,
    },
  },
  '& .FusePageCarded-contentCard': {
    overflow: 'hidden',
  },
}));

const columns = [
  { field: 'id', headerName: 'ID', width: 150 },
  { field: 'mno', headerName: 'MNO', flex: 1 },
  { field: 'name', headerName: 'Name', flex: 1 },
];

function Contracts(props) {
  const dispatch = useDispatch();
  const mnos = useSelector(selectMnos);
  const contracts = filterByMnoStatus(mnos, useSelector(selectContracts));
  const { loading } = useSelector(({ billing }) => billing.contracts);
  const user = useSelector(({ auth }) => auth.user);
  const isBillingAdmin = isUserBillingAdmin(user);

  useEffect(() => {
    dispatch(getContracts());
    dispatch(getMnos());
  }, [dispatch]);

  function onRowClick({ row }) {
    if (isBillingAdmin) dispatch(openNewContractDialog({ type: 'edit', data: row }));
  }

  return (
    <>
      <Root
        header={
          <PageHeader
            iconName="edit"
            title="Billing Contracts"
            buttonName="New Contract"
            noHeaderButton={!isBillingAdmin}
            onButtonClick={() => dispatch(openNewContractDialog({ type: 'new' }))}
          />
        }
        content={
          loading ? (
            <FuseLoading />
          ) : (
            <CustomDataGrid
              onRowClick={onRowClick}
              name="contracts"
              rows={contracts}
              columns={columns}
              onRefresh={() => dispatch(getContracts())}
              density="compact"
            />
          )
        }
        innerScroll
      />
      <ContractDialog />
      <BillingSpinner />
    </>
  );
}

export default withReducer('billing', reducer)(Contracts);
