import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import InputAdornment from '@mui/material/InputAdornment';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from 'app/auth/store/loginSlice';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import _ from '@lodash';
import Typography from '@mui/material/Typography';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email('You must enter a valid email').required('You must enter a email'),
});

const defaultValues = {
  email: '',
};

function ForgotPasswordForm(props) {
  const dispatch = useDispatch();
  const login = useSelector(({ auth }) => auth.login);

  const { control, setValue, formState, handleSubmit, reset, trigger, setError } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;


  useEffect(() => {
    if (login.errors && login.errors.type) {
      setError(login.errors.type, {
        type: 'manual',
        message: login.errors.message,
      });
    }
  }, [login.errors, setError]);

  function onLoginSubmit(model) {
    dispatch(forgotPassword(model));
  }


  return (
    <div className="w-full">
      <Typography variant="h6" className="mt-16 mb-24 font-semibold text-18 sm:text-24">
        Forgot my password
      </Typography>
      <form
        className="flex flex-col justify-center w-full"
        onSubmit={handleSubmit(onLoginSubmit)}
      >
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              className="mb-16"
              type="text"
              label="Email"
              error={!!errors.email}
              helperText={errors?.email?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon className="text-20" color="action">
                      email
                    </Icon>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              required
            />
          )}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-full mx-auto mt-16"
          aria-label="LOG IN"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          value="cognito"
        >
          Send verification code
        </Button>
      </form>
    </div>
  );
}

export default ForgotPasswordForm;
