import { configureStore, isRejected, createListenerMiddleware } from '@reduxjs/toolkit';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import createReducer from './rootReducer';
import { logoutUser } from '../auth/store/userSlice';
import { showMessage } from './fuse/messageSlice';

const logger = new ComponentLogger('AppStore');

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default;
    store.replaceReducer(newRootReducer.createReducer());
  });
}

const errorList = [
  'No current user',
  'Cannot retrieve a new session. Please authenticate.',
  'Local storage is missing an ID Token, Please authenticate',
  'Refresh Token has expired',
];

export function throwErrorIfHandledByMiddleware(error) {
  if (errorList.includes(error.message)) throw error;
}

const middlewares = [];
const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  matcher: isRejected,
  effect: async (action, listenerApi) => {
    if (action.error && errorList.includes(action.error.message)) {
      listenerApi.dispatch(
        showMessage({
          message: 'User session expired. Please re-login.',
          variant: 'error',
          autoHideDuration: null,
        })
      );
      logger.info('rejectedListenerMiddleware', { message: 'User session expired' });
      listenerApi.dispatch(logoutUser());
    }
  },
});

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require(`redux-logger`);
  const logger = createLogger({ collapsed: (getState, action, logEntry) => !logEntry.error });

  middlewares.push(logger);
}

middlewares.push(listenerMiddleware.middleware);

const store = configureStore({
  reducer: createReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: process.env.NODE_ENV === 'development',
});

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
  if (store.asyncReducers[key]) {
    return false;
  }
  store.asyncReducers[key] = reducer;
  store.replaceReducer(createReducer(store.asyncReducers));
  return store;
};

export default store;
