import { authRoles } from 'app/auth';
import Attributes from './attributes/Attributes';
import Journey from './journey/Journey';
import Journeys from './journey/Journeys';
import Templates from '../admin/template/Templates';
import Template from '../admin/template/Template';
import Platforms from '../billing-admin/platform/Platforms';
import Mnos from './mnos/Mnos';
import ApiRequests from './api-requests/ApiRequests';

const SupportConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.support,
  routes: [
    {
      path: '/support/system-attributes',
      element: <Attributes isSystem />,
    },
    {
      path: '/support/journey/:journeyId',
      element: <Journey />,
    },
    {
      path: '/support/journeys',
      element: <Journeys />,
    },
    {
      path: '/support/mno',
      element: <Mnos />,
    },
    {
      path: '/support/template/:templateId',
      element: <Template />,
    },
    {
      path: '/support/templates',
      element: <Templates />,
    },
    {
      path: '/support/platforms',
      element: <Platforms />,
    },
    {
      path: '/support/api-requests',
      element: <ApiRequests />,
    },
  ],
};

export default SupportConfig;
