import { API } from 'aws-amplify';
import { useState, useEffect } from 'react';
import * as queries from '../../../../../../../graphql/queries';

export function useValidateProduct(productId, data) {
  const [apiStatus, setApiStatus] = useState( { verified: false, description: '', data: null });

  useEffect(() => {
    console.log('useValidateProduct', productId, data);
    async function handleValidate() {
      if (!productId) return;
      setApiStatus({ description: 'Validating, Please wait...', data: null });
      const res = await validateRemoteAPI(data.name, productId);
      setApiStatus(res);
    }

    async function validateAPI() {
      await handleValidate();
    }
    validateAPI();
  }, [productId, data]);

  return apiStatus;
}

export const validateRemoteAPI = async function (api, serviceId) {
  try {
    const response = await API.graphql({
      query: queries.validateAPI,
      variables: {
        api,
        serviceId,
      },
    });
    const result = response.data.validateAPI;

    return {
      verified: result?.statusCode === 200,
      description: result.description || '',
      data: result.data,
    };
  } catch (error) {
    console.log(error);
    return { verified: false, description: 'Unable to validate service!', data: null };
  }
};
