import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormInputDropdown from './FormInputDropdown';
import FuseLoading from '../../@fuse/core/FuseLoading';
import { getContracts, selectContracts } from '../main/billing-admin/store/contractsSlice';

function FormContractDropdown({ mno, ...props }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContracts());
  }, [dispatch]);

  const contracts = useSelector(selectContracts);
  const [loading, setLoading] = useState(true);
  const [availableContracts, setAvailableContracts] = useState([]);


  useEffect(() => {
    if(contracts.length > 0){
      setAvailableContracts(
        contracts
          .filter((contract) => contract.mno === mno)
          .sort((c1, c2) => c1.name.localeCompare(c2.name))
          .map((contract) => {
            return {
              value: contract.id,
              label: contract.name
            };
          })
      );
      setLoading(false);
    }
  }, [mno, contracts]);

  if (loading) {
    return <FuseLoading />;
  }

  return <FormInputDropdown {...props} options={availableContracts} />;
}

export default FormContractDropdown;
