import { Grid, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import _ from '@lodash';
import { DEPResultMap, DSMResultMap, JourneyResultMap } from '../../services/constants';

const CLIP_THRESHOLD = 30;

function StatsContent({ stats, stepType, ...props }) {
  const shortenLabels = (data) => {
    Object.entries(JourneyResultMap).forEach(([key, value]) => {
      data = data.replace(key, value.name);
    });
    return data;
  };

  // Remove REQUEST_SENT on step results if exists (due to old stats structure),
  // and generate PROCESSED stat based on sum of all results
  if (stats.REQUEST_SENT) delete stats.REQUEST_SENT;
  if (!stats.PROCESSED) stats.PROCESSED = Object.values(stats).reduce((a, b) => a + b, 0);

  const statList = Object.entries(stats)
    .map(([statKey, statValue]) => {
      if (
        ['RemindMeLaterStep', 'SelectFromMenuStep', 'DsmDoiStep'].includes(stepType) &&
        statKey.includes('RESPONSE_RECEIVED_SUCCESS')
      ) {
        return {
          name: shortenLabels(statKey),
          value: statValue.toLocaleString(),
          orderBy: JourneyResultMap.RESPONSE_RECEIVED_SUCCESS.orderBy,
        };
      }
      if (stepType === 'CallbackStep' || stepType === 'DsmDoiStep') {
        return {
          name: DSMResultMap[statKey] ? DSMResultMap[statKey].name : statKey,
          value: statValue.toLocaleString(),
          orderBy: DSMResultMap[statKey] ? DSMResultMap[statKey].orderBy : 999,
        };
      }
      if (stepType === 'ServiceCallStep') {
        return {
          name: DEPResultMap[statKey] ? DEPResultMap[statKey].name : statKey,
          value: statValue.toLocaleString(),
          orderBy: DEPResultMap[statKey] ? DEPResultMap[statKey].orderBy : 999,
        };
      }
      return {
        name: JourneyResultMap[statKey] ? JourneyResultMap[statKey].name : statKey,
        value: statValue.toLocaleString(),
        orderBy: JourneyResultMap[statKey] ? JourneyResultMap[statKey].orderBy : 999,
      };
    })
    .sort((n1, n2) => {
      const count = n1.orderBy - n2.orderBy;
      if (count) return count;
      return parseInt(n2.value, 10) - parseInt(n1.value, 10);
    });

  return (
    <Grid container>
      {stats &&
        statList.map((stat) => {
          const formattedStatKey = _.startCase(_.lowerCase(stat.name));
          return (
            <Grid key={stat.name} container className="align-middle" spacing={1}>
              <Grid item>
                {formattedStatKey.length > CLIP_THRESHOLD ? (
                  <Tooltip title={formattedStatKey} placement="top">
                    <Typography variant="body2">
                      {formattedStatKey.slice(0, 30)}
                      {formattedStatKey.length > 30 ? '...' : ''}:{' '}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography variant="body2">{formattedStatKey}: </Typography>
                )}
              </Grid>
              <Grid item>
                <Typography variant="body2">{stat.value}</Typography>
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
}

export default StatsContent;
