import { Controller } from 'react-hook-form';
import { Checkbox, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import _ from '@lodash';

function FormInputDropdown({
  name,
  label,
  control,
  options,
  transform,
  customHandler,
  required,
  sorted = true,
  ...props
}) {
  if (sorted && options && options.length) {
    options.sort((a, b) => {
      if (a.label && b.label) {
        return _.toLower(a.label) < _.toLower(b.label) ? -1 : 1;
      }
      return _.toLower(a.value) < _.toLower(b.value) ? -1 : 1;
    });
  }

  if (!required) {
    options = [{ value: '', label: 'None' }].concat(options);
  }

  const renderOption = (option, value) => {
    if (props.multiple)
      return (
        <>
          <Checkbox checked={!!value && Array.isArray(value) && value.indexOf(option.value) > -1} />
          <ListItemText primary={option.label} />
        </>
      );

    return option.label !== undefined ? option.label : option.value;
  };
  const generateSelectOptions = (value) => {
    return options.map((option) => (
      <MenuItem
        key={option.value.id || option.value}
        value={option.value}
        data-testid={option.value}
      >
        {renderOption(option, value)}
      </MenuItem>
    ));
  };

  const addMultipleProps = (value) => {
    if (props.multiple) {
      return {
        multiple: true,
        renderValue: () =>
          value.map((v) => options.find((i) => i.id === v || i.value === v).label).join(', '),
      };
    }
    return {};
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <FormControl fullWidth {...props}>
          <InputLabel id={`select-${name}`}>{`${label}${label && required ? '*' : ''}`}</InputLabel>
          <Select
            onChange={(e) => {
              onChange(e.target.value === '' ? null : e);
              if (customHandler) customHandler(e);
            }}
            required={required}
            value={value === null ? (value = '') : value}
            labelId={`select-${name}`}
            label={`${label}${label && required ? '*' : ''}`}
            {...addMultipleProps(value)}
          >
            {generateSelectOptions(value)}
          </Select>
          {!!error && <FormHelperText error={!!error}>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}

export default FormInputDropdown;
