import { styled } from '@mui/material/styles';
import { Dialog, DialogActions, DialogContent, DialogTitle, Link, Modal } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import {Helmet} from "react-helmet";
import { savePolicyLog } from './store/policySlice';
import FusePageCarded from '../../../@fuse/core/FusePageCarded/FusePageCarded';
import { logoutUser, setUserData } from '../../auth/store/userSlice';
import { loginError, policySuccess, reLogin } from '../../auth/store/loginSlice';

export const CURRENT_POLICY_VERSION = "v1.0";

const Root = styled(FusePageCarded)(({ theme }) => ({
  '& .FusePageCarded-header': {
    minHeight: 72,
    height: 72,
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 136,
      height: 136,
    },
  },
  '& .FusePageCarded-content': {
    display: 'flex',
  },
  '& .FusePageCarded-contentCard': {
    overflow: 'hidden',
  },
}));

const iframestyle = {
  background: 'whitesmoke',
  border: '20px solid #c8c8c8',
  borderStyle: "solid",
  width: '50%',
  height: '825px',
  marginTop: '30px',
  boxShadow: 24,
  display: "block",
};

const Policy = ( {readOnly} = false) => {
  const dispatch = useDispatch();
  const { tempUserData } = useSelector(({ auth }) => auth.login);
  const { policy } = useSelector(({ auth }) => auth.login);


  const [openTos, setOpenTos] = useState(false);
  const handleOpenTos = () => {
    setOpenTos(true)
  };
  const handleCloseTos = () => {
    setOpenTos(false);
  };
  const [openAup, setOpenAup] = useState(false);
  const handleOpenAup = () => {
    setOpenAup(true);
  };
  const handleCloseAup = () => {
    setOpenAup(false);
  };
  const [openPp, setOpenPp] = useState(false);
  const handleOpenPp = () => {
    setOpenPp(true);
  };
  const handleClosePp = () => {
    setOpenPp(false);
  };

  function ChildModalTermsOfService() {
    return (
      <>
        <Modal
          open={openTos}
          onClose={handleCloseTos}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <center>
            <div style={ iframestyle }>
              <iframe title="OnePIN Terms of Service" src="https://fdpolicy.s3.amazonaws.com/OnePIN_Terms_of_Service_20230327.html" width="100%" height="720px" frameBorder="10"/>
              <br/>
              <Button onClick={handleCloseTos} color="error" variant="outlined">Close</Button>
            </div>
          </center>
        </Modal>
      </>
    );
  }


  function ChildModalUsagePolicy() {
    return (
      <>
        <Modal
          open={openAup}
          onClose={handleCloseAup}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <center>
            <div style={ iframestyle }>
              <iframe title="OnePIN Usage Policy" src="https://fdpolicy.s3.amazonaws.com/OnePIN_Acceptable_Usage_Policy_20230327.html" width="100%" height="720px" frameBorder="10" />
              <br/>
              <Button onClick={handleCloseAup}  color="error" variant="outlined">Close</Button>
            </div>
          </center>
        </Modal>
      </>
    );
  }


  function ChildModalPrivacyPolicy() {
    return (
      <>
        <Modal
          open={openPp}
          onClose={handleClosePp}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <center>
            <div style={ iframestyle }>
              <iframe title="OnePIN Privacy Policy" src="https://fdpolicy.s3.amazonaws.com/OnePIN_Privacy_Policy_20230327.html" width="100%" height="720px" frameBorder="10"/>
              <br/>
              <Button onClick={handleClosePp} color="error" variant="outlined">Close</Button>
            </div>
            </center>
        </Modal>
      </>
    );
  }



  const [isOpen, setIsOpen] = useState(true);

  const togglePopup = () => {
    setIsOpen(!isOpen);
    dispatch(setUserData(tempUserData));
    dispatch(savePolicyLog({ username: tempUserData.attributes.email, approvedVersion: CURRENT_POLICY_VERSION, currentPolicy: policy}));
    return dispatch(policySuccess());
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick")
      return;
    setIsOpen(!isOpen);

    if(!readOnly){
      dispatch(logoutUser());
      dispatch(loginError({ type: 'password',message: "You need to accept the Policy!" }))
      dispatch(reLogin());
    }
  }

  return (
  <div>
    <Helmet>
      <base target="_blank"/>
    </Helmet>
      <Dialog open={isOpen} onClose={handleClose}  aria-labelledby="policy-dialog-title">
        
        <DialogTitle id="policy-dialog-title">Privacy Policy</DialogTitle>
        <DialogContent>
          <p>
            By using the OnePIN Portal and the services, you agree to:
            <br/><br/>
            <Link onClick={handleOpenTos} component="button" color="secondary" variant="subtitle2">Terms of Service</Link>
            <br/><br/>
            <Link onClick={handleOpenAup} component="button" color="secondary" variant="subtitle2">Acceptable Use Policy</Link>
            <br/><br/>
            <Link onClick={handleOpenPp} component="button" color="secondary" variant="subtitle2">Privacy Policy</Link>
            <br/><br/>
            I agree with OnePIN’s Terms of Service, Acceptable Use Policy, and Privacy Policy
          </p>
          <p>
            OnePIN may update the above policies and agreements from time to time and by using the service you consent to and agree to be bound by the revised policies and agreements. If you do not agree to the revised policies and agreements, do not use the services.
            <br/><br/>
            If you are using the OnePIN Portal and/or Services in your capacity as employee or agent of an entity, then any references to “you” in the Acceptable Use Policy and Terms of Service shall refer to such entity and not to you in your personal capacity. You warrant that you are authorized to legally bind the company or organization on whose behalf you are accessing the OnePIN Portal and/or Services. If you are not so authorized, then neither you nor your company or organization may use the OnePIN Portal or Services in any manner whatsoever.
            <br/><br/>
            I agree to provide my personal information to OnePIN in conjunction with the use of the OnePIN Portal and Services.  I also agree to allow OnePIN to monitor my usage of the OnePIN Portal and Services and send messages to me.
            <br/><br/>
            I consent to receiving emails related to use of the OnePIN Portal and Services.  I also consent to receiving SMS messages to my mobile number for the purpose of providing Multi-Factor Authentication, (MFA). I further consent to OnePIN’s use of my personal information in accordance with the OnePIN Privacy Policy.
          </p>
          <ChildModalPrivacyPolicy/>
          <ChildModalTermsOfService/>
          <ChildModalUsagePolicy/>
        </DialogContent>

          <DialogActions>
          {
            !readOnly ? (
            <Button onClick={togglePopup} color="secondary" variant="contained" autoFocus>Accept</Button>
            ) : (<br/>)
          }
            <Button onClick={handleClose} color="error" variant="outlined">Close</Button>
          </DialogActions>
      </Dialog>
    </div>
  );
};

export default Policy;
