import { styled } from '@mui/material/styles';
import FusePageCarded from '@fuse/core/FusePageCarded';
import { Grid, Typography, Box } from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { JsonEditor as Editor } from 'jsoneditor-react';
import ace from 'brace';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Error404Page from '../../404/Error404Page';
import withReducer from '../../../store/withReducer';
import reducer from '../store';
import * as templatesSlice from '../store/templatesSlice';
import TemplateHeader from './TemplateHeader';
import 'jsoneditor-react/es/editor.min.css';
import { getTemplates } from '../store/templatesSlice';
import 'brace/mode/json';
import 'brace/theme/github';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import FormMnoDropdown from '../../../shared-components/FormMnoDropdown';
import FormInputText from '../../../shared-components/FormInputText';
import FormInputDropdown from '../../../shared-components/FormInputDropdown';
import * as yup from 'yup';
import { getJourneys, selectJourneys } from '../../support/store/journeysSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import AlertDialog from '../../../shared-components/AlertDialog';
import FormSwitch from 'app/shared-components/FormSwitch';
import findTemplateVariables from './templateHelper';

const Root = styled(FusePageCarded)({
  '& .FusePageCarded-header': {},
  '& .FusePageCarded-toolbar': {},
  '& .FusePageCarded-content': {},
  '& .FusePageCarded-sidebarHeader': {},
  '& .FusePageCarded-sidebarContent': {},
});

const defaultValues = {
  mno: '',
  product: '',
  name: '',
  journeyID: '',
  variables: [],
};

function Template(props) {
  const { templateId } = useParams();
  const navigate = useNavigate();
  const { loading } = useSelector(({ admin }) => admin.templates);
  const templates = useSelector(templatesSlice.selectTemplates);
  const template = useSelector((state) => templatesSlice.selectTemplateByID(state, templateId));
  const journeys = useSelector(selectJourneys);
  const productList = process.env.REACT_APP_CAMPAIGN_GROUP.split(',');

  const dispatch = useDispatch();
  const [jsonData, setJsonData] = useState(null);

  const schema = yup.object().shape({
    mno: yup.string().required('You must select an mno'),
    name: yup
      .string()
      .required('You must enter a name')
      .max(100, 'Must be less than 100 character'),
    product: yup.string().required('You must select an product'),
    journeyID: yup.string().required('You must select a journey'),
    variables: yup
      .mixed()
      .test(
        'is-valid-array',
        'Array must either be empty or contain objects with valid path and value',
        (array) => array.length === 0 || array.every((item) => item.path && item.value)
      ),
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    control,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = methods;

  const { fields } = useFieldArray({
    control,
    name: 'variables',
  });

  const jsonEditorRef = useRef(null);

  useEffect(() => {
    if (jsonEditorRef.current !== null) {
      jsonEditorRef.current.set(jsonData);
    }
  }, [jsonData]);

  const setRef = (instance) => {
    if (instance) {
      jsonEditorRef.current = instance.jsonEditor;
    } else {
      jsonEditorRef.current = null;
    }
  };

  useEffect(() => {
    dispatch(getTemplates());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getJourneys());
  }, [dispatch]);

  useEffect(() => {
    // view only
    if (template) {
      reset(template);
      const data = {
        ...template,
        journey: {
          ...template.journey,
          steps: JSON.parse(template.journey.steps),
          tags: JSON.parse(template.journey.tags),
          billing: JSON.parse(template.journey.billing),
          metrics: JSON.parse(template.journey.metrics),
        },
      };
      setJsonData(data);
    } else if (templateId === 'new') {
      setJsonData(null);
    }
  }, [template, templateId, dispatch]);

  if (loading) {
    return <FuseLoading />;
  }

  if (!template && templateId !== 'new') {
    return <Error404Page />;
  }

  function handleSaveTemplate() {
    const newTemplate = getValues();
    dispatch(templatesSlice.saveTemplate(newTemplate)).then((result) =>
      navigate('/support/templates')
    );
  }
  function handleJsonDataChange(param) {
    setJsonData(param);
  }

  function handleJourneyChange(event) {
    const data = journeys.find((journey) => journey.id === event.target.value);
    setJsonData({
      ...data,
      steps: JSON.parse(data.steps),
      tags: JSON.parse(data.tags),
      billing: JSON.parse(data.billing),
      metrics: JSON.parse(data.metrics),
    });
    setValue('variables', findTemplateVariables(JSON.parse(data.steps)));
  }

  function saveTemplate() {
    const newTemplate = getValues();
    const existingTemplate = templates.find(
      (template) =>
        template.mno === newTemplate.mno &&
        template.product === newTemplate.product &&
        template.journeyID === newTemplate.journeyID
    );
    if (templates && existingTemplate && !newTemplate.id) {
      dispatch(
        openDialog({
          children: (
            <AlertDialog
              title={`Template is already defined with id: (${existingTemplate.id}) and name: (${existingTemplate.name})! Are you sure want to add a new template?`}
              okButtonName="Yes, add new template"
              cancelButtonName="No"
              handleOk={handleSaveTemplate}
              handleCancel={() => navigate('/support/templates')}
              okButtonColor="error"
            />
          ),
        })
      );
    } else {
      handleSaveTemplate();
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <Root
          header={<TemplateHeader template={template} onButtonClick={saveTemplate} />}
          content={
            <Grid container direction="column" alignItems="center" spacing={3} p={3}>
              <Grid item container xs={3} spacing={3}>
                <Grid item container xs={12} md={4} spacing={3}>
                  <Grid item xs={12}>
                    <FormMnoDropdown
                      name="mno"
                      label="MNO"
                      control={control}
                      required
                      noPermissionFiltering
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormInputDropdown
                      name="product"
                      label="Product"
                      control={control}
                      options={productList.map((product) => ({ value: product }))}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputText name="name" label="Name" control={control} required />
                  </Grid>
                  <Grid item xs={12}>
                    <FormInputDropdown
                      required
                      name="journeyID"
                      label="Journey"
                      control={control}
                      customHandler={handleJourneyChange}
                      options={journeys.map((journey) => ({
                        label: journey.name,
                        value: journey.id,
                      }))}
                    />
                  </Grid>
                  {fields.length > 0 && (
                    <>
                      <Grid item xs={12}>
                        <Typography component='div'>
                          <Box sx={{ fontSize: 'body1.fontSize', fontWeight: 'bold', mt: 2 }}>Additional Variables</Box>
                        </Typography>
                      </Grid>
                      {fields.map((field, index) => (
                        <Grid item xs={12} key={field.id}>
                          <FormInputText
                            name={`variables[${index}].value`}
                            label={field?.path || `Variable ${index + 1}`}
                            control={control}
                            errors={errors}
                            required
                          />
                        </Grid>

                      ))}
                    </>
                  )}

                  <Grid item xs={12}>
                    <FormSwitch label="Disabled" name="isDisabled" control={control} />
                  </Grid>
                </Grid>

                <Grid item xs={12} md={8}>
                  {jsonData && (
                    <div>
                      <Editor
                        mode="view"
                        value={jsonData}
                        ref={setRef}
                        ace={ace}
                        statusBar
                        theme="ace/theme/github"
                        onChange={handleJsonDataChange}
                        htmlElementProps={{
                          style: {
                            height: 500,
                          },
                        }}
                      />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
          }
          innerScroll
        />
      </FormProvider>
    </>
  );
}

export default withReducer('admin', reducer)(Template);
