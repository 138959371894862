import { Button, DialogActions, DialogContent, Grid, Tooltip } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useDebouncedValue from '@fuse/hooks/useDebouncedValue';
import { DSM_SERVICE_LABELS } from 'app/services/constants';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { useValidateProduct } from './utils';

function DsmApiCallForm(props) {
  const defaultValues = {
    ...props.data.data.variables,
  };
  const { placeholder } = props.data.data;
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    productId: yup.string().required('Product ID is required'),
  });

  const { control, handleSubmit, formState, watch, setValue, getValues, trigger } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const productId = useDebouncedValue(watch('productId'), 500);
  const { isValid, dirtyFields, errors } = formState;
  const apiStatus = useValidateProduct(productId, props.data.data);

  function handleSave() {
    if (!apiStatus.verified) return;
    dispatch(closeDialog());
    const values = JSON.parse(apiStatus.data ?? '{}');

    const variables = {
      serviceId: productId,
      subscriptionId: values.serviceId,
      nodeId: values.aggregatorId,
      productName: values.productName,
    };

    const res = {
      ...props.data,
      data: {
        ...props.data.data,
        variables,
      },
    };
    props.onSubmit(res);
  }

  return (
    <form
      noValidate
      onSubmit={handleSubmit(handleSave)}
      className="flex flex-col md:overflow-hidden"
    >
      <DialogContent className="sm:min-w-512">
        <div className="pt-8">
          <Tooltip
            title={
              <Typography component="div" sx={{ fontSize: '11px' }}>
                {apiStatus.data
                  ? Object.entries(JSON.parse(apiStatus.data)).map(([key, value]) => {
                      return (
                        Object.keys(DSM_SERVICE_LABELS).includes(key) && (
                          <div sx={{ fontSize: 'inherit' }} key={key}>
                            {DSM_SERVICE_LABELS[key]}: {value}
                          </div>
                        )
                      );
                    })
                  : apiStatus.description}
              </Typography>
            }
            placement={
              apiStatus.description === 'Validating, Please wait...' ? 'top-start' : 'top-end'
            }
            open={apiStatus.description !== ''}
            style={{ maxWidth: '500px', marginTop: '1.5rem' }}
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: !apiStatus.verified ? 'red' : 'common.black',
                  '& .MuiTooltip-arrow': {
                    color: !apiStatus.verified ? 'red' : 'common.black',
                  },
                },
              },
            }}
          >
            <Grid key="productId" item>
              <Controller
                control={control}
                name="productId"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={placeholder?.productId || 'Add your product ID here.'}
                    id="productId"
                    type="text"
                    error={!!errors.productId}
                    helperText={errors?.productId?.message}
                    variant="outlined"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>
          </Tooltip>
        </div>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={() => dispatch(closeDialog())} color="primary">
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={_.isEmpty(dirtyFields) || !isValid || !apiStatus.verified}
          type="submit"
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
    </form>
  );
}

export default DsmApiCallForm;
