import _ from '@lodash';
import { OrderStatus, PRODUCT_FAMILIES_FOR_ORDERS } from './constants';
import { convertColumnFieldToQueryField, convertFilterToGraphQL } from './dataGridUtil';

const keyList = [
  'mno',
  'billingCode',
  'billingAccount',
  'campaignTemplate',
  'ownerId',
  'permission',
  'username',
];

const FILTER_MAP = {
  mno: { permissionName: 'mno', queryName: 'mno', gridName: 'mno' },
  billingAccount: {
    permissionName: 'billingAccount',
    queryName: 'billingAccountId',
    gridName: 'billingAccountId',
  },
  template: {
    permissionName: 'campaignTemplate',
    queryName: 'templateId',
    gridName: 'templateId',
  },
  product: {
    permissionName: 'productFamily',
    queryName: 'product',
    gridName: 'product',
  },
};

function convertToMUIFilterItem(filterItem, modelItems, key, id) {
  if (filterItem && filterItem.length > 1) {
    modelItems.push({ columnField: key, value: filterItem, operatorValue: 'isAnyOf', id });
  } else if (filterItem && filterItem.length === 1) {
    modelItems.push({ columnField: key, value: filterItem[0], operatorValue: 'is', id });
  }
}
function flattenPermissionItem(permissions, permissionType) {
  let filter = permissions.map((permission) => permission[permissionType]).flat();
  if (filter.includes('*')) {
    filter = [];
  }
  return filter;
}
function flattenChildPermissionItem(permissions, permissionType, dataList) {
  let permissionList = permissions.map((permission) => permission[permissionType]).flat();
  if (
    permissionList.includes('*') &&
    permissionList.filter((permissionItem) => permissionItem === '*').length ===
      permissionList.length
  ) {
    return [];
  }
  if (permissionList.includes('*')) {
    const mnoPermission = permissions.find((permission) =>
      permission[permissionType].includes('*')
    ).mno;
    if (mnoPermission === '*') {
      return [];
    }
    permissionList = permissionList.filter((item) => item !== '*');
    const newList = dataList
      .filter((data) => mnoPermission.includes(data.mno))
      .map((data) => data.id)
      .flat();
    newList.push(...permissionList);
    return newList;
  }
  return permissionList;
}

function isEligible(permissions, keyword) {
  return !permissions
    .map((permission) => permission[keyword])
    .flat()
    .some((element) => element !== '*');
}

function addDraft(searchQuery, permissions) {
  const { and } = searchQuery;
  const original = _.cloneDeep(searchQuery);
  const draft = _.cloneDeep(searchQuery);

  const draftStatus = { status: { eq: OrderStatus.DRAFT.name } };
  const ownerIdFilter = { ownerId: { eq: permissions[0].username } };

  if (Array.isArray(and)) {
    original.and.push({ status: { ne: OrderStatus.DRAFT.name } });
    draft.and.push(draftStatus, ownerIdFilter);
  } else if (typeof and === 'object') {
    original.and = [and, { status: { ne: OrderStatus.DRAFT.name } }];
    draft.and = [and, draftStatus, ownerIdFilter];
  } else {
    original.and = [{ status: { ne: OrderStatus.DRAFT.name } }];
    draft.and = [draftStatus, ownerIdFilter];
  }

  return {
    or: [original, draft],
  };
}

function filterPermissionsBySelectedProducts(selectedProducts, permissions, selectedMnoList) {
  if (
    permissions
      .map((permission) => permission.mno)
      .flat()
      .includes('*')
  ) {
    const options = [];
    if (selectedMnoList && selectedMnoList.length > 0) {
      selectedMnoList.forEach((mno) =>
        selectedProducts.forEach((productFamily) => options.push(`${mno}:${productFamily}`))
      );
    }
    return options;
  }

  return permissions
    .map((permission) => permission[FILTER_MAP.product.permissionName])
    .flat()
    .filter((item) => {
      const [mno, product] = item.split(':');
      return selectedProducts.includes(product);
    });
}

function generateProductFilters(filter, productFilters, permissions, mnoFilters, filterList) {
  const productFreeFilters = [...filter.items].filter(
    (item) => item.columnField !== FILTER_MAP.product.queryName
  );

  productFilters.forEach((productFilter) => {
    const result = [];
    const { value } = productFilter;

    if (value !== null && value !== undefined) {
      const valueList = [];
      Array.isArray(value) ? valueList.push(...value) : valueList.push(value);
      result.push(filterPermissionsBySelectedProducts(valueList, permissions, mnoFilters));
    }
    filterList.push({ ...productFilter, value: result.flat() });
  });
  filterList.push(...productFreeFilters);
}

function getMandatoryFilter(filter, keyword) {
  return filter.items
    .filter((item) => item.columnField === FILTER_MAP[keyword].queryName)
    .filter((item) => {
      if (Array.isArray(item.value)) {
        return item.value.length > 0;
      }
      if (typeof item.value === 'string') {
        return item.value.trim() !== '';
      }
      return false;
    });
}

export default {
  FILTER_MAP,

  convertPermissionsToMuiFilter(permissions, accounts, templates) {
    const modelItems = [];
    const mnoFilter = flattenPermissionItem(permissions, FILTER_MAP.mno.permissionName);
    const billingAccountFilter = flattenChildPermissionItem(
      permissions,
      FILTER_MAP.billingAccount.permissionName,
      accounts
    );
    const campaignTemplateFilter = flattenChildPermissionItem(
      permissions,
      FILTER_MAP.template.permissionName,
      templates
    );
    convertToMUIFilterItem(mnoFilter, modelItems, FILTER_MAP.mno.gridName, 10000);
    convertToMUIFilterItem(
      billingAccountFilter,
      modelItems,
      FILTER_MAP.billingAccount.gridName,
      20000
    );
    convertToMUIFilterItem(campaignTemplateFilter, modelItems, FILTER_MAP.template.gridName, 30000);
    return modelItems;
  },

  getModelForQuery(model) {
    const newModel = { ...model };
    const newItems = [];
    for (let i = 0; i < newModel.items.length; i++) {
      const item = newModel.items[i];
      if (item.value) {
        const filter = {};
        filter.value = item.value;
        filter.columnField = convertColumnFieldToQueryField(item.columnField);
        filter.operatorValue = item.operatorValue;
        filter.id = item.id;
        newItems.push(filter);
      }
    }
    newModel.items = newItems;
    return newModel;
  },

  evaluateFilter(filter, permissions, accounts, templates) {
    const filterList = [];
    if (
      permissions
        .map((permission) => permission.mno)
        .flat()
        .includes('*')
    ) {
      // custom product case
      const productFilters = [...filter.items].filter(
        (item) => item.columnField === FILTER_MAP.product.queryName
      );
      const mnoFilters = [...filter.items]
        .filter((item) => item.columnField === FILTER_MAP.mno.queryName)
        .map((item) => item.value)
        .flat();
      if (productFilters && mnoFilters && productFilters.length > 0 && mnoFilters.length > 0) {
        generateProductFilters(filter, productFilters, permissions, mnoFilters, filterList);
        const searchQuery = convertFilterToGraphQL(
          this.getModelForQuery({ items: filterList }, accounts)
        );
        return addDraft(searchQuery, permissions);
      }
      const searchQuery = convertFilterToGraphQL(this.getModelForQuery(filter, accounts));
      return addDraft(searchQuery, permissions);
    }

    const permissionFreeFilters = [...filter.items].filter(
      (item) =>
        item.columnField !== FILTER_MAP.mno.queryName &&
        item.columnField !== FILTER_MAP.billingAccount.queryName &&
        item.columnField !== FILTER_MAP.product.queryName &&
        item.columnField !== FILTER_MAP.template.queryName
    );
    filterList.push(...permissionFreeFilters);

    if (
      !isEligible(permissions, FILTER_MAP.mno.permissionName) &&
      getMandatoryFilter(filter, 'mno').length === 0
    ) {
      const mnoFilter = flattenPermissionItem(permissions, FILTER_MAP.mno.permissionName);
      convertToMUIFilterItem(mnoFilter, filterList, FILTER_MAP.mno.gridName, 10000);
    } else {
      filterList.push(
        ...filter.items.filter((item) => item.columnField === FILTER_MAP.mno.gridName)
      );
    }
    if (
      !isEligible(permissions, FILTER_MAP.billingAccount.permissionName) &&
      getMandatoryFilter(filter, 'billingAccount').length === 0
    ) {
      convertToMUIFilterItem(
        flattenChildPermissionItem(permissions, FILTER_MAP.billingAccount.permissionName, accounts),
        filterList,
        FILTER_MAP.billingAccount.queryName,
        20000
      );
    } else {
      filterList.push(
        ...filter.items.filter((item) => item.columnField === FILTER_MAP.billingAccount.queryName)
      );
    }

    if (
      !isEligible(permissions, FILTER_MAP.product.permissionName) &&
      getMandatoryFilter(filter, 'product').length === 0
    ) {
      convertToMUIFilterItem(
        permissions.map((permission) => permission[FILTER_MAP.product.permissionName]).flat(),
        filterList,
        FILTER_MAP.product.queryName,
        30000
      );
    } else {
      const productFilters = filter.items.filter(
        (item) => item.columnField === FILTER_MAP.product.queryName
      );

      if (Array.isArray(productFilters) && productFilters.length > 0) {
        productFilters.forEach((filter) => {
          const result = [];
          const { value } = filter;
          if (value !== null && value !== undefined) {
            const valueList = [];
            Array.isArray(value) ? valueList.push(...value) : valueList.push(value);
            valueList.forEach((val) => {
              result.push(filterPermissionsBySelectedProducts(val, permissions, filter));
            });
          }
          filter.value = result.flat();
          filterList.push(filter);
        });
      }
    }

    if (
      !isEligible(permissions, FILTER_MAP.template.permissionName) &&
      getMandatoryFilter(filter, 'template').length === 0
    ) {
      convertToMUIFilterItem(
        flattenChildPermissionItem(permissions, FILTER_MAP.template.permissionName, templates),
        filterList,
        FILTER_MAP.template.queryName,
        30000
      );
    } else {
      filterList.push(
        ...filter.items.filter((item) => item.columnField === FILTER_MAP.template.queryName)
      );
    }

    const searchQuery = convertFilterToGraphQL(
      this.getModelForQuery(
        {
          items: filterList,
        },
        accounts
      )
    );
    return addDraft(searchQuery, permissions);
  },

  getFilteredProductsByPermission(permissions) {
    if (
      permissions
        .map((permission) => permission.mno)
        .flat()
        .includes('*')
    ) {
      return PRODUCT_FAMILIES_FOR_ORDERS;
    }

    const permissionProductArray = permissions
      .map((permission) => permission[FILTER_MAP.product.permissionName])
      .flat();
    const productNames = permissionProductArray.map((item) => item.split(':')[1]);
    return PRODUCT_FAMILIES_FOR_ORDERS.filter((product) => productNames.includes(product));
  },

  isEligible(permissions, keyword) {
    return !permissions
      .map((permission) => permission[keyword])
      .flat()
      .some((element) => element !== '*');
  },

  checkPermissionsForEdit(order, permissions, roles = null) {
    if (!order) {
      return true;
    }

    for (const permission of permissions.filter(
      (perm) => perm.permission !== 'View' && perm.permission !== 'Approve'
    )) {
      if (
        (permission.mno.includes(order.mno) || permission.mno.includes('*')) &&
        (permission.billingAccount.includes(order.billingAccountId) ||
          permission.billingAccount.includes('*')) &&
        (permission.billingCode.includes(order.billingCodeId) ||
          permission.billingCode.includes('*')) &&
        (permission.campaignTemplate.includes(order.templateId) ||
          permission.campaignTemplate.includes('*'))
      ) {
        return true;
      }
    }
    return false;
  },

  checkPermissionsForApprove(order, permissions, roles = null) {
    const permission = permissions.find((perm) => perm.permission === 'Approve');
    if (
      permission &&
      (permission.mno.includes(order.mno) || permission.mno.includes('*')) &&
      (permission.billingAccount.includes(order.billingAccountId) ||
        permission.billingAccount.includes('*')) &&
      (permission.campaignTemplate.includes(order.templateId) ||
        permission.campaignTemplate.includes('*'))
    ) {
      return true;
    }
    return false;
  },
  checkPermissionsForView(order, permissions) {
    for (const permission of permissions) {
      if (order.status === OrderStatus.DRAFT && order.ownerId === permission.username) {
        return true;
      }
      if (
        (permission.mno.includes(order.mno) || permission.mno.includes('*')) &&
        (permission.billingAccount.includes(order.billingAccountId) ||
          permission.billingAccount.includes('*')) &&
        (permission.campaignTemplate.includes(order.templateId) ||
          permission.campaignTemplate.includes('*'))
      ) {
        return true;
      }
    }
    return false;
  },
};

function handleItem(key, keyName, type = 'eq') {
  if (key.length > 1) {
    const or = [];
    key.forEach((item, index) => {
      or.push({ [keyName]: { [type]: item } });
    });
    return { or };
  }
  return { [keyName]: { [type]: key[0] } };
}

function addCommonFilters(commonFilters) {
  if (commonFilters.length === 1) {
    return commonFilters[0];
  }
  const and = [];
  if (commonFilters && commonFilters.length) {
    for (const item of commonFilters) {
      and.push(item);
    }
  }
  return { and };
}
export function mergeArrays(arr) {
  return [...new Set([].concat(...arr))];
}
