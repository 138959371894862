import { createSlice } from '@reduxjs/toolkit';

const customDataGridSlice = createSlice({
  name: 'dataGrid',
  initialState: {
    filter: {},
    sorting: {},
    columnVisibilityModel: {},
    columnOrder: {},
  },
  reducers: {
    saveFilter(state, action) {
      state.filter[action.payload.name] = action.payload.model;
    },
    saveSorting(state, action) {
      state.sorting[action.payload.name] = action.payload.model;
    },
    saveColumnVisibilityModel(state, action) {
      state.columnVisibilityModel[action.payload.name] = action.payload.model;
    },
    saveColumnOrder(state, action) {
      state.columnOrder[action.payload.name] = action.payload.model;
    },
  },
  extraReducers: {},
});

export const { saveFilter, saveSorting, saveColumnVisibilityModel, saveColumnOrder } =
  customDataGridSlice.actions;

export default customDataGridSlice.reducer;
