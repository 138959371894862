import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import withReducer from '../../../store/withReducer';
import reducer from '../store';

function JourneyHeader(props) {
  const { error } = props;
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);

  return (
    <div className="flex flex-1 w-full items-center justify-between">
      <Grid container item xs={6} direction="row" justifyContent="flex-start">
        <Grid container item xs={2}>
          <Typography component={Link} role="button" to="/support/journeys" color="inherit" className="centered">
            <Icon style={{color: "#22d3ee"}} className="text-base centered">arrow_back</Icon>
            <span style={{color: "#22d3ee"}} className="hidden sm:flex mx-4 font-normal hover:underline">Journeys</span>
          </Typography>
        </Grid>

        <Grid container item direction="row" xs={11} p={1}>
          <Icon className="text-28 md:text-32 mx-3">code</Icon>
          <Typography className="text-16 sm:text-20 truncate font-semibold">
            {props.journey && props.journey.name}
            {!props.journey && 'New Journey'}
          </Typography>
        </Grid>
      </Grid>

      <motion.div
        initial={{ opacity: 0, x: 20 }}
        animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
      >
        <Button
          className="whitespace-nowrap"
          variant="contained"
          color="secondary"
          disabled={!!error}
          onClick={props.onButtonClick}
        >
          <span className="hidden sm:flex"> Save the Journey</span>
          <span className="flex sm:hidden">Save</span>
        </Button>
      </motion.div>
    </div>
  );
}

export default withReducer('support', reducer)(JourneyHeader);
