import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice';
import { Auth as cognito } from 'aws-amplify';
import { checkPolicy } from './store/loginSlice'

import { logoutUser, setUserData } from './store/userSlice';
import { CURRENT_POLICY_VERSION } from '../main/policy/Policy';

class Auth extends Component {
  state = {
    waitAuthCheck: true,
  };

  componentDidMount() {
    return Promise.all([this.authCheck()]).then(() => {
      this.setState({ waitAuthCheck: false });
    });
  }

  authCheck = () =>
    new Promise((resolve) => {
      cognito
        .currentAuthenticatedUser()
        .then((userInfo) => {
          if (userInfo){
            checkPolicy(userInfo.attributes.email).then( value => {
                const payload = value.data.getPolicyLog;
                if (payload !== null && payload.approvedVersion === CURRENT_POLICY_VERSION){
                  this.props.setUserData(userInfo).then((result) => { resolve();});
                }else{
                  resolve();
                }
              }
            );
          } else {
            resolve();
          }
        })
        .catch(() => {
          resolve();
        });

      return Promise.resolve();
    });

  render() {
    return this.state.waitAuthCheck ? <FuseSplashScreen /> : <>{this.props.children}</>;
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      logout: logoutUser,
      setUserData,
      showMessage,
      hideMessage,
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Auth);
