import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import FlightdeckLogger from 'app/services/logger/FlightdeckLogger';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import * as queries from '../../../../graphql/queries';
import { showMessage } from '../../../store/fuse/messageSlice';
import { throwErrorIfHandledByMiddleware } from '../../../store';

const logger = new ComponentLogger('UtilitySlice');

export const utilityApi = createAsyncThunk('utility', async (variables, { dispatch }) => {
  const { action } = variables;
  try {
    const response = await API.graphql({
      query: queries.utility,
      variables: {
        action,
      },
    });
    return response?.data?.utility?.result;
  } catch (error) {
    logger.info('utilityApi', { error });
    throwErrorIfHandledByMiddleware(error);
    dispatch(
      showMessage({
        message: error.errors[0].message,
        variant: 'error',
      })
    );
    return [];
  }
});

export const utilitySlice = createSlice({
  name: 'utility',
  initialState: {
    loading: false,
    data: [],
  },
  reducers: {
    resetData: (state) => {
      state.data = [];
    },
  },
  extraReducers: {
    [utilityApi.pending]: (state) => {
      state.loading = true;
    },
    [utilityApi.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [utilityApi.rejected]: (state, action) => {
      state.loading = false;
      state.data = [];
    },
  },
});

export const {} = utilitySlice.actions;
export default utilitySlice.reducer;
