import { API } from 'aws-amplify';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import ComponentLogger from 'app/services/logger/ComponentLogger';
import * as queries from '../../../../graphql/queries';
import * as mutations from '../../../../graphql/mutations';
import { showMessage } from '../../../store/fuse/messageSlice';
import { throwErrorIfHandledByMiddleware } from '../../../store';

const logger = new ComponentLogger('UserSettingsSlice');

const MAX_USER_SETTINGS_LIMIT = 1000;
export const getUserSettings = createAsyncThunk('admin/getUserSettings', async (dispatch) => {
  let data = [];
  let nextToken = null;
  do {
    // eslint-disable-next-line no-await-in-loop
    const response = await API.graphql({
      query: queries.listUserSettings,
      variables: {
        limit: MAX_USER_SETTINGS_LIMIT,
        nextToken,
      },
    }).catch((error) => {
      logger.error('listUserSettings', { error });
      throwErrorIfHandledByMiddleware(error);
    });
    nextToken = response.data.listUserSettings.nextToken;
    data = data.concat(response.data.listUserSettings.items);
  } while (nextToken);

  if (data.length === 0) {
    dispatch(showMessage({ message: 'No user settings found!', variant: 'info' }));
    return [];
  }
  return data.map((userSetting) => {
    return {
      ...userSetting,
      id: userSetting.username,
    };
  });
});

export const saveUserSetting = createAsyncThunk(
  'admin/userSettings/saveUserSetting',
  async (userSetting, { dispatch }) => {
    let data;

    if (!userSetting.id) {
      const response = await API.graphql({
        query: mutations.createUserSetting,
        variables: { input: userSetting },
      }).catch((error) => {
        logger.error('saveUserSetting', { data: userSetting, error });
      });

      data = await response.data.createUserSetting;
      dispatch(
        showMessage({ message: 'User settings has been created successfully!', variant: 'success' })
      );
    } else {
      const { id, createdAt, updatedAt, ...userSettingData } = userSetting;
      const response = await API.graphql({
        query: mutations.updateUserSetting,
        variables: { input: userSettingData },
      }).catch((error) => {
        logger.error('updateUserSetting', { data: userSettingData, error });
      });

      data = await response.data.updateUserSetting;
      dispatch(
        showMessage({ message: 'User settings has been updated successfully!', variant: 'success' })
      );
    }
    return data;
  }
);

export const deleteUserSetting = createAsyncThunk(
  'admin/userSettings/deleteUserSetting',
  async (userSetting, { dispatch }) => {
    // ugly workaround to save user
    const { id, createdAt, updatedAt, deleted, ...userSettingData } = userSetting;
    const updateResponse = await API.graphql({
      query: mutations.updateUserSetting,
      variables: { input: userSettingData },
    }).catch((error) => {
      logger.error('deleteUpdateUserSetting', { data: userSettingData, error });
    });

    const request = {
      username: userSetting.username,
      userSetting: userSetting.userSetting,
    };

    const response = await API.graphql({
      query: mutations.deleteUserSetting,
      variables: { input: request },
    }).catch((error) => {
      logger.error('deleteUserSetting', { data: userSetting, error });
    });

    const data = await response.data.deleteUserSetting;
    dispatch(
      showMessage({ message: 'User settings has been deleted successfully!', variant: 'success' })
    );

    return data;
  }
);

export async function createDefaultUserSettings(username, updaterId) {
  const userSetting = {
    username,
    msisdnSourceSetting: {
      queryEnabled: true,
      fileUploadEnabled: false,
    },
    executionFlowStatsEnabled: false,
    downloadAllMSISDNListsEnabled: false,
    updaterId,
  };
  let success = true;
  await API.graphql({
    query: mutations.createUserSetting,
    variables: { input: userSetting },
  }).catch((error) => {
    logger.error('createDefaultUserSettings', { username, error });
    success = false;
  });
  return success;
}

const userSettingsAdapter = createEntityAdapter({});

export const { selectAll: selectUserSettings, selectById: selectUserSettingById } =
  userSettingsAdapter.getSelectors((state) => state.admin.userSettings);

export const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState: userSettingsAdapter.getInitialState({
    loading: true,
    userSettingDialog: {
      type: 'new',
      props: {
        open: false,
      },
      data: null,
    },
  }),
  reducers: {
    openNewUserSettingDialog: (state, action) => {
      state.userSettingDialog = {
        type: 'new',
        props: {
          open: true,
        },
        data: null,
      };
    },
    closeNewUserSettingDialog: (state, action) => {
      state.userSettingDialog = {
        type: 'new',
        props: {
          open: false,
        },
        data: null,
      };
    },
    openEditUserSettingDialog: (state, action) => {
      state.userSettingDialog = {
        type: 'edit',
        props: {
          open: true,
        },
        data: action.payload,
      };
    },
    closeEditUserSettingDialog: (state, action) => {
      state.userSettingDialog = {
        type: 'edit',
        props: {
          open: false,
        },
        data: null,
      };
    },
  },
  extraReducers: {
    [getUserSettings.pending]: (state) => {
      state.loading = true;
    },
    [getUserSettings.fulfilled]: (state, action) => {
      state.loading = false;
      userSettingsAdapter.setAll(state, action.payload);
    },
    [getUserSettings.rejected]: (state, action) => {
      state.loading = false;
    },
    [saveUserSetting.fulfilled]: (state, action) => {
      const userSetting = action.payload;
      userSetting.id = action.payload.username;
      userSettingsAdapter.upsertOne(state, userSetting);
    },
    [deleteUserSetting.pending]: (state) => {
      state.loading = true;
    },
    [deleteUserSetting.fulfilled]: (state, action) => {
      userSettingsAdapter.removeOne(state, action.payload.username);
      state.loading = false;
    },
  },
});

export const {
  openNewUserSettingDialog,
  closeNewUserSettingDialog,
  openEditUserSettingDialog,
  closeEditUserSettingDialog,
} = userSettingsSlice.actions;

export default userSettingsSlice.reducer;
