import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import FormInputDropdown from 'app/shared-components/FormInputDropdown';
import { convertMnoNamesToIDs, getMergedPermissionAttribute } from 'app/services/reportsUtil';
import Root from 'app/shared-components/Root';
import GridBigDataColumnRenderer from 'app/shared-components/GridBigDataColumnRenderer';
import CustomDataGrid from 'app/shared-components/CustomDataGrid';
import { ATTRIBUTE_NAME_QS_DASHBOARD_ID_PAYMENT_HISTORY } from 'app/services/constants';
import QuickSightFrame from 'app/shared-components/QuickSightFrame';
import {
  getAccountsOfUserWithPaymentFunctionality,
  isUserAdmin,
} from 'app/services/permissionUtil';
import PageHeader from '../../../shared-components/PageHeader';
import { getMnos } from '../store/reportsSlice';
import FuseLoading from '../../../../@fuse/core/FuseLoading';
import {
  getAccounts,
  selectAccountById,
  selectAccounts,
} from '../../billing-admin/store/accountsSlice';
import { getIncompletePayments } from '../store/paymentSlice';

const schema = yup.object().shape({
  billingAccountIdParameter: yup.string().required('Billing Account id required'),
});

const defaultValues = {
  billingAccountIdParameter: '',
};

function PaymentHistory() {
  const dispatch = useDispatch();
  const { permissions } = useSelector(({ auth }) => auth.user);
  const { loadingMnos, mnos } = useSelector(({ journey }) => journey.reports);
  const { incompletePayments, incompletePaymentsLoading } = useSelector(
    ({ journey }) => journey.payment
  );
  const mnosOfUser = getMergedPermissionAttribute(permissions, 'mno');
  const user = useSelector(({ auth }) => auth.user);

  const commonColumns = [
    {
      field: 'paymentDate',
      headerName: 'Payment Date',
      flex: 1,
      valueFormatter: (params) =>
        params.value ? new Date(params.value).toLocaleDateString('en-GB') : 'Not Found',
    },
    {
      field: 'responseMessage',
      headerName: 'Status',
      flex: 1,
    },
    {
      field: 'accountMno',
      headerName: 'Mno',
      flex: 1,
      valueGetter: () => selectedAccount?.mno,
    },
    { field: 'email', headerName: 'Username', flex: 1 },
    {
      field: 'transactionId',
      headerName: 'Transaction ID',
      flex: 1,
    },
    {
      field: 'currency',
      headerName: 'Currency',
      flex: 1,
      valueGetter: () => selectedAccount?.amountDueLimitCurrency,
    },

    {
      field: 'amountRequested',
      headerName: 'Amount',
      flex: 1,
    },
    {
      field: 'fee',
      headerName: 'Payment Fee',
      flex: 1,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      hide: true,
      flex: 1,
    },
  ];

  const [columns, setColumns] = useState(commonColumns);

  useEffect(() => {
    if (user && user.role && isUserAdmin(user)) {
      setColumns((prev) => [
        ...prev,
        {
          field: 'fullJson',
          flex: 1,
          headerName: 'Full JSON',
          renderCell: (params) => (
            <GridBigDataColumnRenderer title="Full JSON" value={JSON.stringify(params.row)} />
          ),
        },
        {
          field: 'channel',
          headerName: 'Channel',
          flex: 1,
        },
      ]);
    }
  }, [user]);

  const { control, watch, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const billingAccountIdParameter = watch('billingAccountIdParameter');

  const accounts = useSelector(selectAccounts);
  const selectedAccount = useSelector((state) =>
    selectAccountById(state, billingAccountIdParameter)
  );

  function getTags() {
    const billingAccountId = getMergedPermissionAttribute(permissions, 'billingAccount');

    return {
      cpBillAccId: billingAccountId.toString(),
      mno_ids: convertMnoNamesToIDs(mnos, mnosOfUser).toString(),
    };
  }

  useEffect(() => {
    if (!billingAccountIdParameter || !user) return;
    dispatch(getIncompletePayments({ billingAccountIdParameter }));
  }, [dispatch, user, billingAccountIdParameter]);

  useEffect(() => {
    if (!accounts.length) dispatch(getAccounts());
    if (!mnos.length) dispatch(getMnos());
  }, [dispatch]);

  useEffect(() => {
    const accountsOfUser = getAccountsOfUserWithPaymentFunctionality(accounts, permissions);
    if (accountsOfUser && accountsOfUser.length) {
      const compare = (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      };
      setValue('billingAccountIdParameter', accountsOfUser.sort((a, b) => compare(a, b))[0].id);
    }
  }, [accounts, permissions]);

  function getAccountDropdownOptions() {
    const accountsOfUser = getAccountsOfUserWithPaymentFunctionality(accounts, permissions);
    const options = accountsOfUser?.map((account) => ({
      value: account.id,
      label: account.name === account.mno ? account.name : `${account.name} (${account.mno})`,
    }));
    return options ?? [];
  }

  function getUrlParams() {
    return { billingAccountIdParameter };
  }

  return (
    <Root
      header={<PageHeader iconName="error" title="Payment History" noHeaderButton />}
      content={
        <section className="p-24 w-full h-full flex flex-col">
          <header className="max-w-[400px]">
            <FormInputDropdown
              name="billingAccountIdParameter"
              label="Billing Account"
              control={control}
              mno={mnosOfUser}
              options={getAccountDropdownOptions()}
              required
            />
          </header>
          <div className="mt-36 flex flex-col gap-36 grow">
            {loadingMnos || !billingAccountIdParameter || incompletePaymentsLoading ? (
              <FuseLoading />
            ) : (
              incompletePayments &&
              incompletePayments.length > 0 && (
                <>
                  <header className='font-bold text-lg text-red-500'>Incomplete Payments</header>
                  <CustomDataGrid
                    name="Incomplete Payments"
                    rows={incompletePayments ?? []}
                    columns={columns}
                  />
                </>
              )
            )}
            <QuickSightFrame
              dashboardIdAttributeName={ATTRIBUTE_NAME_QS_DASHBOARD_ID_PAYMENT_HISTORY}
              tags={getTags()}
              urlParams={getUrlParams()}
            />
          </div>
        </section>
      }
      innerScroll
    />
  );
}

export default PaymentHistory;
