import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { logoutUser } from 'app/auth/store/userSlice';
import { policyIcon } from '@mui/icons-material/Policy';
import { openDialog } from '../../store/fuse/dialogSlice';
import UserNotificationDialog from '../../main/orders/notifications/UserNotificationDialog';
import { getActualUserRoles } from '../../services/generalUtil';
import ReceivedNotificationsDialog from '../../main/orders/notifications/ReceivedUserNotificationsDialog';
import Policy from '../../main/policy/Policy';
import Error404Page from '../../main/404/Error404Page';
import AboutDialog from '../../main/about/AboutDialog';


function UserMenu(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(({ auth }) => auth.user);

  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  return (
    <>
      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
        color="inherit"
      >
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold flex">
            {user.data.email}
          </Typography>
          <Typography className="text-11 font-medium capitalize" color="textSecondary">
            {getActualUserRoles(user).toString()}
            {(!user.role || (Array.isArray(user.role) && user.role.length === 0)) && 'Guest'}
          </Typography>
        </div>
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {!user.role || user.role.length === 0 ? (
          <>
            <MenuItem component={Link} to="/login" role="button">
              <ListItemIcon className="min-w-40">
                <Icon>lock</Icon>
              </ListItemIcon>
              <ListItemText primary="Login" />
            </MenuItem>
            <MenuItem component={Link} to="/register" role="button">
              <ListItemIcon className="min-w-40">
                <Icon>person_add</Icon>
              </ListItemIcon>
              <ListItemText primary="Register" />
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              onClick={() => {
                dispatch(
                  openDialog({
                    children: <UserNotificationDialog />,
                  })
                );
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon>email</Icon>
              </ListItemIcon>
              <ListItemText primary="Configure Notifications" />
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(
                  openDialog({
                    children: <Policy readOnly={ true }/>,
                  })
                );
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon>policyIcon</Icon>
              </ListItemIcon>
              <ListItemText primary="Privacy & Terms" />
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(
                  openDialog({
                    children: <AboutDialog />,
                  })
                );
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon>infoIcon</Icon>
              </ListItemIcon>
              <ListItemText primary="About" />
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(logoutUser());
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
}

export default UserMenu;
